import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import PrimeGridKF from "../PrimeGridKlient";
import useStorage from "../../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../../services/axios";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import PaneliTab2 from "../../fature-blerje/tabet/PaneliTab2";
import "../Celje.scss";
import { Toast } from "primereact/toast";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TabMainCelje from "../PaneliTabeveCelje/TabMainCelje";
import TabTjeraCelje from "../PaneliTabeveCelje/TabTjera";
import SmallButtonGroupC from "../SmallButtonGroupC";
import { getFurnitor } from "../../../store/globalSlice";
import Footer from "../../../components/Navbar/Footer";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TabTjeraCeljePrv from "../PaneliTabeveCelje/TabTjeraPrv";
import SideBarCelje from "../SideBarCelje";
import { useUser } from "../../../zustand/common";

const Furnitor = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const toast = useRef(null);
  const [lastData, setLastData] = useState({});
  const [lastKodi, setLastKodi] = useState("");

  // Redux: Get client data from global slice
  // const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const furnitoriRedux = useSelector((state) => state.globalSlice.furnitor);

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        setdata(furnitoriRedux);
        if (furnitoriRedux.length > 0) {
          const lastKodi = furnitoriRedux[furnitoriRedux.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("F01"); // Start with F1 if there are no existing entries
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
        hideLoading();
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [furnitoriRedux, hideLoading, showLoading]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "Id", title: "ID" },
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "BarKod", title: "BarKod" },
    { field: "Nipt", title: "Nipt" },
    { field: "Cel", title: "Cel" },
    { field: "EmerKontakti", title: "Emer Kontakti" },
    { field: "NrLL", title: "Llogari" },
    { field: "Skonto", title: "Skonto" },
    { field: "Aktiv", title: "Aktiv" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatFurnitor"
  );
  let defaultState = {
    Adresa: "",
    Adresa2: null,
    Adresa3: null,
    Aktiv: "",
    Banka: null,
    Banka_Llogari: null,
    BillingAddres1: null,
    BillingAddres2: null,
    BillingCity: null,
    BillingCountry: null,
    BillingPhone: null,
    Cel: "",
    Data_Regjistrimit: null,
    Email: "",
    Emer: null,
    EmerKontakti: "",
    Furnitor_Kls01_ID: null,
    Furnitor_Kls02_ID: null,
    Furnitor_Kls03_ID: null,
    Furnitor_Kls04_ID: null,
    Furnitor_Kls05_ID: null,
    Inserted: null,
    KaVeprime: null,
    Klient_Kodi: null,
    KodFiskal: null,
    Kodi: `${lastKodi}`,
    Kodi1: null,
    KodiOld: null,
    KomisionFee: null,
    KomisionPerqindje: null,
    KomisionTipi: null,
    KomisionVlera: null,
    Kompania: null,
    LimitDite: null,
    LimitVlere: null,
    Mbiemer: null,
    Nipt: "",
    NotForSync: null,
    NrLL: "401",
    Operator: null,
    Pershkrim: "",
    Punonjes_ID: null,
    Qyteti_Kodi: "",
    Rabat: null,
    Rendit: null,
    Selektuar: null,
    Shenime: "",
    ShippingAddres1: null,
    ShippingAddres2: null,
    ShippingCity: null,
    ShippingCountry: null,
    Shteti_Kodi: "",
    Tel: "",
    TipFirme_Kodi: "",
    Tip_Cmimi: "CMIMI",
    // AktivM: "",
    Updated: null,
    UserRole_Kodi: null,
    idOLD: null,
  };
  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Furnitor
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SideBarCelje
          currentAction={currentAction}
          toast={toast}
          visible={visibleRight}
          onHide={() => setVisibleRight(false)}
          position="right"
        />
      </div>
      // 11-10-2023 - Fundi i shtimit te sidebar nga e djathta - Arseni
    );
  };

  const deleteMethod = async (e) => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=FURNITOR&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });

        return;
      } else {
        const response = await mainAxios.delete(`/furnitori/${state.Id}`);

        if (response && response.status === 200) {
          setState(defaultState);
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getFurnitor("/furnitori/search"));
          toast.current.show({
            severity: "info",
            summary: "u fshi",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u fshi." + error.message,
      });
    }
  };

  const handleAdd = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Tip_Cmimi.trim() ||
      (state.Nipt.length !== 10 && state.Nipt.length !== 0)
    ) {
      toast.current.show({
        severity: "info",
        summary:
          "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : "") +
          (!state.NrLL.trim() ? "Llogari " : "") +
          (!state.Tip_Cmimi.trim() ? "Tip Cmimit" : "") +
          (state.Nipt.length !== 10 && state.Nipt.length !== 0
            ? "Nipt -10 karaktere ose asnje"
            : ""),
      });
      return;
    }
    try {
      const response = await mainAxios.post(`/furnitori`, {
        // ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Cel: state.Cel,
        EmerKontakti: state.EmerKontakti,
        Kodi: state.Kodi,
        NrLL: state.NrLL,
        Pershkrim: state.Pershkrim,
        Qyteti_Kodi: state.Qyteti_Kodi,
        Shteti_Kodi: state.Shteti_Kodi,
        Tel: state.Tel,
        Nipt: state.Nipt,
        Tip_Cmimi: state.Tip_Cmimi,
        Email: state.Email,
        TipFirme_Kodi: state.TipFirme_Kodi,
        Shenime: state.Shenime,
        // AktivM: state.AktivM,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getFurnitor("/furnitori/search"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U rregjistrua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u regjistrua." + error.message,
      });
    }
  };

  const handleModify = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Tip_Cmimi.trim() ||
      (state.Nipt.length !== 10 && state.Nipt.length !== 0)
    ) {
      toast.current.show({
        severity: "info",
        summary:
          "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : "") +
          (!state.NrLL.trim() ? "Llogari " : "") +
          (!state.Tip_Cmimi.trim() ? "Tip Cmimit" : "") +
          (state.Nipt.length !== 10 && state.Nipt.length !== 0
            ? "Nipt -10 karaktere ose asnje"
            : ""),
      });
      return;
    }
    try {
      const response = await mainAxios.put(`/furnitori/${state.Id}`, {
        // ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Cel: state.Cel,
        EmerKontakti: state.EmerKontakti,
        Kodi: state.Kodi,
        NrLL: state.NrLL,
        Pershkrim: state.Pershkrim,
        Qyteti_Kodi: state.Qyteti_Kodi,
        Shteti_Kodi: state.Shteti_Kodi,
        Tel: state.Tel,
        Nipt: state.Nipt,
        Tip_Cmimi: state.Tip_Cmimi,
        Email: state.Email,
        TipFirme_Kodi: state.TipFirme_Kodi,
        Shenime: state.Shenime,
        // AktivM: state.AktivM,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getFurnitor("/furnitori/search"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U modifikua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u modifikua." + error.message,
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  const fetchSubject = async (niptSubjekti, dbName) => {
    try {
      const response = await mainAxios.get(
        `/subject?nipt_subjekti=${niptSubjekti}&nipt=${dbName}`
      );
      if (response.status === 200) {
        // Check if the response data array has at least one element
        if (Array.isArray(response.data) && response.data.length > 0) {
          const firstObject = response.data[0];
          // Update only the Nipt field in the state
          setState((prevState) => ({
            ...prevState,
            Pershkrim: firstObject.Pershkrim,
            Nipt: firstObject.NIPT,
            Shteti_Kodi: firstObject.Shteti,
            Adresa: firstObject.Adresa,
          }));
        } else {
          // Handle the case where the array is empty or not an array
          console.error("Response data is empty or not an array.");
        }
      }
    } catch (error) {
      console.error("Error fetching subject:", error);
    }
  };

  const handleGenerateClick = () => {
    // Check if the word count is less than 10
    if (state.Nipt.length < 10) {
      toast.current.show({
        severity: "error",
        summary: "Vendos niptin sakte.",
      });
      return;
    }

    const niptSubjekti = state.Nipt;
    fetchSubject(niptSubjekti, nipt);
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.Id}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
      />
    </div>
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";
  const button7Text = isMobile ? "" : "Te tjera";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      includeButton7={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AssignmentIndIcon />}
      button7Text={button7Text}
      button7Icon={<AutoAwesomeMotionIcon />}
    />
  );

  const TabiKryesor = (
    <TabMainCelje
      handleGenerateClick={handleGenerateClick}
      btnGjenero={true}
      TextEmri={true}
      state={state}
      DocId={true}
      TextLlogari={true}
      TextKodi={true}
      TextNipt={true}
      disabled={disabled}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraCelje
        TextTipCmimi={true}
        isFurnitor={true}
        TextEmerKontakti={true}
        TextQyteti={true}
        TextTel={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
      />
      <TabTjeraCeljePrv
        TextCel={true}
        // AktivM={true}
        isFurnitor={true}
        setState={setState}
        TextAdresa={true}
        TextEmail={true}
        TextShenime={true}
        TextShteti={true}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh-c">
      <NavBar />
      <Toast ref={toast} />
      <div className={isMobile ? null : "template"}>
        <div className="p-2 main-container-c">
          <div className="top_panel_fsh-c">
            <Panel
              headerTemplate={template}
              toggleable
              style={{ height: "100%" }}
            >
              <div className="bg-white" style={{ height: "100%" }}>
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve-c">
                      <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                      <div className="paneli-fis-print-c"></div>
                    </div>
                    <div className={"below-tabs-c"}>
                      <div className="below-tabs1-c">{TabiKryesor}</div>
                      <div className="below-tabs2-c">{smallTabsKM}</div>
                      <div className="below-tabs3-c"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>
          <div className={!isMobile ? "middle_fsh-c" : null}></div>

          <div className="dataTable_fsh-c">
            <PrimeGridKF
              gridKey="Furnitori"
              data={data}
              defaultState={defaultState}
              isEditable={true}
              defaultColumnsStorage={defaultColumnsStorage}
              columns={columns}
              setColumns={setColumns}
              disabled={disabled}
              setState={setState}
              rows={rows}
              state={state}
              setdata={setdata}
              setLastData={setLastData}
              setRows={setRows}
              selectedColumns={selectedColumns}
              setselectedcolumns={setselectedcolumns}
              currentAction={currentAction}
            />
          </div>
        </div>
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(Furnitor);
