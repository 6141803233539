import React, { useState } from "react";
import ModalListSupermarket from "./ModalListSupermarket";
import "./ArtikujSuperMarket.scss";

export default function SearchArtikuj({ shtoArtikull, art }) {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="tabela_artikuj">
      <div className="search_supermarket">
        <div style={{ position: "relative" }}>
          <ModalListSupermarket
            shtoArtikull={shtoArtikull}
            gridKey={"PosSupermarket"}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            art={art}
          />
        </div>
      </div>
    </div>
  );
}
