import React, { useEffect, useState, useContext } from "react";
import mainAxios from "../../../../services/axios";
import KthePanelet from "./KthePanelet";
import Stack from "@mui/material/Stack";
import NavbarR from "../NavPos/NavbarR";
import "./NewScssRes.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useMyContext } from "../../../../components/context/MyStatePOSContext";
import { useSelector } from "react-redux";
import {
  getArkaList,
  getGrupi,
  getKlienti,
  getMenyraPageses,
} from "../../../../store/globalSlice";
import { getArtikujPos } from "../../../../store/globalPos";
import { useDispatch } from "react-redux";
import { useUser } from "../../../../zustand/common";

const Tavolina = ({
  userAutomatikisht,
  setUserAutomatikisht,
  isOnline,
  isMobile,
}) => {
  const { user } = useUser();
  const operator = user.username;
  const [sektoret, setSektoret] = useState([]);
  const [btnSektor, setBtnSektor] = useState("");
  const [btntavolinat, setBtnTavolinat] = useState([]);
  const [lastOpenedTable, setLastOpenedTable] = useState("");
  const [tavolinaObjects, setTavolinaObjects] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [art, setArt] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [clickedItems, setClickedItems] = useState([]);
  const [newId, setNewId] = useState("");
  const dispatch = useDispatch();

  // redux artikuj (get requesti i artikujve)
  const artikujPos = useSelector((state) => state.globalPosSlice.artikujPos);

  const menyraPageses = useSelector((state) => state.globalSlice.menyraPageses);
  const [menyra, setMenyra] = useState(menyraPageses);

  const grupi = useSelector((state) => state.globalSlice.grupi);
  const [kls01, setkls01] = useState(grupi);

  const arkaList = useSelector((state) => state.globalSlice.arkaList);
  const filteredArka = arkaList.filter((item) => item.TCRCode);
  const [arka, setArka] = useState(filteredArka);

  const klientG = useSelector((state) => state.globalSlice.klienti);
  const [klienti, setKlienti] = useState(klientG);

  useEffect(() => {
    dispatch(getMenyraPageses("/menyra-pageses"));
    dispatch(getArtikujPos("/pos/art"));
    dispatch(getGrupi("/art/kls01"));
    dispatch(getArkaList("/arka/list"));
    dispatch(getKlienti("/klienti"));
  }, []);

  let defaultState = {
    Aktiv: 1,
    E_Paguar: 0,
    Kodi: 1,
    Menyra_Pageses_Id: 6,
    Pike_Fitim: 0,
    Tip_Cmimi: "CMIMI",
    Operator: `${operator}`,
    Tipi: "FTSH",
  };
  const { state, setState } = useMyContext();

  useEffect(() => {
    setLoading(true);
    const fetchSektoret = async () => {
      try {
        const sektor = await mainAxios("/sektoret");
        setSektoret(sektor.data);
        setBtnSektor(sektor.data[0]?.Pershkrim || "");
      } catch (error) {
        console.error("Error ne gjenerimin e sektoreve", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSektoret();
  }, []);

  const fetchPos = async () => {
    setLoading(true);
    try {
      const posDataTav = await mainAxios(`/bar/filter`);

      const mappedData = posDataTav.data.map((item) => ({
        Id: item.Id,
        Tavolina: item.Tavolina,
        Vlera: item.Vlera,
        Operator: item.Operator,
      }));

      setTavolinaObjects(mappedData);
    } catch (error) {
      console.error("Error in fetching pos data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sektoret && sektoret.length > 0) {
      fetchPos();
    }
  }, [sektoret]);

  useEffect(() => {
    // Filter and set data only if artikujPos has data
    if (artikujPos.length > 0) {
      try {
        setArt(artikujPos);
        if (artikujPos.length === 0) {
          setNoDataMessage("Nuk u gjend asnje artikull.");
        } else {
          setNoDataMessage("");
        }
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    }
  }, [artikujPos]);

  useEffect(() => {
    const KrijoTavolinat = () => {
      setLoading(true);

      const panelData = {};

      sektoret.forEach((sektor) => {
        const { Pershkrim, Fillon, Numri_Tavolinave } = sektor;
        const buttonData = [];
        for (let i = Fillon; i < Numri_Tavolinave + Fillon; i++) {
          buttonData.push(i);
        }

        panelData[Pershkrim] = buttonData;
      });
      setBtnTavolinat(panelData);
      setLoading(false);
    };
    KrijoTavolinat();
  }, [sektoret]);

  const tavolinaFundit = (nr, currentId) => {
    setCurrentId(currentId);
    setLastOpenedTable(nr);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <NavbarR
            active="Main"
            fetchPos={fetchPos}
            userAutomatikisht={userAutomatikisht}
            setUserAutomatikisht={setUserAutomatikisht}
            lastOpenedTable={lastOpenedTable}
            menyra={menyra}
            clickedItems={clickedItems}
            state={state}
            arka={arka}
            setState={setState}
            newId={newId}
            setNewId={setNewId}
            defaultState={defaultState}
          />

          <div
            style={{
              width: "100%",
              height: "90%",
              background: "#ececec",
            }}
          >
            <div className="flex little__div" direction="row">
              <span>
                <span className="dot"></span>
                <strong className="little__dsc">Te lira</strong>
              </span>
              <span>
                <span className="dotRed"></span>
                <strong className="little__dsc">Te zena</strong>
              </span>
            </div>

            {sektoret.map((item) => (
              <KthePanelet
                defaultState={defaultState}
                newId={newId}
                setNewId={setNewId}
                state={state}
                setState={setState}
                clickedItems={clickedItems}
                setClickedItems={setClickedItems}
                isMobile={isMobile}
                key={item.Pershkrim}
                panelName={item.Pershkrim}
                btnSektor={btnSektor}
                btntavolinat={btntavolinat}
                tavolinaFundit={tavolinaFundit}
                tavolinaObjects={tavolinaObjects}
                currentId={currentId}
                fetchPos={fetchPos}
                noDataMessage={noDataMessage}
                klienti={klienti}
                setNoDataMessage={setNoDataMessage}
                art={art}
                kls01={kls01}
                loading={loading}
                setLoading={setLoading}
                arka={arka}
                menyra={menyra}
                isOnline={isOnline}
                // storeOfflineRequest={storeOfflineRequest}
              />
            ))}
          </div>

          <footer
            style={{
              background: "#fff",
              width: "100%",
              height: "5%",
            }}
          >
            <Stack direction="row" spacing={2}>
              {sektoret.map((item) => (
                <div key={item.ID}>
                  <button
                    className={`px-8 sektor ${
                      item.Pershkrim === btnSektor ? "active" : ""
                    }`}
                    onClick={() => {
                      setBtnSektor(item.Pershkrim);
                    }}
                    style={{
                      backgroundColor:
                        item.Pershkrim === btnSektor ? "transparent" : "",
                      color: item.Pershkrim === btnSektor ? "black" : "black",
                      fontSize: "bold",
                      marginBottom: "5px",
                      left: "5px",
                      position: "relative",
                    }}
                  >
                    {item.Pershkrim}
                    {item.Pershkrim === btnSektor && (
                      <div
                        className="red-line"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "10px",
                          background: "#11999E",
                        }}
                      ></div>
                    )}
                  </button>
                </div>
              ))}
            </Stack>
          </footer>
        </>
      )}
    </>
  );
};

export default Tavolina;
