import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, TextField, Autocomplete } from "@mui/material";
import Switch from "@mui/material/Switch";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Arka({ open, handleClose }) {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Balanca e Arkes
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* Brenda dialogut te Balanca e arkes  */}
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo1"
                  // options={top100Films}
                  sx={{ width: 309 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Arka" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <div className="Item">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo2"
                    // options={top100Films}
                    sx={{ width: 309 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Vlera" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="Item">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo3"
                    // options={top100Films}
                    sx={{ width: 309 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Veprimi" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="Item">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo4"
                    // options={top100Films}
                    sx={{ width: 309 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Shenim" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginTop: 5, margin: 5 }}
          >
            <div className="Item">
              <strong>Me Vonese</strong>
              <Switch sx={{ marginRight: 5 }} {...label} defaultChecked />
            </div>

            <Button
              variant="contained"
              sx={{ marginLeft: 4 }}
              autoFocus
              onClick={handleClose}
            >
              Save changes
            </Button>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
