import React, { useState } from "react";
import "./NavStyles/Footer.css";
import Logoja from "../../assets/Logoja.png";
import { useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useUser } from "../../zustand/common";

function Footer() {
  const { user, logout } = useUser();

  const [openDialog, setOpenDialog] = useState(false);

  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = () => {
    handleDialogClose();
    logout();
  };

  return (
    <div
      style={{ background: "#1564ad" }}
      className="footerMain flex items-center justify-center text-white  rounded-xl "
    >
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={handleDialogOpen}
      >
        <img src={Logoja} className={`logoja`} alt="logo-footer" />
        <p style={{ color: "#fff" }}>
          Ndërmarrje :{" "}
          {konfigurimeRedux && konfigurimeRedux.length > 0
            ? `${konfigurimeRedux[0].Pershkrim} - ${user.nipt}`
            : `${user.nipt}`}
        </p>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          Nese deshironi te ndryshoni ndermarrje. <br /> Duhet ta ndryshoni ne
          Login
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleLogout} variant="contained" color="primary">
            <Logout fontSize="medium" className="mx-2" /> Logout
          </Button>
          <Button onClick={handleDialogClose} variant="contained">
            JO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Footer;
