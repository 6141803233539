import React, { useEffect, useState, useRef, useContext } from "react";
import "../POSE.scss";
import FullScreenDialog from "./FullScreenDialog";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
import DivComponent from "./DivComponent.js/DivComponent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDate } from "./DateUtils";
import { useUser } from "../../../../zustand/common";

const KthePanelet = ({
  panelName,
  klienti,
  btnSektor,
  btntavolinat,
  tavolinaFundit,
  tavolinaObjects,
  fetchPos,
  noDataMessage,
  art,
  setNoDataMessage,
  kls01,
  loading,
  setLoading,
  arka,
  menyra,
  isOnline,
  // storeOfflineRequest,
  isMobile,
  clickedItems,
  setClickedItems,
  state,
  setState,
  newId,
  setNewId,
  defaultState,
}) => {
  const { user } = useUser();
  const operator = user.username;
  const toast = useRef(null);
  const [divs, setDivs] = useState([]);
  const [ora, setOra] = useState("");
  const timeOnly = ora.slice(11, 19);
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [dtlState, setDtlState] = useState([]);
  const [dtlNum, setDtlNum] = useState("");
  const [numTav, setNumTav] = useState("");
  const [openedTable, setOpenedTable] = useState([]);
  const [aExistTable, setTableExist] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loadingLeft, setLoading2] = useState(true);

  const [totalOfflineCmimi, setTotalOfflineCmimi] = useState(0);

  // when ofline
  const [offlineData, setDataOffline] = useState([]);

  const handleButtonClick = async (tableNumber) => {
    setLoading(true);
    const tableExists = tavolinaObjects.some(
      (item) => item.Tavolina === tableNumber
    );

    const openedTableItem = tavolinaObjects.find(
      (item) => item.Tavolina === tableNumber
    );

    try {
      if (tableExists) {
        setTableExist(true);
        if (openedTableItem.Operator.toLowerCase() !== operator.toLowerCase()) {
          showToast("User ndryshe");
          setLoading(false);
          return;
        }
        const Id = openedTableItem.Id;

        try {
          setTimeout(async () => {
            const response = await mainAxios(`/pos/${Id}`);

            if (response.data.NIVF !== null) {
              setOpen(false);
              showToast("Fatura e fiskalizuar");
              setLoading(false);
              return;
            }

            if (response.status === 200) {
              setLoading2(true);
              setState(response.data);
              setNewId(Id);
              setOra(response.data.Hapur);

              try {
                const responseDtl = await mainAxios(`/posdtl/master/${Id}`);
                if (responseDtl.status === 200) {
                  setDtlState(responseDtl.data);
                }
              } catch (error) {
                console.error(error);
              } finally {
                setOpen(true);
                setLoading2(false);
              }
            }
          }, 50);
        } catch (err) {
          console.error(err);
          setDtlState([]);

          showToast("Ndodhi problem me marrjen e te dhenave", {
            severity: "error",
          });
        } finally {
          setLoading(false);
        }
      } else {
        const { Id, ...postDataWithoutId } = {
          ...state,
          Aktiv: Number(state.Aktiv),
          Data: currentDate + "T00:00:00",
          Hapur: currentDateAndTime,
          E_Paguar: Number(state.E_Paguar),
          Kodi: state.Kodi,
          Operator: `${operator}`,
          Tavolina: tableNumber,
          Tip_Cmimi: state.Tip_Cmimi,
          Tipi: state.Tipi,
          KLFU_Kodi: "",
          KLFU_Pershkrim: "",
          Punonjes_Pershkrim: "",
        };

        //store data offline
        if (!isOnline) {
          const requestData = {
            url: "/pos",
            body: postDataWithoutId,
          };

          setDataOffline(requestData);
          setLoading2(false);
          showToast("Tavolina u ruajt për dërgesë pasi të keni internet");
          setDtlState([]);
          setOra(postDataWithoutId.Hapur);
          setNumTav(tableNumber);
          setOpen(true);
          setLoading(false);
          return;
        }

        try {
          setTableExist(false);
          setTimeout(async () => {
            const response = await mainAxios.post("/pos", postDataWithoutId, {
              headers: { "Content-Type": "application/json" },
            });

            if (response.status === 200) {
              const newEntryID = response.data.Id;
              setLoading2(false);
              setDtlState([]);
              setNewId(newEntryID);
              setOra(postDataWithoutId.Hapur);
              setState(response.data);
            }
          }, 50);
        } catch (err) {
          showToast("Ndodhi problem me hapjen e tavolines");
          setError(true);
          console.error(err);
          setOpen(false);
          handleClose(true);
        } finally {
          setLoading(false);
        }
      }

      setNumTav(tableNumber);
      setOpenedTable((prevOpenedTable) => {
        if (prevOpenedTable) return [...prevOpenedTable, tableNumber];
      });

      const updatedDivs = divs.map((div) =>
        div.id === tableNumber ? { ...div, backgroundColor: "green" } : div
      );
      setDivs(updatedDivs);
      tavolinaFundit(tableNumber);
      setOpen(true);
    } catch (err) {
      console.error(err);
      showToast("Ndodhi problem me hapjen e tavolines", {
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 2000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const currentDate = formatDate(new Date());

  const currentDateAndTime = formatDate(new Date(), true);

  useEffect(() => {
    if (panelName === btnSektor) {
      const localDivs =
        JSON.parse(localStorage.getItem(`divs${panelName}`)) || [];
      setDivs(localDivs);
    }
  }, [panelName, btnSektor]);

  const mbylleTav = async (text, severity) => {
    setLoading(true);

    const { ...postDataWithoutId } = {
      Aktiv: 0,
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };
    try {
      const response = await mainAxios.put(`/pos/${newId}`, postDataWithoutId, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status !== 200) {
        showToast("Ndodhi problem me mbylljen e tavolines", {
          severity: "error",
        });
      } else {
        showToast(text, { severity: severity });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      if (open) {
        setOpen(false);
        await fetchPos();
      }
      setLoading(false);
    }
  };

  const handleClose = async () => {
    if (dtlNum === 0 && open) {
      await mbylleTav("Nuk keni asnje artikull, tavolina u mbyll", "info");
    }
    if (dtlNum !== 0 && open) {
      setOpen(false);
      await fetchPos();
    }
  };

  const handleSelectDiv = (div) => {
    setSelectedDiv(div);
  };

  if (panelName !== btnSektor) return null;

  const numTavolinave = btntavolinat[panelName] || [];
  const divWidth = isMobile ? "125px" : "150px";
  const divHeight = isMobile ? "125px" : "150px";
  const spaceBetween = "5px";

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="kthePAnelet">
          <Toast ref={toast} />
          <div
            className="kthePAnelet1"
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: isMobile ? "95%" : "",
              overflowY: isMobile ? "scroll" : "none",
            }}
          >
            {numTavolinave.map((tableNumber) => (
              <div
                key={tableNumber}
                className="tavolinat"
                id={
                  openedTable.some((table) => table === tableNumber)
                    ? "tavolineHapur"
                    : ""
                }
                onClick={() => handleButtonClick(tableNumber)}
                style={{
                  width: divWidth,
                  height: divHeight,
                  margin: spaceBetween,
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  position: "relative",
                }}
              >
                {tavolinaObjects.find((item) => item.Tavolina === tableNumber)
                  ?.Operator ? (
                  <div style={{ position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        top: -30,
                        right: 30,
                        fontSize: "16px",
                        width: "30px",
                        height: "30px",
                        color: "#fff",
                        textAlign: "center",
                        backgroundColor: "#D92027",
                        borderRadius: "5px",
                        alignSelf: "self-start",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {
                        tavolinaObjects.find(
                          (item) => item.Tavolina === tableNumber
                        )?.Operator
                      }
                    </span>
                  </div>
                ) : null}

                <div className="title_table">
                  <h2>{tableNumber}</h2>
                </div>
                {tavolinaObjects.find((item) => item.Tavolina === tableNumber)
                  ?.Vlera && (
                  <div className="price_table">
                    <span>
                      <AttachMoneyIcon />{" "}
                      {
                        tavolinaObjects.find(
                          (item) => item.Tavolina === tableNumber
                        )?.Vlera
                      }
                    </span>
                  </div>
                )}

                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: divWidth,
                    height: "30px",
                    backgroundColor: tavolinaObjects.some(
                      (item) => item.Tavolina === tableNumber
                    )
                      ? "#D92027"
                      : "#B6F7C1",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                ></div>
              </div>
            ))}
            <FullScreenDialog
              open={open}
              aExistTable={aExistTable}
              newId={newId}
              handleClose={handleClose}
              mbylleTav={mbylleTav}
              setDtlNum={setDtlNum}
              numTav={numTav}
              state={state}
              setState={setState}
              formatDate={formatDate}
              dtlState={dtlState}
              isMobile={isMobile}
              defaultState={defaultState}
              timeOnly={timeOnly}
              klienti={klienti}
              showToast={showToast}
              fetchPos={fetchPos}
              error={error}
              setError={setError}
              noDataMessage={noDataMessage}
              setNoDataMessage={setNoDataMessage}
              art={art}
              kls01={kls01}
              menyra={menyra}
              setLoading={setLoading}
              loading={loading}
              loadingLeft={loadingLeft}
              setLoading2={setLoading2}
              arka={arka}
              setOpen={setOpen}
              isOnline={isOnline}
              offlineData={offlineData}
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              setTotalOfflineCmimi={setTotalOfflineCmimi}
            />
          </div>
          {divs.map((div) => (
            <DivComponent
              key={div.id}
              div={div}
              selectedDiv={selectedDiv}
              handleSelectDiv={handleSelectDiv}
              customizeDiv={""}
              enable={false}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default KthePanelet;
