const RaportButtons = [
  {
    key: "1-0",
    label: "ARTIKULL",
    type: "input",
    value: "",
    reduxGet: "artikujPag",
  },
  {
    key: "1-0-1",
    label: "ARTIKULL",
    type: "input",
    value: "",
    reduxGet: "artikujPag",
  },
  {
    key: "1-0-1-1",
    label: "ARTIKULLI",
    type: "input",
    value: "",
    reduxGet: "artikujPag",
  },
  {
    key: "4-8",
    label: "BARKOD_NGA",
    type: "input",
    value: "",
  },
  {
    key: "4-9",
    label: "BARKOD_DERI",
    type: "input",
    value: "",
  },
  {
    key: "1-2",
    label: "MAGAZINA",
    type: "input",
    value: "",
    reduxGet: "magazina",
  },
  {
    key: "1-2-1",
    label: "MAGAZINA",
    type: "input",
    value: "",
    reduxGet: "magazina",
  },
  {
    key: "1-3",
    label: "KLASIFIKIM1",
    type: "input",
    value: "",
    reduxGet: "grupi",
  },
  {
    key: "1-3-1",
    label: "KLASIFIKIM1",
    type: "input",
    value: "",
    reduxGet: "grupi",
  },
  {
    key: "1-4",
    label: "SKEMA",
    type: "input",
    value: "",
    reduxGet: "skema",
  },
  {
    key: "1-4-1",
    label: "SKEMA",
    type: "input",
    value: "",
    reduxGet: "skema",
  },
  {
    key: "1-5",
    label: "Tarifa",
    type: "input",
    value: "",
    reduxGet: "tarifa",
  },
  {
    key: "1-6",
    label: "GJENDJE",
    type: "input",
    value: "",
    reduxGet: "gjendje",
  },
  {
    key: "1-6-1",
    label: "GJENDJE",
    type: "input",
    value: "",
    reduxGet: "gjendje",
  },
  {
    key: "1-7",
    label: "AAM",
    type: "input",
    value: "",
    reduxGet: "",
  },
  {
    key: "1-7-1",
    label: "Aktiv",
    type: "input",
    value: "",
    reduxGet: "",
  },
  {
    key: "1-8",
    label: "KLASIFIKIM2",
    type: "input",
    value: "",
    reduxGet: "klasifikim2",
  },
  {
    key: "1-9",
    label: "KLASIFIKIM3",
    type: "input",
    value: "",
    reduxGet: "klasifikim3",
  },
  {
    key: "2-0",
    label: "SKEMA",
    type: "input",
    value: "",
    reduxGet: "skema",
  },
  {
    key: "2-1",
    label: "GJENDJE",
    type: "input",
    value: "gjendje",
  },
  {
    key: "2-2",
    label: "KLASIFIKIM4",
    type: "input",
    value: "",
    reduxGet: "klasifikim4",
  },
  {
    key: "2-3",
    label: "MONEDHA",
    type: "input",
    value: "",
    reduxGet: "monedha",
  },
  {
    key: "2-4",
    label: "FURNITORI",
    type: "input",
    value: "",
    reduxGet: "furnitor",
  },
  {
    key: "2-5",
    label: "KLIENT",
    type: "input",
    value: "",
    reduxGet: "klienti",
  },
  {
    key: "2-5-1",
    label: "KLIENTI",
    type: "input",
    value: "",
    reduxGet: "klienti",
  },
  {
    key: "2-6",
    label: "TIPI",
    type: "input",
    value: "",
    reduxGet: "tipi",
  },
  {
    key: "2-7",
    label: "SHENIM",
    type: "input",
    value: "",
  },
  {
    key: "2-7-1",
    label: "SHENIMI",
    type: "input",
    value: "",
  },
  {
    key: "2-9",
    label: "VEPRIMI",
    type: "input",
    value: "",
    reduxGet: "veprimi",
  },
  {
    key: "2-9-1",
    label: "VEPRIMI",
    type: "input",
    value: "",
    reduxGet: "veprimi",
  },
  {
    key: "3-0",
    label: "ARKA",
    type: "input",
    value: "",
    reduxGet: "banka",
  },
  {
    key: "3-0-1",
    label: "ARKA",
    type: "input",
    value: "",
    reduxGet: "arkaList",
  },
  {
    key: "3-1",
    label: "QYTETI",
    type: "input",
    value: "",
    reduxGet: "qytetet",
  },
  {
    key: "3-2",
    label: "BANKA",
    type: "input",
    value: "",
    reduxGet: "banka",
  },
  {
    key: "3-3",
    label: "TRANSPORTUES",
    type: "input",
    value: "",
    reduxGet: "transportuesi",
  },
  {
    key: "3-4",
    label: "TIPI",
    type: "input",
    value: "",
    reduxGet: "tipi0",
  },
  {
    key: "3-5",
    label: "TIPI",
    type: "input",
    value: "",
    reduxGet: "tipi2",
  },
  {
    key: "3-6",
    label: "MENYRA",
    type: "input",
    value: "",
    reduxGet: "menyraPageses",
  },
  {
    key: "3-6-1",
    label: "MENYRA",
    type: "input",
    value: "",
    reduxGet: "menyraPageses",
  },
  {
    key: "3-7",
    label: "PUNONJES",
    type: "input",
    value: "",
    // reduxGet: "punonjes",
  },
  {
    key: "3-8",
    label: "Nipt",
    type: "input",
    value: "",
  },
  {
    key: "3-9",
    label: "Nr.Dok",
    type: "input",
    value: "",
  },
  {
    key: "4-0",
    label: "Nr.Serial",
    type: "input",
    value: "",
  },
  {
    key: "4-1",
    label: "Vl. Paguar",
    type: "input",
    value: "",
  },
  {
    key: "4-2",
    label: "Tot. Fature",
    type: "input",
    value: "",
  },
  {
    key: "4-3",
    label: "Operator",
    type: "input",
    value: "",
    reduxGet: "perdorues",
  },
  {
    key: "4-4",
    label: "Nr.Fat",
    type: "input",
    value: "",
  },

  {
    key: "4-5",
    label: "Agjenti",
    type: "input",
    value: "",
    reduxGet: "agjenti",
  },
  {
    key: "4-6",
    label: "START_VLERA",
    type: "input",
    value: "",
  },
  {
    key: "4-7",
    label: "END_VLERA",
    type: "input",
    value: "",
  },
  {
    key: "5-0",
    label: "VEPRIMI",
    type: "input",
    value: "",
    reduxGet: "veprimi",
  },
];

export default RaportButtons;
