import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ArticleIcon from "@mui/icons-material/Article";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// import SettingsIcon from "@mui/icons-material/Settings";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import WarehouseIcon from "@mui/icons-material/Warehouse";

const items = [
  {
    id: 1,
    text: "Faturë Blerje",
    url: "/fature-blerje",
    photo: <DescriptionIcon fontSize="medium" className="opacity-70" />,
  },
  {
    id: 2,
    text: "Faturë Shitje",
    url: "/fature-shitje",
    photo: <RequestQuoteIcon fontSize="medium" className="opacity-50" />,
  },
  {
    id: 3,
    text: "Artikuj",
    url: "/artikuj",
    photo: <ArticleIcon fontSize="medium" className="opacity-50" />,
  },
  {
    id: 4,
    text: "POS",
    url: "/pos",
    photo: <PointOfSaleIcon fontSize="medium" className="opacity-50" />,
    sublinks: [
      {
        id: 4.1,
        text: " BAR ",
        url: "/pos/bar",
        photo: <RestaurantIcon fontSize="small" className="opacity-50" />,
      },
      {
        id: 4.2,
        text: "Market",
        url: "/pos/market",
        photo: (
          <LocalGroceryStoreIcon fontSize="small" className="opacity-50" />
        ),
      },
      {
        id: 10,
        text: "Xhiro",
        photo: (
          <AssignmentTurnedInIcon fontSize="small" className="opacity-50" />
        ),
      },
    ],
  },
  {
    id: 5,
    text: "Fletë Hyrje",
    url: "/flete-hyrje",
    photo: (
      <FormatIndentIncreaseIcon fontSize="medium" className="opacity-50" />
    ),
  },
  {
    id: 6,
    text: "Fletë Dalje",
    url: "/flete-dalje",
    photo: (
      <FormatIndentDecreaseIcon fontSize="medium" className="opacity-50" />
    ),
  },
  {
    id: 7,
    text: "Celje",
    url: "/celje/klienti",
    photo: <SaveAsIcon fontSize="medium" className="opacity-50" />,
  },
  // {
  //   id: 8,
  //   text: "Konfigurime",
  //   url: "/konfigurime",
  //   photo: <SettingsIcon fontSize="medium" className="opacity-50" />,
  // },
  {
    id: 9,
    text: "Fiskale",
    url: "/fiskale",
    photo: <FactCheckIcon fontSize="medium" className="opacity-50" />,
  },
  {
    id: 10,
    text: "Ndermarrje",
    url: "/ndermarrje",
    photo: <WarehouseIcon fontSize="medium" className="opacity-50" />,
  },
];

export default items;
