import React, { useState } from "react";
import "./TabelaSuperMarket.scss";
import PrimeGridSuperMarket from "../PrimeGridSuperMarket/PrimGridSuperMarket";

const TabelaSupermarket = ({ newDesign, rows, setRows, calculateData }) => {
  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Cmimi", title: "Cmimi" },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Skonto", title: "Skonto (%)" },
    { field: "Skonto_Vlera", title: "Skonto (VL)", allowSum: true },
    { field: "Vlera_Pa_Tvsh", title: "Vlera Pa Tvsh", allowSum: true },
    { field: "Total", title: "Total", allowSum: true },
  ]);
  const total = rows.reduce((acc, row) => acc + row.Vlera, 0);
  const formattedTotal = total.toFixed(2);
  const defaultColumnsStorage = columns.map((column) => column.title);

  // Generate row numbers for rows not in the data
  const missingRowNumbers = Array.from(
    { length: rows.length + 1 },
    (_, index) => index + 1
  ).filter((num) => !rows.find((row) => row.Nr === num));

  return (
    <div
      className={newDesign ? "tabela_supermarket" : "tabela_supermarket_100"}
    >
      <div className="wrap_div_dataTable">
        <PrimeGridSuperMarket
          gridKey="Supermarket"
          contextKey={"pos/dtl"}
          isEditable={true}
          columns={columns}
          setColumns={setColumns}
          disabled={false}
          defaultColumnsStorage={defaultColumnsStorage}
          rows={rows}
          setRows={setRows}
          calculateData={calculateData}
          missingRowNumbers={missingRowNumbers}
        />
        <div
          className="footer_total flex"
          style={{ justifyContent: "space-between" }}
        >
          <h1 style={{ fontSize: "60px" }}>Total</h1>

          <h1 style={{ color: "inherit", fontSize: "60px" }}>
            {formattedTotal}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default TabelaSupermarket;
