import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import useStorage from "../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import "./Celje.scss";
import { Toast } from "primereact/toast";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TabMainCelje from "./PaneliTabeveCelje/TabMainCelje";
import TabTjeraCelje from "./PaneliTabeveCelje/TabTjera";
import SmallButtonGroupC from "./SmallButtonGroupC";
import { getPerdorues } from "../../store/globalSlice";
import Footer from "../../components/Navbar/Footer";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PrimeGridKF from "./PrimeGridKlient";
import TabWebRights from "./PaneliTabeveCelje/TabWebRights";
import SidebarCelje from "./SideBarCelje";
import { useUser } from "../../zustand/common";

const PerdoruesC = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;

  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const userCurrent = user.user;
  const [lastData, setLastData] = useState({});
  const toast = useRef(null);

  // Redux: Get client data from global slice
  const perdoruesRedux = useSelector((state) => state.globalSlice.perdorues);

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();

        setdata(perdoruesRedux);
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
        hideLoading();
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [perdoruesRedux, showLoading, hideLoading]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "Operator", title: "Operator" },
    { field: "Pershkrim", title: "Pershkrim" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatMagazines"
  );
  let defaultState = {
    AAM: "",
    Adresa: "",
    Aktiv: true,
    Arka_Kodi: "",
    BakshishPerqindje: "",
    Banka_Kodi: "",
    Blerje_Arka_Banka_Kodi: "",
    Blerje_KlFu_Kodi: "F001",
    Blerje_Menyra_Pageses_Id: "",
    Blerje_Mon: "",
    Blerje_Paguar_Ne: "BANKA",
    Cel: "",
    Dep_Kodi: "",
    Email: "",
    EshteBanakier: "",
    EshteKasier: "",
    EshteNeList: "",
    Fjalekalim: "",
    Gjuha: "SHQIP",
    HAPAUTOMATIKISHT: "", //num
    Klient_Kodi: "",
    Magazina_Drejt: "",
    Magazina_Kodi: "",
    MenuRibbonSipasMenuPeme: "",
    Menu_ID: "", //num
    MobileActiv: "",
    Monedha_Kodi: "",
    MundTeBejLogOut: "",
    Ndermarrje_Drejt: "",
    Ndermarrje_Sel: nipt,
    NotForSync: "",
    Operator: "",
    POSPIN: "",
    PagaOre: "",
    Pakice: "",
    Password: "",
    Pershkrim: "",
    Punonjes_ID: "",
    Qyteti_Kodi: "",
    Raporte_Drejt: "",
    Scripter_Indeksi: "",
    SelectWhereData: "",
    Selektuar: "",
    ShfaqMenuPeme: "",
    ShfaqMenuRibbon: "",
    Shitje_Arka_Banka_Kodi: "",
    Shitje_KlFu_Kodi: "",
    Shitje_Menyra_Pageses_Id: "",
    Shitje_Mon: "",
    Shitje_Paguar_Ne: "",
    Shteti_Kodi: "",
    Shumice: "",
    Skin: "",
    Sportel_Kodi: "",
    Station: "",
    Tel: "",
    TipMenu: "", //num
    Tip_Cmimi: "",
    Updated: "",
    Updated_Nga_Operator: userCurrent,
    Users_Grup_Id: "", //num
    fisBusinessAdress: "",
    fisBusinessCode: "",
    fisBusinessNIPT: "",
    fisBusinessName: "",
    fisDevPOSOperator: "",
    fisOperator: "",
    fisPassword: "",
    fisTCRCode: "",
    mArka: "",
    mArt: "",
    mArtListRight: "", //num
    mAutomaticOpen: "", //num
    mBanka: "",
    mBarRestorant: "",
    mBlueToothTest: "",
    mChangePrice: "",
    mCloseDocumentAfterPrint: "",
    mCloseDocumentAfterSave: "",
    mClosePOS: "",
    mCmimiBleresShow: "",
    mCmimiJoneShow: "",
    mConfigDB: "",
    mDBSinkronizim: "",
    mDetyrat: "",
    mDevicesCanLogin: "",
    mFLD: "",
    mFLH: "",
    mFTBL: "",
    mFTSH: "",
    mInventory: "",
    mNotRound: "",
    mPOS: "",
    mPOSChangePrice: "",
    mPOSOnlyOwnDocuments: "",
    mPorosi: "",
    mPreOrder: "",
    mProdhim: "",
    mQRCodeFTSH: "",
    mRaportFurnitor: "",
    mRaportKlient: "",
    mRaportPorosi: "",
    mRaporteTePergjithshme: "",
    mReferencat: "",
    mReportArt: "",
    mReportFTBL: "",
    mReportFTSH: "",
    mReportPOS: "",
    mReports: "",
    mScanLocalDB: "",
    mSearchClientFromCalls: "",
    mShowClientInPOS: "",
    mShowEmployeeInPOS: "",
    mShowNavigator: "",
    mUploadToServer: "",
    mUserManager: "",
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [visibleRight, setVisibleRight] = useState(false);

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Perdoruesi
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SidebarCelje
          currentAction={currentAction}
          toast={toast}
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        />
      </div>
    );
  };

  const deleteMethod = async (e) => {
    try {
      const response = await mainAxios.delete(`/perdorues/${state.Id}`);

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        toast.current.show({
          severity: "info",
          summary: "u fshi",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u fshi." + error.message,
      });
    }
  };

  const handleAdd = async (e) => {
    try {
      // Adjust this object to match the expected fields for the endpoint
      const payload = {
        Pershkrim: state.Pershkrim,
        Operator: state.Operator,
        Password: state.Password,
        Gjuha: state.Gjuha,
        Email: state.Email,
        HAPAUTOMATIKISHT: state.Users_Grup_Id,
        MobileActiv: state.MobileActiv,
        Ndermarrje_Sel: state.Ndermarrje_Sel,
        MundTeBejLogOut: true,
        Menu_ID: 1,
        Pakice: true,
        Shitje_Paguar_Ne: "ARKA",
        Shitje_KlFu_Kodi: "K001",
        Shumice: true,
        TipMenu: 2,
        Updated_Nga_Operator: state.Updated_Nga_Operator,
        Users_Grup_Id: state.Users_Grup_Id,
        fisOperator: state.fisOperator,
      };

      const response = await mainAxios.post(`/perdorues`, payload);

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U rregjistrua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u regjistrua." + error.message,
      });
    }
  };

  const handleModify = async (e) => {
    try {
      const response = await mainAxios.put(`/perdorues/${state.Id}`, {
        ...state,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U modifikua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u modifikua " + error.message,
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      {/* {isChecked && ( */}
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.Id}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
        // deleteHandler={deleteHandler}
      />
    </div>
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";
  const button7Text = isMobile ? "" : "Web Rights";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      includeButton7={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      button7Text={button7Text}
      button7Icon={<FactCheckIcon />}
    />
  );

  const TabiKryesor = (
    <TabMainCelje
      state={state}
      TextGrupi={true}
      TextEmri={true}
      TextPerdorues={true}
      TextFjalkalim={true}
      disabled={disabled}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraCelje
        isTransport={true}
        TextOperator={true}
        AktivM={true}
        AktivBarPos={true}
        TextCel={true}
        setState={setState}
        TextEmail={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        state={state}
      />
      <TabWebRights
        Aktiv1={true}
        Aktiv2={true}
        Aktiv3={true}
        Aktiv4={true}
        Aktiv5={true}
        Aktiv6={true}
        Aktiv7={true}
        setState={setState}
        TextEmail={true}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh-c">
      <NavBar />

      <div className={isMobile ? null : "template"}>
        <div className="p-2 main-container-c">
          <div className="top_panel_fsh-c">
            <Panel
              headerTemplate={template}
              toggleable
              style={{ height: "100%" }}
            >
              <div className="bg-white" style={{ height: "100%" }}>
                <Toast ref={toast} />
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve-c">
                      <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                      <div className="paneli-fis-print-c"></div>
                    </div>
                    <div className={"below-tabs-c"}>
                      <div className="below-tabs1-c">{TabiKryesor}</div>
                      <div className="below-tabs2-c">{smallTabsKM}</div>
                      <div className="below-tabs3-c"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>

          <div className={!isMobile ? "middle_fsh-c" : null}></div>

          <div className="dataTable_fsh-c">
            <PrimeGridKF
              gridKey="Perdorues"
              data={data}
              defaultState={defaultState}
              isEditable={true}
              defaultColumnsStorage={defaultColumnsStorage}
              columns={columns}
              setColumns={setColumns}
              setLastData={setLastData}
              disabled={disabled}
              setdata={setdata}
              setState={setState}
              rows={rows}
              state={state}
              setRows={setRows}
              selectedColumns={selectedColumns}
              setselectedcolumns={setselectedcolumns}
              currentAction={currentAction}
            />
          </div>
        </div>
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(PerdoruesC);
