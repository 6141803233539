// const shtoArtikull = (artikull, rows, setRows, state) => {
//   if (!artikull) return;
//   let existingIndex = rows.findIndex((row) => row.Id === artikull?.Id);
//   if (existingIndex !== -1) {
//     const updatedRow = calculateData(
//       { Cmimi: artikull.Cmimi },
//       rows[existingIndex],
//       state
//     );
//     let updatedRows = [...rows];
//     updatedRows[existingIndex] = updatedRow;
//     setRows(updatedRows);
//   } else {
//     const newItem = {
//       ...artikull,
//       Sasia: 1,
//       Cmimi: artikull.Cmimi ? artikull.Cmimi : 0,
//       Vlera: 0,
//     };
//     const calculatedItem = calculateData(
//       { Cmimi: newItem.Cmimi },
//       newItem,
//       state
//     );
//     setRows((rows) => [calculatedItem, ...rows]);
//   }
// };

const shtoArtikull = (artikull, rows, setRows, state) => {
  if (!artikull) return;
  // let currObj = rows.find((row) => row.Id === artikull?.Id);
  // if (currObj) {
  //   return;
  // }

  // Calculate the initial item
  const newItem = {
    ...artikull,
    Sasia: 1,
    Cmimi: artikull.Cmimi ? artikull.Cmimi : 0,
    Vlera: 0,
  };

  // Calculate the new item data
  const calculatedItem = calculateData(
    { Cmimi: newItem.Cmimi },
    newItem,
    state
  );

  setRows((rows) => [calculatedItem, ...rows]);
};

const calculateData = (field, item) => {
  let { Sasia, Cmimi, Vlera } = item;

  const [[cell, cellVal]] = Object.entries(field);

  try {
    let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
    let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);
    if (cell === "Cmimi") {
      Cmimi = cellVal;
      Vlera = (SasiaPrv * CmimiPrv).toFixed(2);
    }

    if (cell === "Sasia") {
      Sasia = cellVal;
      Vlera = (SasiaPrv * CmimiPrv).toFixed(2);
    }

    if (cell === "Vlera") {
      Vlera = cellVal;
      Cmimi = (Vlera / Math.abs(Sasia)).toFixed(2);
    }
    return {
      ...item,
      Sasia,
      Cmimi,
      Vlera,
    };
  } catch (error) {
    Vlera = 0;
    return item;
  }
};

export { shtoArtikull, calculateData };
