import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
// import Pergjegjes from "../../minimodal/Pergjegjes";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";

const TabPaguar = ({
  setState,
  popupContextKey,
  toggleState,
  disabled,
  handleChange,
  state,
  formatDate,
  rows,
  menyrapageses,
  setSelectedArkaMon,
}) => {
  // redux arka/banka (get requesti i Arka/Banka)
  const arka = useSelector((state) => state.globalSlice.banka);
  // console.log(arka, "arka");

  // Calculate Total
  useEffect(() => {
    if (state.Menyra_Pageses_Id === 6 || state?.Menyra_Pageses_ID === 6) {
      const sumTotal = rows.reduce((acc, row) => acc + row.Total, 0);
      handleChange("Paguar", sumTotal.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Menyra_Pageses_Id, state?.Menyra_Pageses_ID, rows]);

  const handleChangeArka = (key, value) => {
    setSelectedArkaMon(value.Mon);
    setState((state) => {
      return {
        ...state,
        Arka_Banka_Kodi: value.Kodi,
      };
    });
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2">
        {/* Button Menyra */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-menyra1"
          options={menyrapageses}
          getOptionLabel={(option) => option.Id && option.Pershkrim}
          value={
            menyrapageses.find((MenyraP) =>
              popupContextKey === "FatureShitje"
                ? MenyraP.Id === state?.Menyra_Pageses_ID
                : MenyraP.Id === state?.Menyra_Pageses_Id
            ) || null
          }
          onChange={(_, newValue) => {
            popupContextKey === "FatureShitje"
              ? handleChange("Menyra_Pageses_ID", newValue?.Id || "")
              : handleChange("Menyra_Pageses_Id", newValue?.Id || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Menyra" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Id}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
            </div>
          )}
        />

        {/* Button Arke/Banke */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-arka2"
          options={arka}
          getOptionLabel={(option) =>
            option
              ? option.Kodi + " - " + option.Pershkrim + " - " + option.Mon
              : ""
          }
          value={
            arka.find((ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi) || null
          }
          onChange={(_, newValue) => {
            handleChangeArka("Arka_Banka_Kodi", newValue || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Arke/Banke" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Kodi}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              <div style={{ textAlign: "end" }}>{option.Mon}</div>
            </div>
          )}
          PaperComponent={(props) => (
            <Paper {...props} /> // Adjust the width as needed
          )}
        />

        {/* Buttoni Paguar */}
        <TextField
          label="Paguar"
          fullWidth
          disabled={disabled}
          type="number"
          size="small"
          variant="outlined"
          value={state?.Paguar}
          onChange={(e) => handleChange("Paguar", e.target.value)}
        />
        {/* Afati Data */}
        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Afati PagesesData"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Afati_PagesesData)}
          onChange={(e) => handleChange("Afati_PagesesData", e.target.value)}
          size="small"
        />

        {/* <div className="flex gap-2 text-center items-center  ">
          <span>Ose për</span>
          <input
            className="rounded-md w-20 border"
            disabled={disabled}
            type="number"
            value={state?.Afati_PagesesDite}
            onChange={(e) => handleChange("Afati_PagesesDite", e.target.value)}
          />
          <span>ditë</span>
        </div> */}
        {/* <div className="bg-gray-100 rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative"> */}
        {/* //////////// */}
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={menyrapageses}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Përgjegjës" />
            )}
          /> */}
        {/* ////// MiniModal */}
        {/* <div className="hover:scale-110 transition-all"></div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TabPaguar;
