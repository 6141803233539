import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteFature = ({ setDialogOpen, selectedId, toast, currentFatura }) => {
  const functionDelete = () => {
    if (selectedId === "") {
      toast.current.show({
        severity: "info",
        summary: "Selekto nje Fature",
      });
      return;
    }
    if (currentFatura.TIPI === "_BAK") {
      toast.current.show({
        severity: "info",
        summary: "Fature eshte e mbyllur nuk mund te fshihet",
      });
      return;
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <Button
      disabled={!selectedId}
      variant="outlined"
      startIcon={<DeleteIcon style={{ color: "red" }} />}
      onClick={functionDelete}
      style={{ margin: "5px" }}
    >
      Fshi Faturen
    </Button>
  );
};

export default DeleteFature;
