import React from "react";
import "./print80Skontrino.scss";

function SkontrinoXhiroFis({ state, menyrapageses, nipt }) {
  // Find the Menyra Pageses entry by ID and return its description
  const pagesaPershkrim = menyrapageses.find(
    (menyraP) => menyraP.Id === state.Menyra_Pageses_Id
  )?.Pershkrim;

  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <b>Fature Tatimore</b>
          </td>
          <td className="tds-footer" colSpan={4}>
            Ndermarrje: {nipt}
          </td>

          <td className="tds-footer" colSpan={4}>
            Operator:{" "}
            {state.fisOperatorCode ? state.fisOperatorCode : state.Operator}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <hr className="dashed-line" />
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Data dhe Ora :{" "}
            {state.UPDATED ||
              state.Updated ||
              state.Hapur ||
              state.DATA ||
              state.Data}
          </td>
        </tr>
        {state.TAVOLINA && (
          <tr>
            <td className="columnsSkontrino">Tavolina: {state.TAVOLINA}</td>
          </tr>
        )}

        <tr>
          <td className="columnsSkontrino">
            Menyra e Pageses: {pagesaPershkrim}
          </td>
        </tr>
        {state.KLFU_PERSHKRIM && (
          <tr>
            <td className="columnsSkontrino" colSpan={4}>
              Klienti: {state.KLFU_PERSHKRIM}
            </td>
          </tr>
        )}
      </thead>
      <hr />
      <tbody>
        <tr>
          <td colSpan={4} className="hr-dashed"></td>
        </tr>
      </tbody>
    </table>
  );
}

export default SkontrinoXhiroFis;
