import React from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import Grupi from "./MiniModalArtikuj/Tepergjithshme/Grupi";
import Tarifat from "./MiniModalArtikuj/Tepergjithshme/Tarifat";
import { useSelector } from "react-redux";
import "./TePergjithshme.scss";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const TePergjithshme = ({
  isMobile,
  disabled,
  state,
  setState,
  handleChange,
}) => {
  // tarifa redux
  const tarifaRedux = useSelector((state) => state.globalSlice.tarifa);
  const tarifa = tarifaRedux;

  // grupi redux
  const grupiRedux = useSelector((state) => state.globalSlice.grupi);
  const grupi = grupiRedux;

  // magazina redux
  // const magazinaRedux = useSelector((state) => state.globalSlice.magazina);
  // const magazina = magazinaRedux;

  // skema redux
  // const skemaRedux = useSelector((state) => state.globalSlice.skema);
  // const skema = skemaRedux;

  const skema = [
    { Kodi: "001", Pershkrim: "Mallra" },
    { Kodi: "002", Pershkrim: "Materiale te para" },
    { Kodi: "003", Pershkrim: "Materiale te tjera" },
    { Kodi: "004", Pershkrim: "Amballazh i kthyeshem" },
    { Kodi: "005", Pershkrim: "Inventari i Imet" },
    { Kodi: "006", Pershkrim: "Material ndihmes" },
    { Kodi: "007", Pershkrim: "Lende djegese" },
    { Kodi: "008", Pershkrim: "Pjese nderimi" },
    { Kodi: "009", Pershkrim: "Material ambalazhimi" },
    { Kodi: "010", Pershkrim: "Ndertesa" },
    {
      Kodi: "011",
      Pershkrim:
        "Instalime teknike, makineri, pajisje,instrumente dhe vegla pune",
    },
    { Kodi: "012", Pershkrim: "Mjete transporti" },
    { Kodi: "013", Pershkrim: "Mobilje dhe pajisje zyre" },
    {
      Kodi: "014",
      Pershkrim: "Koncensione dhe të drejta të ngjashme, licensa",
    },
    { Kodi: "015", Pershkrim: "Të tjera AA jomateriale" },
    { Kodi: "016", Pershkrim: "Pajisje informative" },
    { Kodi: "017", Pershkrim: "Te tjera" },
    { Kodi: "018", Pershkrim: "Toka" },
    { Kodi: "019", Pershkrim: "Sherbime" },
  ];

  // nejsia redux
  // const njesiaRedux = useSelector((state) => state.globalSlice.njesia);
  // const njesia = njesiaRedux;

  const njesia = [
    { Kodi: "BIDO", Pershkrim: "BIDO" },
    { Kodi: "COP", Pershkrim: "Cope" },
    { Kodi: "KG", Pershkrim: "Kilogram" },
    { Kodi: "KOL", Pershkrim: "Koli" },
    { Kodi: "KT", Pershkrim: "Kuti" },
    { Kodi: "KV", Pershkrim: "Kuintal" },
    { Kodi: "LIT", Pershkrim: "Litra" },
    { Kodi: "M2", Pershkrim: "Metro Katrore" },
    { Kodi: "M3", Pershkrim: "Metro Kub" },
    { Kodi: "ML", Pershkrim: "Metro Lineare" },
    { Kodi: "ORE", Pershkrim: "Ore Pune" },
    { Kodi: "TON", Pershkrim: "Ton" },
    { Kodi: "TUF", Pershkrim: "tufa zorre 80-90m" },
  ];
  const numGrupi = `${grupi ? grupi.length : 0} Rekorde`;
  const numTarifa = `${tarifa ? tarifa.length : 0} Rekorde`;

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? 1 : 0) : value,
        [key]: key === "Shumice" ? (value ? 1 : 0) : value,
        [key]: key === "Pos_NeList" ? (value ? 1 : 0) : value,
        [key]: key === "Pakice" ? (value ? 1 : 0) : value,
        [key]: key === "Distribucion" ? (value ? 1 : 0) : value,
        [key]: key === "AAM" ? (value ? 1 : 0) : value,
      };
    });
  };

  return (
    <div className={`second_div flex ${isMobile ? "flex-col" : ""} `}>
      <div
        className={`first-border-pergjithshme flex ${
          isMobile ? "flex-col gap-4" : "gap-3"
        } border p-4`}
      >
        <div className="kutizat">
          <TextField
            InputLabelProps={{ shrink: true }}
            value={state.Kodi || ""}
            onChange={(e) => handleChange("Kodi", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            style={{ backgroundColor: "#F4CE14" }}
            label="Kodi"
            id="Kodi_"
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            value={state.Kodi1 || ""}
            onChange={(e) => handleChange("Kodi1", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Kodi 1"
            id="Kodi_1"
          />
        </div>

        <div className="kutizat">
          <TextField
            value={state.Pershkrim || ""}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange("Pershkrim", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Pershkrim"
            style={{ backgroundColor: "#F4CE14" }}
            id="Pershkrim"
          />
          <TextField
            value={state.Fjale_Kyce || ""}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange("Fjale_Kyce", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Fjale Kyce"
            id="Fjale_Kyce"
          />
        </div>

        <div className="kutizat">
          <TextField
            InputLabelProps={{ shrink: true }}
            value={state.Barkod || ""}
            onChange={(e) => handleChange("Barkod", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Barkod"
            id="Bar_Kodi"
            inputProps={{ maxLength: 12 }} // Limit input to 12 characters
          />

          <TextField
            type="number"
            InputLabelProps={{ shrink: true }}
            value={state.Cmimi_Bleres || ""}
            onChange={(e) => handleChange("Cmimi_Bleres", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Cmimi Bleres"
            id="Cmimi_Bleres"
          />
        </div>

        <div className="kutizat">
          <TextField
            type="number"
            value={state.Cmimi || ""}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange("Cmimi", e.target.value)}
            disabled={disabled}
            className="p-inputtext-sm block mb-2"
            label="Cmimi Shites"
            id="Cmimi_Shites"
          />

          <FormControl fullWidth>
            <InputLabel shrink={true} id="njesia-label">
              Njesia
            </InputLabel>
            <Select
              labelId="njesia-label"
              id="njesia-select"
              className="drop_down_list mb-2"
              disabled={disabled}
              size="small"
              value={state.Njesi_Kodi || ""}
              onChange={(e) => {
                handleChange("Njesi_Kodi", e.target.value);
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <MenuItem value="" disabled>
                Njesia
              </MenuItem>
              {njesia?.map((item) => (
                <MenuItem key={item.Kodi} value={item.Kodi}>
                  {item.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl fullWidth>
            <InputLabel shrink={true} id="magazina-label">
              Magazina
            </InputLabel>
            <Select
              labelId="magazina-label"
              id="magazina-select"
              className="drop_down_list"
              disabled={disabled}
              size="small"
              value={state.Magazina_Kodi || ""}
              onChange={(e) => {
                handleChange("Magazina_Kodi", e.target.value);
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <MenuItem value="" disabled>
                Magazina
              </MenuItem>
              {magazina?.map((item) => (
                <MenuItem key={item.Kodi} value={item.Kodi}>
                  {item.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>

        <div className="kutiza-fundit">
          <div className="drop_down_list">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="grupi-label">
                Grupi
              </InputLabel>
              <Select
                labelId="grupi-label"
                id="grupi-select"
                disabled={disabled}
                size="small"
                className="drop_down_list"
                value={state?.Art_Kls01_Kodi}
                onChange={(e) => handleChange("Art_Kls01_Kodi", e.target.value)}
                style={{ backgroundColor: "#fff" }}
              >
                <MenuItem value="" disabled>
                  Grupi
                </MenuItem>
                {grupi?.map((list) => (
                  <MenuItem key={list?.Kodi} value={list?.Kodi}>
                    {list?.Pershkrim}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <span
              style={{
                border: "solid 1px #ddd",
                backgroundColor: "#1971c2",
                color: "#fff",
              }}
            >
              {/* Assuming Grupi is a component that displays numGrupi */}
              <Grupi numGrupi={numGrupi} grupi={grupi} />
            </span>
          </div>
          {/* <div className="flex items-center"> */}
          <FormControl fullWidth>
            <InputLabel shrink={true} id="skema-label">
              Skema
            </InputLabel>
            <Select
              labelId="skema-label"
              id="skema-select"
              className="drop_down_list"
              disabled={disabled}
              style={{ backgroundColor: "#fff" }}
              size="small"
              value={state.Skema_Kodi || ""}
              onChange={(e) => handleChange("Skema_Kodi", e.target.value)}
            >
              <MenuItem value="" disabled>
                Skema
              </MenuItem>
              {skema?.map((list) => (
                <MenuItem key={list.Kodi} value={list.Kodi}>
                  {list.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* </div> */}
          <div className="drop_down_list">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="tarifa-label">
                Tarifa
              </InputLabel>
              <Select
                labelId="tarifa-label"
                id="tarifa-select"
                className="drop_down_list"
                style={{ backgroundColor: "#fff" }}
                disabled={disabled}
                size="small"
                value={state?.Tarifa_Kodi}
                onChange={(e) => handleChange("Tarifa_Kodi", e.target.value)}
              >
                <MenuItem value="" disabled>
                  Tarifa
                </MenuItem>
                {tarifa?.map((list) => (
                  <MenuItem key={list.Kodi} value={list.Kodi}>
                    {list.Pershkrim}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <span
              style={{
                border: "solid 1px #ddd",
                backgroundColor: "#1971c2",
                color: "#fff",
              }}
            >
              {/* Assuming Tarifat is a component that displays numTarifa */}
              <Tarifat numTarifa={numTarifa} tarifa={tarifa} />
            </span>
          </div>
        </div>
      </div>

      <div className={`second-border-pergjithshme border flex gap-3 resizable`}>
        <div className="kodi_box relative">
          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi2 || ""}
              onChange={(e) => handleChange("Kodi2", e.target.value)}
              className="input_kodi"
              id="Kodi_2"
            />
            <label>Kodi 2</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi3 || ""}
              onChange={(e) => handleChange("Kodi3", e.target.value)}
              className="input_kodi"
              id="Kodi_3"
            />
            <label>Kodi 3</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi4 || ""}
              onChange={(e) => handleChange("Kodi4", e.target.value)}
              className="input_kodi"
              id="Kodi_4"
            />
            <label>Kodi 4</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi5 || ""}
              onChange={(e) => handleChange("Kodi5", e.target.value)}
              className="input_kodi"
              id="Kodi_5"
            />
            <label>Kodi 5</label>
          </span>
        </div>

        <div className="checkbox_box border">
          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Aktiv === 1}
              onChange={(e) =>
                handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "10px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Aktiv
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Shumice === 1}
              onChange={(e) =>
                handleChangeAktiv("Shumice", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb4"
              className="p-checkbox-label"
            >
              Shumice
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Pakice === 1}
              onChange={(e) =>
                handleChangeAktiv("Pakice", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb6"
              className="p-checkbox-label"
            >
              Pakice
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Pos_NeList === 1}
              onChange={(e) =>
                handleChangeAktiv("Pos_NeList", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb8"
              className="p-checkbox-label"
            >
              Pos Bar
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Distribucion === 1}
              onChange={(e) =>
                handleChangeAktiv("Distribucion", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb12"
              className="p-checkbox-label"
            >
              Distribucion
            </label>
          </div>

          <div className="flex items-center mb-3">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.AAM === 1}
              onChange={(e) =>
                handleChangeAktiv("AAM", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb12"
              className="p-checkbox-label"
            >
              AAM
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TePergjithshme;
