import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import PrimeGrid from "../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import TabKlienti from "../fature-blerje/tabet/Klienti/TabKlienti";
import TabPaguar from "../fature-blerje/tabet/Paguar/TabPaguar";
import TabMagazina from "../fature-blerje/tabet/Magazina/TabMagazina";
import TabTjera from "../fature-blerje/tabet/Tjera/TabTjera";
import TabTrans from "../fature-blerje/tabet/Transportuesi/TabTrans";
import MainTab from "../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import { useDispatch } from "react-redux";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PrintoPdf1 from "../../components/ReactPDF/PrintoPdf1";
import "../fature-blerje/FatureBlerje.scss";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import Footer from "../../components/Navbar/Footer";
import RightBar from "./RightBarFIS";
import Template from "../dynamicComponent/Template";
import { shtoArtikull, calculateData } from "../dynamicComponent/ArtikullCalc";
import { getNextKodiFsh, getIndexFirstFtSh } from "../../store/globalSlice";

const FatureShitje = ({
  hidePupUp,
  setHidePupUp,
  currentAction,
  setCurrentAction,
  // storeOfflineRequest,
  // isOnline,
  setDataCurrentOffline,
  isMobile,
  disabled,
  setDisabled,
}) => {
  const dispatch = useDispatch();
  const [pdfDisabled, setPdfDisabled] = useState(true);
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [fisVerificationUrl, setFisVerificationUrl] = useState("");
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tipiDTL, setTipiDTL] = useState("");
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [nipt, setNipt] = useState("");
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const toast = useRef(null);
  const [selectedArkaMon, setSelectedArkaMon] = useState("");

  // monedha redux
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const defaultMon = monedhat.map((item) => item.Kodi);
  const defaultMonb = defaultMon[0];

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const handleCloseRightDrawer = () => {
    setRightDrawerOpen(false);
  };

  // Define fetchData outside the useEffect
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);

      setNipt(parsedData.nipt);
    }
  };

  useEffect(() => {
    fetchFShitje(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // redux klienti (get requesti i klientit)
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  // redux klienti (get requesti i klientit)
  const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const klienti = klientiRedux;

  const defaultKursi = monedhat.map((item) => item.Kursi);

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // shteti redux
  const shtetetRedux = useSelector((state) => state.globalSlice.shtetet);
  const shtetet = shtetetRedux.map((item) => item.Kodi);

  // redux menyra (get requesti i Menyres-Pageses)
  const menyrapageses = useSelector((state) => state.globalSlice.menyraPageses);
  const defaultPagesa = menyrapageses.map((item) => item.Id);

  // redux mer indexin e fatures se pare
  const firstFatureIndex = useSelector(
    (state) => state.globalSlice.getFirstFtSh
  );

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = async () => {
    setCurrentAction("add");
    setCurrentId(lastId);
    const response = await dispatch(getNextKodiFsh("/fsh/nextKodi"));

    if (response.payload && response.payload.new_Kodi) {
      setState((prevState) => ({
        ...prevState,
        Kodi: response.payload.new_Kodi,
      }));
    }
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    setCurrentAction("modify");
  };

  const [rows, setRows] = useState([]);

  const [index, setIndex] = useState(0);

  const columnsSF = [
    { field: "Id", title: "Id" },
    { field: "Kodi", title: "Kodi" },
    { field: "Data", title: "Data" },
    { field: "KLFU_Kodi", title: "Klienti" },
    { field: "Vlera", title: "Vlera" },
    { field: "Shenim", title: "Shenim" },
    { field: "Operator", title: "Operator" },
    { field: "NIVF", title: "NIVF" },
    { field: "NSLF", title: "NSLF" },
  ];

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Skonto", title: "Skonto (%)" },
    {
      field: "Skonto_Vlera",
      title: "Skonto_Vlera",
      allowSum: true,
    },
    { field: "Cmimi_Pa_Tvsh", title: "Cmimi_Pa_Tvsh" },
    {
      field: "Vlera_Pa_Tvsh",
      title: "Vlera_Pa_Tvsh",
      allowSum: true,
    },
    { field: "Tvsh", title: "Tvsh" },
    {
      field: "Tvsh_Vlera",
      title: "Tvsh_Vlera",
      allowSum: true,
    },
    { field: "Cmimi", title: "Cmimi" },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Tipi", title: "Tipi" },
    { field: "Total", title: "Total", allowSum: true },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, setTipiDTL, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      setDataCurrentOffline(`${formattedDate}T00:00:00`);
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };

  let defaultState = {
    KLFU_Pershkrim: "",
    KLFU_Kodi: "",
    Data: "",
    Mon: defaultMonb,
    NIPT: "",
    Qyteti_Kodi: "",
    Shenim: "",
    Menyra_Pageses_ID: defaultPagesa[0],
    Arka_Banka_Kodi: "",
    Paguar: "",
    Afati_PagesesData: "",
    Afati_PagesesDite: null,
    Kursi: defaultKursi[0],
    Kodi_Org: "",
    Kodi: "",
    NrSerik: "",
    fisDocumentTypeKodi: "",
    Tip_Cmimi: "",
    Magazina_Kodi: defaultMag[0],
    Veprimi: "",
    Data_Deklarimit: "",
    Dep_Kodi: "",
    Targa_e_Mjetit: "",
    Eksport: "",
    Transportuesi_Nipt: "",
    Transportuesi_Adresa: "",
    Transportuesi_Pershkrim: "",
    Transport_Shenim: "",
    Transport_Data: "",
  };

  const [number, setNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFShitje = async (number) => {
    try {
      showLoading();

      const response = await mainAxios(`/last/faturash/${number}`);
      const responseTotalRows = await dispatch(
        getIndexFirstFtSh("/first/faturash")
      );
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      const responseData = response.data;
      handleChangeValue(response.data.Id);

      if (responseData.NIVF === null) {
        setFatureDisabled(false);
      } else {
        setFatureDisabled(true);
      }
      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(
          `/fature/shitje/dtl/${responseData.Id}`
        );
        const dtlData = dtlResponse.data;

        setState(responseData || "");
        // setTesti({ ...responseData });
        setResponseData(responseData);
        setLastObject(responseData);
        setFisVerificationUrl(responseData.fisVerificationUrl);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);
    if (type === "first") {
      setNumber(firstFatureIndex.total_rows);
      fetchFShitje(firstFatureIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFShitje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFShitje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchFShitje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  // Metoda e fiskalizimit

  const fiskalizo = async () => {
    try {
      const response = await mainAxios(`/fature/shitje/fis/${currentId}`);
      const firstItem = response.data;

      if (response.status === 200) {
        if (response.data?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;

          showToast(faultstring, { severity: "error" });
        }
        if (response.data?.ErrorCode === "0") {
          if (state.fisIsEinvoice === 1) {
            const responseEIC = await mainAxios(
              `/fature/shitje/fiseic/${currentId}`
            );
            if (responseEIC.status === 200) {
              showToast("Fatura elektronike u krijua !", {
                severity: "success",
              });
            }
            setPdfDisabled(false);
          }
          await fetchFShitje(number);

          showToast("Fatura u fiskalizua !", { severity: "success" });
        }
      } else {
        showToast("Nuk u fiskalizua.", { severity: "error" });
      }
    } catch (error) {
      console.error("Error fetching new_kodi_fsh data:", error);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/fature/shitje/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFShitje(numberIndex);
        showToast("Fatura u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e fatures.", { severity: "error" });
      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (!state.KLFU_Kodi) {
      showToast("Cakto Klientin!");
      return;
    }

    if (tipiDTL === "AR") {
      if (!state.Magazina_Kodi) {
        showToast("Cakto Magazinen!", { hideProgressBar: true });
        return;
      }
    }

    if (state.Mon && selectedArkaMon && state.Mon !== selectedArkaMon) {
      showToast("Monedha e Arkes/Bankes e ndryshme nga monedha e fatures!", {
        hideProgressBar: true,
      });
      return;
    }

    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/fature/shitje/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  // Kur Klikohet butoni i "SHTIM"
  const handleAdd = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Pershkrim: row.Pershkrim,
          Vlera: row.Vlera,
          Sasia: row.Sasia,
          Tipi: row.Tipi,
          SkontoAgjent: row.SkontoAgjent,
          Cmimi: row.Cmimi,
          Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
          Operator: row.Operator,
          Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
          Kodi: row.Kodi,
          KLFU_Kodi: row.KLFU_Kodi,
          Tvsh: row.Tvsh,
          DateGarancie: row.DateGarancie,
          Rimbursim_Cmimi: row.Rimbursim_Cmimi,
          NrLL: row.NrLL,
          Shenim2: row.Shenim2,
          IdOld: row.IdOld,
          Skonto_VleraAgjent: row.Skonto_VleraAgjent,
          Art_ID: row.Art_ID,
          Punonjes_Id: row.Punonjes_Id,
          BarKod: row.BarKod,
          Njesi_Kodi: row.Njesi_Kodi,
          Skonto_Vlera: row.Skonto_Vlera,
          Total: row.Total,
          Magazina_Kodi: row.Magazina_Kodi,
          TipVlere_ID: row.TipVlere_ID,
          Tvsh_Vlera: row.Tvsh_Vlera,
          NrSerik: row.NrSerik,
          SasiaPak: row.SasiaPak,
          Cmimi_Kosto: row.Cmimi_Kosto,
          Skonto: row.Skonto,
        };
        return item;
      });
    };
    try {
      showLoading();

      // if (!isOnline) {
      //   storeOfflineRequest({
      //     url: "/fature/shitje",
      //     body: {
      //       ...state,
      //       Data: formatDate(state.Data, true),
      //       Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      //       Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      //       Flete_Dalje_KODI: Number(state.Kodi),
      //       Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      //       Kodi: Number(state.Kodi),
      //       Eksport: Number(state.Eksport),
      //       Afati_PagesesDite: Number(state.Afati_PagesesDite),
      //       Kursi: Number(state.Kursi),
      //       Paguar: Number(state.Paguar),
      //       Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      //       Paguar_ne: String(state.Menyra_Pageses_ID),
      //       Operator: String(username),
      //       fisPaymentMethodTypeID:
      //         state.fisPaymentMethodTypeID === null
      //           ? null
      //           : Number(state.fisPaymentMethodTypeID),
      //       FisInvoiceType: Number(state.FisInvoiceType),
      //       fisIsEinvoice: Number(state.fisIsEinvoice),
      //       fisProcessKodi: state.fisProcessKodi,
      //       fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
      //       fisCorrectiveInvTypeID: Number(state.fisCorrectiveInvTypeID),
      //       fisIsReverseCharge: Number(state.fisIsReverseCharge),
      //       fisTypeOfSelfIssID:
      //         state.fisTypeOfSelfIssID === null
      //           ? null
      //           : Number(state.fisTypeOfSelfIssID),

      //       subsequentDeliveryType:
      //         state.subsequentDeliveryType === null
      //           ? null
      //           : Number(state.subsequentDeliveryType),
      //     },
      //     rows: rows.map((row) => ({
      //       Pershkrim: row.Pershkrim,
      //       Vlera: row.Vlera,
      //       Sasia: row.Sasia,
      //       Tipi: row.Tipi,
      //       SkontoAgjent: row.SkontoAgjent,
      //       Cmimi: row.Cmimi,
      //       Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
      //       Operator: row.Operator,
      //       Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
      //       Kodi: row.Kodi,
      //       KLFU_Kodi: row.KLFU_Kodi,
      //       Tvsh: row.Tvsh,
      //       DateGarancie: row.DateGarancie,
      //       Rimbursim_Cmimi: row.Rimbursim_Cmimi,
      //       NrLL: row.NrLL,
      //       Shenim2: row.Shenim2,
      //       IdOld: row.IdOld,
      //       Skonto_VleraAgjent: row.Skonto_VleraAgjent,
      //       Art_ID: row.Art_ID,
      //       Punonjes_Id: row.Punonjes_Id,
      //       BarKod: row.BarKod,
      //       Njesi_Kodi: row.Njesi_Kodi,
      //       Skonto_Vlera: row.Skonto_Vlera,
      //       Total: row.Total,
      //       Magazina_Kodi: row.Magazina_Kodi,
      //       TipVlere_ID: row.TipVlere_ID,
      //       Tvsh_Vlera: row.Tvsh_Vlera,
      //       NrSerik: row.NrSerik,
      //       SasiaPak: row.SasiaPak,
      //       Cmimi_Kosto: row.Cmimi_Kosto,
      //       Skonto: row.Skonto,
      //     })),
      //   });
      //   // Inform the user that the request will be sent when online
      //   showToast("Request will be sent when online.");
      //   setState(defaultState);
      //   setRows([]);
      //   setDisabled(true);
      //   setButtonClicked(false);
      //   return;
      // }
      //  POST request i 1 per FATURE SHITJE
      const response = await mainAxios.post(`/fature/shitje`, {
        ...state,
        Kodi_Org: state.Kodi_Org,
        Tip_Cmimi: state.Tip_Cmimi,
        Flete_Dalje_KODI: Number(state.Kodi),
        Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
        NrSerik: state.NrSerik,
        Veprimi: state.Veprimi,
        // Klasifikim4_ID: Number(state.Klasifikim4_ID),
        Shenim: state.Shenim,
        Kodi: Number(state.Kodi),
        Eksport: Number(state.Eksport),
        Magazina_Kodi: state.Magazina_Kodi,
        NIPT: state.NIPT,
        Afati_PagesesDite: Number(state.Afati_PagesesDite),
        // Klasifikim1_ID: Number(state.Klasifikim1_ID),
        Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
        Data: formatDate(state.Data, true),
        Mon: state.Mon,
        Kursi: Number(state.Kursi),
        Dep_Kodi: state.Dep_Kodi,
        KLFU_Kodi: state.KLFU_Kodi,
        Paguar: Number(state.Paguar),
        Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
        Paguar_ne: String(state.Menyra_Pageses_ID),
        Operator: String(username),
        // Klasifikim2_ID: Number(state.Klasifikim2_ID),
        Qyteti_Kodi: state.Qyteti_Kodi,
        // Klasifikim3_ID: Number(state.Klasifikim3_ID),
        Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Transportuesi_Nipt: state.Transportuesi_Nipt,
        Targa_e_Mjetit: state.Targa_e_Mjetit,
        Transportuesi_Adresa: state.Transportuesi_Adresa,
        Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
        Transport_Shenim: state.Transport_Shenim,
        Transport_Data: formatDate(state.Transport_Data, true),
        fisDocumentTypeKodi: state.fisDocumentTypeKodi,
        fisPaymentMethodTypeID:
          state.fisPaymentMethodTypeID === null
            ? null
            : Number(state.fisPaymentMethodTypeID),
        FisInvoiceType: Number(state.FisInvoiceType),
        fisIsEinvoice: Number(state.fisIsEinvoice),
        fisProcessKodi: state.fisProcessKodi,
        fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
        fisCorrectiveInvTypeID: Number(state.fisCorrectiveInvTypeID),
        fisIsReverseCharge: Number(state.fisIsReverseCharge),
        fisTypeOfSelfIssID:
          state.fisTypeOfSelfIssID === null
            ? null
            : Number(state.fisTypeOfSelfIssID),

        subsequentDeliveryType:
          state.subsequentDeliveryType === null
            ? null
            : Number(state.subsequentDeliveryType),
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        setSearchString("");

        const newEntryID = response.data.Id;

        showToast("Fatura u rregjistrua.", { severity: "success" });

        // POST request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        const responseDtl = await mainAxios.post(
          `/fature/shitje/dtl/bulk`,
          dtlDataArray
        );

        if (responseDtl.status === 200 && tipiDTL === "AR") {
          // POST request i 3 per gjenerimin e Flete Daljes
          const responseFD = await mainAxios.post(
            `/fature/shitje/f_insert/${newEntryID}`,
            {}
          );
          if (responseFD.status === 200) {
            fetchFShitje(1);
            setCurrentAction(null);
            showToast("Flete Dalja u gjenerua.", { severity: "success" });
          } else {
            showToast("Nuk u gjenerua flete dalje.", { severity: "error" });
          }
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gabim gjatë regjistrimit të artikujve",
        });
      }
    } catch (error) {
      console.error("Error in POST request:", error);

      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë regjistrimit të faturës.",
      });
    } finally {
      hideLoading();
    }
  };
  const hasArObject = rows.some((row) => row.Tipi === "AR");

  // Kur Klikohet butoni i "MODIFIKO"
  const handleModify = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Id: row.Id,
          Pershkrim: row.Pershkrim,
          Vlera: row.Vlera,
          Sasia: row.Sasia,
          Tipi: row.Tipi,
          SkontoAgjent: row.SkontoAgjent,
          Cmimi: row.Cmimi,
          Operator: row.Operator,
          Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
          Kodi: row.Kodi,
          KLFU_Kodi: row.KLFU_Kodi,
          Tvsh: row.Tvsh,
          DateGarancie: row.DateGarancie,
          Rimbursim_Cmimi: row.Rimbursim_Cmimi,
          NrLL: row.NrLL,
          Shenim2: row.Shenim2,
          IdOld: row.IdOld,
          Skonto_VleraAgjent: row.Skonto_VleraAgjent,
          Art_ID: row.Art_ID,
          Punonjes_Id: row.Punonjes_Id,
          BarKod: row.BarKod,
          Njesi_Kodi: row.Njesi_Kodi,
          Skonto_Vlera: row.Skonto_Vlera,
          Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
          Total: row.Total,
          Magazina_Kodi: row.Magazina_Kodi,
          TipVlere_ID: row.TipVlere_ID,
          Tvsh_Vlera: row.Tvsh_Vlera,
          NrSerik: row.NrSerik,
          SasiaPak: row.SasiaPak,
          Cmimi_Kosto: row.Cmimi_Kosto,
          Skonto: row.Skonto,
        };
        return item;
      });
    };
    try {
      showLoading();
      // Requesti i 1 PUT per Faturen e shitjes
      const response = await mainAxios.put(`/fature/shitje/${currentId}`, {
        // ...state,
        Kodi_Org: state.Kodi_Org,
        Tip_Cmimi: state.Tip_Cmimi,
        Flete_Dalje_KODI: Number(state.Kodi),
        Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
        NrSerik: state.NrSerik,
        Veprimi: state.Veprimi,
        // Klasifikim4_ID: Number(state.Klasifikim4_ID),
        Shenim: state.Shenim,
        Kodi: Number(state.Kodi),
        Eksport: Number(state.Eksport),
        Magazina_Kodi: state.Magazina_Kodi,
        NIPT: state.NIPT,
        Afati_PagesesDite: Number(state.Afati_PagesesDite),
        // Klasifikim1_ID: Number(state.Klasifikim1_ID),
        Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
        Data: formatDate(state.Data, true),
        Mon: state.Mon,
        Kursi: Number(state.Kursi),
        Dep_Kodi: state.Dep_Kodi,
        KLFU_Kodi: state.KLFU_Kodi,
        Paguar: Number(state.Paguar),
        Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
        Paguar_ne: String(state.Menyra_Pageses_ID),
        Operator: String(username),
        // Klasifikim2_ID: Number(state.Klasifikim2_ID),
        Qyteti_Kodi: state.Qyteti_Kodi,
        // Klasifikim3_ID: Number(state.Klasifikim3_ID),
        Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Transportuesi_Nipt: state.Transportuesi_Nipt,
        Targa_e_Mjetit: state.Targa_e_Mjetit,
        Transportuesi_Adresa: state.Transportuesi_Adresa,
        Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
        Transport_Shenim: state.Transport_Shenim,
        Transport_Data: formatDate(state.Transport_Data, true),
        fisDocumentTypeKodi: state.fisDocumentTypeKodi,
        fisPaymentMethodTypeID:
          state.fisPaymentMethodTypeID === null
            ? null
            : Number(state.fisPaymentMethodTypeID),
        FisInvoiceType: Number(state.FisInvoiceType),
        fisIsEinvoice: Number(state.fisIsEinvoice),
        fisProcessKodi: state.fisProcessKodi,
        fisTypeOfSelfIssID:
          state.fisTypeOfSelfIssID === null
            ? null
            : Number(state.fisTypeOfSelfIssID),
        fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
        fisCorrectiveInvTypeID: Number(state.fisCorrectiveInvTypeID),
        subsequentDeliveryType:
          state.subsequentDeliveryType === null
            ? null
            : Number(state.subsequentDeliveryType),
        fisIsReverseCharge: Number(state.fisIsReverseCharge),
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        setSearchString("");

        const newEntryID = response.data.Id;

        showToast("Fatura u modifikua.", {
          severity: "success",
        });

        //  PUT request i 2 per modifikimin e DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        const responseDtl = await mainAxios.put(
          `/fature/shitje/dtl/bulk`,
          dtlDataArray
        );

        if (responseDtl.status === 200 && hasArObject) {
          // PUT request i 3 per gjenerimin e Flete Daljes
          const responseFD = await mainAxios.post(
            `/fature/shitje/f_insert/${newEntryID}`,
            {}
          );
          if (responseFD.status === 200) {
            showToast("Flete Dalja e re, u gjenerua.", {
              severity: "success",
            });
            setCurrentAction(null);
            fetchFShitje(number);
          } else {
            showToast("Nuk u gjenerua flete dalje.", {
              severity: "error",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error in POST request:", error);
      showToast("Gabim gjatë modifikimit të faturës.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  const updateDepKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Dep_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  //Ploteson fushat e tjera nqs Zgjedh nje X-klient
  //Dmth nqs zgjedh F003 => NIPT (L61918004H)
  //F003 => klient (Ahmetaj )
  const handleKlientiType = async (e, value) => {
    setSearchString(value ? value.Kodi : "");
    if (value && value.Kodi) {
      try {
        const selectedKlienti = klientiRedux.find(
          (item) => item.Kodi === value.Kodi
        );

        if (selectedKlienti) {
          setState((state) => ({
            ...state,
            KLFU_Kodi: selectedKlienti.Kodi,
            KLFU_Pershkrim: selectedKlienti.Pershkrim,
            NIPT: selectedKlienti.Nipt,
            Qyteti_Kodi: selectedKlienti.Qyteti_Kodi,
          }));
        } else {
          // If selectedKlienti is not found, clear the text fields
          setState((state) => ({
            ...state,
            KLFU_Kodi: value,
            KLFU_Pershkrim: "",
            NIPT: "",
            Qyteti_Kodi: "",
          }));
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      // If value is empty or value.Kodi is falsy, clear all fields
      setState((state) => ({
        ...state,
        KLFU_Kodi: "",
        KLFU_Pershkrim: "",
        NIPT: "",
        Qyteti_Kodi: "",
      }));
    }
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;
    console.log(value, "value", kursi, "kursi");
    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  useEffect(() => {
    // Update the Total for each row in the rows array
    const updatedRows = rows.map((row) => {
      return {
        ...row,
        Total: row.Vlera * state.Kursi,
      };
    });

    // Update the rows state with the updated rows
    setRows(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Kursi]);

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
    setSelectedArkaMon("");
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      popupContextKey="FatureShitje"
      contexDelete={"faturen"}
      onAddClick={onAddClick}
      fatureDisabled={fatureDisabled}
      onModifyClick={onModifyClick}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFShitje={fetchFShitje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      number={number}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile ? "" : "Klient";
  const button7Text = isMobile ? "" : "Paguar";
  const button8Text = isMobile ? "" : "Mag";
  const button9Text = isMobile ? "" : "Tjera";
  const button11Text = isMobile ? "" : "Transp";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      includeButton7={true}
      includeButton8={true}
      includeButton9={true}
      includeButton11={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<PeopleIcon />}
      button7Text={button7Text}
      button7Icon={<AttachMoneyIcon />}
      button8Text={button8Text}
      button8Icon={<WarehouseIcon />}
      button9Text={button9Text}
      button9Icon={<AutoAwesomeMotionIcon />}
      button11Text={button11Text}
      button11Icon={<DirectionsBusIcon />}
      isMobile={isMobile}
    />
  );

  const TabiKryesor = (
    <MainTab
      formatDate={formatDate}
      includeNrOrigjinesField={false}
      includeDateField={true}
      includeKursiField={true}
      includeMonedhaSelect={true}
      includeKodiField={true}
      includeSerialNumerField={true}
      includeModalList={true}
      disabled={disabled}
      state={state}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
      fatureShitje={true}
    />
  );

  const BtnPrint = (
    <PrintoPdf1
      className="print"
      state={state}
      showToast={showToast}
      konfigurimeRedux={konfigurimeRedux}
      fromFShitje={true}
      rowsData={rows}
      fisVerificationUrl={fisVerificationUrl}
      menyrapageses={menyrapageses}
      nipt={nipt}
      username={username}
    />
  );
  const BtnFiskal = (
    <Button
      size="small"
      variant="contained"
      style={{
        ...(isMobile
          ? {
              height: "2.3rem",
              width: "4rem",
            }
          : {}),
        display: "flex",
      }}
      disabled={buttonClicked || fatureDisabled}
      onClick={fiskalizo}
    >
      {isMobile || "Fiskalizo"}
      <PointOfSaleIcon fontSize="small" />
    </Button>
  );
  const smallTabsKM = (
    <div>
      {/* tab1 */}
      <TabKlienti
        qytetKlienti={true}
        nipt={true}
        shenim={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
        setSearchString={setSearchString}
        searchString={searchString}
        handleKlientiType={handleKlientiType}
        qytetet={qytetet}
        shtetet={shtetet}
        klienti={klienti}
      />
      {/* //// */}

      {/* tab2 */}
      <TabPaguar
        setSelectedArkaMon={setSelectedArkaMon}
        setState={setState}
        popupContextKey="FatureShitje"
        formatDate={formatDate}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
        rows={rows}
        menyrapageses={menyrapageses}
        // pergjegjes={pergjegjes}
      />
      {/* /////*/}

      {/* tab3 */}
      <TabMagazina
        fleteDaljeBtn={true}
        disabled={disabled}
        formatDate={formatDate}
        toggleState={toggleState === 3}
        state={state}
        qytetet={qytetet}
        handleChange={handleChange}
      />
      {/*///// */}

      {/* tab4 */}
      <TabTjera
        formatDate={formatDate}
        toggleState={toggleState === 4}
        disabled={disabled}
        state={state}
        updateDepKodi={updateDepKodi}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        fetchPost={fetchFShitje}
      />
      {/* //// */}

      {/* tab6 */}
      <TabTrans
        formatDate={formatDate}
        toggleState={toggleState === 6}
        disabled={disabled}
        includeShenim={true}
        state={state}
        setState={setState}
        handleChange={handleChange}
        fetchTShitje={fetchFShitje}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh">
      <NavBar currentAction={currentAction} />
      <div className={isMobile ? null : "template"}>
        <div className="main-container p-2">
          <div className="top_panel_fsh">
            <Panel
              headerTemplate={
                <Template
                  options={{
                    collapsed: isPanelToggleable,
                    onTogglerClick: () =>
                      setIsPanelToggleable(!isPanelToggleable),
                  }}
                  isMobile={isMobile}
                  fatureName="Fature Shitje"
                  currentId={currentId}
                  nxtBtnDisable={nxtBtnDisable}
                  prvBtnDisable={prvBtnDisable}
                  shikoFaturen={shikoFaturen}
                  handleTestiChange={handleTestiChange}
                  columnsSF={columnsSF}
                  contextKey={"fature/shitje"}
                  visible={visible}
                  setVisible={setVisible}
                  gridKey="FatureShitje"
                  fatureshitje={true}
                />
              }
              toggleable
              collapsed={isPanelToggleable}
              onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
              style={{ height: "100%" }}
            >
              <Toast ref={toast} />
              <div className="bg-white" style={{ height: "100%" }}>
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve">
                      <div className="paneli-tabeve1">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2">{PaneliDytesor}</div>

                      <div className="paneli-fis-print">
                        {BtnPrint}

                        {BtnFiskal}
                      </div>
                    </div>
                    <div className={"below-tabs"}>
                      <div className="below-tabs1">{TabiKryesor}</div>
                      <div className="below-tabs2">{smallTabsKM}</div>
                      <div className="below-tabs3"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                      <div style={{ display: "flex" }}>{BtnFiskal}</div>
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                      {BtnPrint}
                    </div>
                    <div
                      style={{
                        margin: 10,
                      }}
                    >
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>

          <div className={!isMobile ? "middle_fsh" : null}>
            <RightBar
              showToast={showToast}
              currentAction={currentAction}
              isFsh="true"
              contextKeyFatureShitje={true}
              openRight={rightDrawerOpen}
              onCloseRight={handleCloseRightDrawer}
              state={state}
              setState={setState}
              setRows={setRows}
              disabled={disabled}
              setDisabled={setDisabled}
              currentId={currentId}
              pdfDisabled={pdfDisabled}
              handleChange={handleChange}
              fisVerificationUrl={fisVerificationUrl}
              fetchFShitje={fetchFShitje}
              buttonClicked={buttonClicked}
              fatureDisabled={fatureDisabled}
              prvBtnDisable={prvBtnDisable}
              nxtBtnDisable={nxtBtnDisable}
            />
          </div>

          <div className="dataTable_fsh">
            <PrimeGrid
              deletedRowIds={deletedRowIds}
              setDeletedRowIds={setDeletedRowIds}
              gridKey="FatureShitje"
              contextKey={"/fature/shitje/dtl"}
              isEditable={true}
              columns={columns}
              setColumns={setColumns}
              disabled={disabled}
              defaultColumnsStorage={defaultColumnsStorage}
              rows={rows}
              setRows={setRows}
              calculateData={handleCalculateData}
              shtoArtikull={handleShtoArtikull}
              currentAction={currentAction}
              currentId={currentId}
            />
          </div>
        </div>
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(FatureShitje);
