import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import "../MainComponent/MainComponent.scss";
import { Toast } from "primereact/toast";
import HandleItemClick from "./HandleItemClick";
import HandleInputChange from "./HandleInputChange";
import HandleInputCmimiChange from "./HandleInputCmimiChange";

const LeftBar = ({
  clickedItems,
  setClickedItems,
  showLoading,
  shikoTotalin,
  hideLoading,
  totaliFatura,
  setArtikujtPrint,
  aExistTable,
}) => {
  const toast = useRef(null);
  const [filteredItems2, setFilteredItems2] = useState([]);

  // Toast Notification function
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  // Update filteredItems2 whenever clickedItems changes
  useEffect(() => {
    const filteredItems = clickedItems.filter((item) => item.Printuar !== 1);
    setFilteredItems2(filteredItems);
  }, [clickedItems]);

  // Update setArtikujtPrint whenever filteredItems2 changes
  useEffect(() => {
    setArtikujtPrint(filteredItems2);
  }, [filteredItems2, setArtikujtPrint]);

  return (
    <>
      <div className="squares-container">
        {totaliFatura
          ? clickedItems.map((item, index) => (
              <div className="square1" key={index}>
                <Toast ref={toast} position="top-center" />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <div
                    className="pershkrimArt_leftbar"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.overflow = "visible")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.overflow = "hidden")
                    }
                  >
                    {item.Pershkrim}
                  </div>

                  <div className="cmimi_leftbar">
                    <div>
                      {Number.isInteger(item.Sasia)
                        ? item.Sasia
                        : item.Sasia.toFixed(2)}
                    </div>
                  </div>

                  <div className="shumezim_leftbar">
                    <ClearIcon />
                  </div>

                  <div className="cmimi_leftbar">
                    <div>
                      {Number.isInteger(item.Cmimi)
                        ? item.Cmimi
                        : item.Cmimi.toFixed(2)}
                    </div>
                  </div>
                  {item.Printuar === 1 && (
                    <div className="mr-5">
                      <span>Printuar</span>
                    </div>
                  )}
                  {item.Printuar !== 1 && (
                    <div className="mr-5">
                      <span>Aktiv</span>
                    </div>
                  )}
                </Grid>
              </div>
            ))
          : filteredItems2.map((item, index) => (
              <div className="square1" key={index}>
                <Toast ref={toast} position="top-center" />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <div
                    className="pershkrimArt_leftbar"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.overflow = "visible")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.overflow = "hidden")
                    }
                  >
                    {item.Pershkrim}
                  </div>

                  <HandleInputChange
                    item={item}
                    showLoading={showLoading}
                    clickedItems={clickedItems}
                    hideLoading={hideLoading}
                    setClickedItems={setClickedItems}
                    index={index}
                  />

                  <div className="shumezim_leftbar">
                    <ClearIcon />
                  </div>

                  <HandleInputCmimiChange
                    item={item}
                    showLoading={showLoading}
                    clickedItems={clickedItems}
                    hideLoading={hideLoading}
                    setClickedItems={setClickedItems}
                    index={index}
                  />

                  <HandleItemClick
                    item={item}
                    showLoading={showLoading}
                    hideLoading={hideLoading}
                    showToast={showToast}
                    setClickedItems={setClickedItems}
                    clickedItems={clickedItems}
                  />
                </Grid>
              </div>
            ))}
      </div>
      {aExistTable && (
        <Button className="button_restaurant_Pos" onClick={shikoTotalin}>
          {totaliFatura ? (
            <span>Shiko Aktualen</span>
          ) : (
            <span>Shiko totalin</span>
          )}
        </Button>
      )}
    </>
  );
};

export default LeftBar;
