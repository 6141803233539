import React from "react";
import "./printFdShoqeruese.scss";
import TotalFooterFD from "./TotalFooter";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "";
};

const DynamicTableFdShoqeruese = ({ rows, state }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={info.Id}>
        <td className="solid-border">{rowNumber}</td>
        <td className="left-align">{info.Pershkrim}</td>
        <td>{info.Njesi_Kodi}</td>
        <td>{info.Sasia}</td>
        <td>{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  const sums = {
    Sasia: formatNumber(rows.reduce((total, info) => total + info.Sasia, 0)),
  };
  return (
    <table className="HeaderTable border">
      <table className="table table-striped bordered table-body">
        <thead className="thead thead-striped pt-2">
          {/* Kolona 1 */}
          <tr>
            <th>Nr</th>
            <th>Pershkrimi i mallit</th>
            <th>Njesia e matjes</th>
            <th>Sasia</th>
            <th>Vlera</th>
          </tr>
        </thead>

        <tfoot>
          <tr>
            <td className="footer-gjeneruar" colSpan={8}>
              Fiskalizuar nga Parid Smart Solution
            </td>
          </tr>
        </tfoot>
        <tbody className="tbody-print">{DisplayData}</tbody>
      </table>
      <br />
      <tr className="flex">
        <h5>
          LLoji i amballazhit/
          ........................................................................................
          Nr Kolive(arkave, thase, etj) ......{sums.Sasia}
          .......................................
        </h5>
      </tr>{" "}
      <br />
      <tr>
        <h6>Kur transporti behet me te trete :</h6>
        <h5>
          Emri i shoqerise transportuese/personit fizik:
          /..................................................................
          Nipt:/...............................................................
        </h5>
      </tr>
      <tr>
        <h5>
          Adresa:/
          .....................................................................................................................................................................................................
        </h5>
      </tr>
      <div className="footer-bleresi ">
        <TotalFooterFD state={state} className="m-2" FdShoqeruese={true} />
      </div>
    </table>
  );
};

export default DynamicTableFdShoqeruese;
