import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKonfigurime } from "../../store/globalSlice";

// Create context
const ApiContext = createContext();

// Context provider component
export const ApiProvider = ({ children }) => {
  const dispatch = useDispatch();

  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  useEffect(() => {
    dispatch(getKonfigurime("/konfigurim"));
  }, [dispatch]);

  return (
    <ApiContext.Provider value={{ konfigurimeRedux }}>
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook to use ApiContext
export const useApi = () => useContext(ApiContext);
