import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context
const MyContext = createContext();

// Create a provider for the context
export const MyProvider = ({ children }) => {
  const [operator, setOperator] = useState("");
  const [nipt, setNipt] = useState("");
  const [state, setState] = useState({
    Aktiv: 1,
    E_Paguar: 0,
    Kodi: 1,
    Menyra_Pageses_Id: 6,
    Pike_Fitim: 0,
    Tip_Cmimi: "CMIMI",
    Operator: `${operator}`,
    Tipi: "FTSH",
    KLFU_Kodi: "",
    KLFU_Pershkrim: "",
  });

  // Define fetchData function
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setOperator(parsedData.username);
      setNipt(parsedData.nipt);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update the state when operator changes
    setState((prevState) => ({
      ...prevState,
      Operator: operator,
    }));
  }, [operator]);

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <MyContext.Provider value={{ state, setState, updateState }}>
      {children}
    </MyContext.Provider>
  );
};

// Custom hook to use the context
export const useMyContext = () => useContext(MyContext);
