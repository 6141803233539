import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Ripple } from "primereact/ripple";
import SearchFature from "../../components/ShikoFaturat/SeachFaturat";
import "./Template.scss";

const Template = ({
  isMobile,
  fatureName,
  currentId,
  nxtBtnDisable,
  prvBtnDisable,
  shikoFaturen,
  handleTestiChange,
  columnsSF,
  visible,
  setVisible,
  gridKey,
  contextKey,
  options,
  fatureshitje,
}) => {
  const titleClassName = "tittle_header";
  const toggleIcon = options.collapsed
    ? "pi pi-chevron-down"
    : "pi pi-chevron-up";

  // Define styles based on isMobile
  const buttonGroupStyle = isMobile
    ? { width: "50%", height: "40px" }
    : { width: "200px", height: "100%" };

  const buttonStyle = isMobile
    ? { fontSize: "0.75rem", padding: "4px 8px", margin: "2px" }
    : { fontSize: "1rem", padding: "8px 16px", margin: "2px" };

  return (
    <div className="header_container">
      {/* <Button
        className={options.togglerClassName}
        onClick={options.onTogglerClick}
      >
        <span className={toggleIcon}></span>
        <Ripple />
      </Button> */}

      <span className={titleClassName}>
        {fatureName} ID: {currentId ? currentId : "No Data"}
      </span>
      <div className="flex">
        <SearchFature
          isMobile={isMobile}
          gridKey={gridKey}
          onTestiChange={handleTestiChange}
          columns={columnsSF}
          contextKey={contextKey}
          visible={visible}
          setVisible={setVisible}
          fatureshitje={fatureshitje}
        />
        <div className="buttons_right_header">
          <ButtonGroup
            variant="contained"
            aria-label="text button group"
            style={buttonGroupStyle}
          >
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: nxtBtnDisable ? "#D7D9DA" : "#1564ad",
              }}
              disabled={nxtBtnDisable}
              onClick={() => shikoFaturen("first")}
            >
              <FirstPageIcon />
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: nxtBtnDisable ? "#D7D9DA" : "#1564ad",
              }}
              disabled={nxtBtnDisable}
              onClick={() => shikoFaturen("increment")}
            >
              <ArrowCircleLeftOutlinedIcon />
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: prvBtnDisable ? "#D7D9DA" : "#1564ad",
              }}
              onClick={() => shikoFaturen("decrement")}
              disabled={prvBtnDisable}
            >
              <ArrowCircleRightOutlinedIcon />
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: prvBtnDisable ? "#D7D9DA" : "#1564ad",
              }}
              disabled={prvBtnDisable}
              onClick={() => shikoFaturen("last")}
            >
              <LastPageIcon />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default Template;
