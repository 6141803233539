import React from "react";
import NewDesign from "./NewDesign";
import "./Supermarket.scss";

const Supermarket = ({ userAutomatikisht, setUserAutomatikisht }) => {
  return (
    <div className="div_supermarket">
      <NewDesign
        open={true}
        userAutomatikisht={userAutomatikisht}
        setUserAutomatikisht={setUserAutomatikisht}
      />
    </div>
  );
};

export default Supermarket;
