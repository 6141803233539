import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MainComponent from "./MainComponent/MainComponent";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useUser } from "../../../../zustand/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  defaultState,
  handleClose,
  mbylleTav,
  numTav,
  aExistTable,
  klienti,
  newId,
  state,
  setState,
  formatDate,
  dtlState,
  setDtlNum,
  setError,
  timeOnly,
  showToast,
  fetchPos,
  isMobile,
  error,
  art,
  noDataMessage,
  setNoDataMessage,
  kls01,
  loadingLeft,
  arka,
  menyra,
  setOpen,
  clickedItems,
  setClickedItems,
  setTotalOfflineCmimi,
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { user } = useUser();
  const operator = user.username;

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#ececec",
            boxShadow: "none",
            color: "#000",
            height: "6%",
          }}
        >
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "row",
                color: "#000",
                fontWeight: "600",
              }}
              component="div"
            >
              <div className="tavolina_Toolbar">Operator: {operator}</div>
              <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div className="tavolina_Toolbar">Tavolina: {numTav}</div>
              <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div
                className="ora_Toolbar"
                style={{
                  marginLeft: "5px",
                  color: "#454545",
                  backgroundColor: "#dbdbdbaf",
                  borderRadius: "10%",
                }}
              >
                Ora: {timeOnly}
              </div>
            </Typography>
            <Typography
              sx={{ ml: 2, mr: 2, flex: 2, color: "#000" }}
              component="div"
            ></Typography>
            {isMobile ? (
              ""
            ) : (
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
                sx={{ ml: 2 }}
              >
                {!isFullscreen ? (
                  <OpenInFullIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                ) : (
                  <CloseFullscreenIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                      marginRight: "5px",
                    }}
                  />
                )}
              </IconButton>
            )}
            <IconButton
              edge={false}
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                width: 30,
                height: 30,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MainComponent
          newId={newId}
          aExistTable={aExistTable}
          isMobile={isMobile}
          state={state}
          setState={setState}
          numTav={numTav}
          dtlState={dtlState}
          setDtlNum={setDtlNum}
          handleClose={handleClose}
          mbylleTav={mbylleTav}
          formatDate={formatDate}
          klienti={klienti}
          showToast={showToast}
          fetchPos={fetchPos}
          setError={setError}
          error={error}
          defaultState={defaultState}
          art={art}
          noDataMessage={noDataMessage}
          setNoDataMessage={setNoDataMessage}
          kls01={kls01}
          loadingLeft={loadingLeft}
          arka={arka}
          menyra={menyra}
          setOpen={setOpen}
          clickedItems={clickedItems}
          setTotalOfflineCmimi={setTotalOfflineCmimi}
          setClickedItems={setClickedItems}
        />
      </Dialog>
    </React.Fragment>
  );
}
