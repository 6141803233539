// DateUtils.js
export const formatDate = (dateString, addTime = false) => {
  let date;
  if (dateString instanceof Date) {
    date = dateString;
  } else if (typeof dateString === "string") {
    date = new Date(dateString);
    if (isNaN(date.getTime())) {
      date = new Date();
    }
  } else {
    date = new Date();
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;

  if (addTime) {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    formattedDate += `T${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
};
