import React from "react";
import { Dialog, Button } from "@mui/material";
import "./ConfirmationDialog.scss";

const ConfirmationDialog = ({
  fleteName,
  fatureName,
  showConfirmation,
  handleConfirmation,
  onClose,
}) => {
  return (
    <Dialog
      open={showConfirmation}
      onClose={onClose}
      PaperProps={{ style: { padding: "20px" } }}
    >
      <h2>
        Kjo {fleteName} eshte gjeneruar nga {fatureName}
      </h2>
      <h2>Deshironi te vazhdoni?</h2>

      <div className="button_modifikim_confirm">
        <Button variant="contained" onClick={() => handleConfirmation(true)}>
          Po
        </Button>
        <Button variant="contained" onClick={() => handleConfirmation(false)}>
          Jo
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
