import "../print.scss";

function SimpleTableFH({ state }) {
  return (
    <table className="HeaderTable border">
      <thead>
        <tr>
          <td className="title1" colSpan={8}>
            <b>Flete HYRJE</b>
            <br />
            <b>NR: {state.Kodi}</b>
          </td>
        </tr>
      </thead>
      <th colSpan="11">
        <tr className="table table-striped bordered border">
          {/* Tabela 1 */}
          <tr className="th-square1">
            {/* Data Ora */}
            <td>
              <tr className="solid-border">
                Data : {state.Data.substring(0, 10)}
              </tr>
            </td>
          </tr>

          {/* Bleresi */}
          <td>
            <br />
            <tr>
              <tr className="solid-border"> NR.i Dokumentit: {state.Kodi}</tr>
            </tr>
          </td>
        </tr>
      </th>
    </table>
  );
}

export default SimpleTableFH;
