import React from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";

const MainTabCArka = ({
  disabled,
  state,
  handleChange,
  setState,
  TextTCRCode,
}) => {
  // redux llogari (get requesti i llogaris)
  const furnitorRedux = useSelector((state) => state.globalSlice.llogari);
  const llogari = furnitorRedux;

  // redux monedha (get requesti i monedhes)
  const monedhaRedux = useSelector((state) => state.globalSlice.monedha);
  const monedha = monedhaRedux;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? 1 : 0) : value,
      };
    });
  };

  return (
    // <div className={toggleState === 1 ? "content  active-content" : "content"}>

    <div className="grid gap-2 grid-cols-2 ">
      <TextField
        disabled={disabled}
        onChange={(e) => handleChange("Kodi", e.target.value)}
        label="Kodi"
        variant="outlined"
        size="small"
        style={{ width: "100%" }}
        value={state?.Kodi || ""}
      />

      <TextField
        disabled={disabled}
        onChange={(e) => handleChange("Pershkrim", e.target.value)}
        label="Pershkrim"
        variant="outlined"
        size="small"
        style={{ width: "100%" }}
        value={state?.Pershkrim || ""}
      />
      {TextTCRCode ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("TCRCode", e.target.value)}
          label="TCRCode"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.TCRCode || ""}
        />
      ) : null}

      <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
        <Autocomplete
          disablePortal
          disabled={disabled}
          id="combo-box-llogari"
          options={llogari}
          filterOptions={filterOptions}
          getOptionLabel={(option) =>
            option ? option.Kodi + "-" + option.Pershkrim : ""
          }
          onChange={(e, newValue) =>
            handleChange("NrLL", newValue ? newValue.Kodi : "")
          }
          value={llogari.find((qytet) => qytet.Kodi === state?.NrLL) || ""}
          size="small"
          style={{ width: "100%", backgroundColor: "#fff" }}
          renderInput={(params) => <TextField {...params} label="Llogari" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Kodi}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
            </div>
          )}
        />
      </div>
      <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
        <Autocomplete
          disablePortal
          disabled={disabled}
          id="combo-box-monedha2"
          options={monedha}
          filterOptions={filterOptions}
          getOptionLabel={(option) => (option ? option.Kodi : "")}
          onChange={(e, newValue) =>
            handleChange("Mon", newValue ? newValue.Kodi : "")
          }
          value={monedha.find((qytet) => qytet.Kodi === state?.Mon) || null}
          size="small"
          style={{ width: "100%", backgroundColor: "#fff" }}
          renderInput={(params) => <TextField {...params} label="Monedha" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Kodi}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
            </div>
          )}
        />
      </div>
      <div className="flex items-center">
        <Checkbox
          disabled={disabled}
          checked={state?.Aktiv === 1}
          onChange={(e) => handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0)}
        ></Checkbox>
        <label
          style={{ color: "#1971c2", marginLeft: "5px" }}
          htmlFor="cb2"
          className="p-checkbox-label"
        >
          Aktiv
        </label>
      </div>
    </div>
  );
};

export default MainTabCArka;
