// ConfirmationDialog.js
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import "./ConfirmationDialog.scss";

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Confirmation</DialogTitle>

      <DialogContent>Deshironi te largoheni ?</DialogContent>

      <div className="buttons_confirmation">
        <Button
          onClick={() => onConfirm(true)}
          color="primary"
          variant="contained"
        >
          PO
        </Button>
        <Button
          onClick={() => onClose(false)}
          color="primary"
          variant="contained"
        >
          JO
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
