import React from "react";
import { Button } from "@mui/material";
import SyncLockIcon from "@mui/icons-material/SyncLock";

const ButtonMbyllXhiro = ({
  setDialogXhiro,
  mainData,
  ngaData,
  deriData,
  operator,
  dataPosBak,
}) => {
  const functionDialogOpenXhiro = () => {
    setDialogXhiro(true);
  };
  return (
    <Button
      disabled={
        dataPosBak.length === 0 ||
        !mainData ||
        !ngaData ||
        !deriData ||
        !operator
      }
      variant="contained"
      onClick={functionDialogOpenXhiro}
      startIcon={
        <SyncLockIcon style={{ fontSize: "25px", marginRight: "10px" }} />
      }
      style={{
        justifyContent: "space-between",
        margin: "5px",
      }}
    >
      Mbyll Xhiron
    </Button>
  );
};

export default ButtonMbyllXhiro;
