import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { Toast } from "primereact/toast";
import useStorage from "../../../../../hooks/useStorage";
import { Tooltip } from "primereact/tooltip";
import mainAxios from "../../../../../services/axios";
import "./PrimeGridSuperRestaurant.scss";

const PrimeGridSuperRestaurant = ({
  disabled,
  columns,
  setColumns,
  contextKey,
  shtoArtikull,
  currentAction,
  currentId,
  rows,
  setRows,
  isEditable = true,
  showHideColumns = true,
  gridKey,
  calculateData,
  Total,
  defaultColumnsStorage,
  ...props
}) => {
  const toast = useRef(null);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );

  const onCellEditComplete = async (e) => {
    let { rowData, newValue, rowIndex, field } = e;
    if (field === "Total") return;
    let cellData = null;
    if (typeof rowData[field] === "number") {
      cellData = parseFloat(newValue);
    } else {
      cellData = newValue;
    }

    const newRowData = calculateData({ [field]: cellData }, { ...rowData });
    let allRows = [...rows];
    allRows[rowIndex] = newRowData;
    setRows(allRows);

    try {
      const itemForSecondRequest = {
        Master_Id: newRowData.Master_Id,
        Pershkrim: newRowData.Pershkrim,
        Vlera: parseFloat(newRowData.Vlera).toFixed(2),
        Sasia: newRowData.Sasia,
        Tipi: newRowData.Tipi,
        SkontoAgjent: newRowData.SkontoAgjent,
        Cmimi: newRowData.Cmimi,
        Operator: newRowData.Operator,
        Vlera_Pa_Tvsh: newRowData.Vlera_Pa_Tvsh,
        Kodi: newRowData.Kodi,
        Tvsh: newRowData.Tvsh,
        DateGarancie: newRowData.DateGarancie,
        NrLL: newRowData.NrLL,
        Shenim2: newRowData.Shenim2,
        IdOld: newRowData.IdOld,
        Skonto_VleraAgjent: newRowData.Skonto_VleraAgjent,
        Art_ID: newRowData.Art_ID,
        Punonjes_Id: newRowData.Punonjes_Id,
        BarKod: newRowData.BarKod,
        Njesi_Kodi: newRowData.Njesi_Kodi,
        Skonto_Vlera: newRowData.Skonto_Vlera,
        Total: newRowData.Total,
        Magazina_Kodi: newRowData.Magazina_Kodi,
        TipVlere_ID: newRowData.TipVlere_ID,
        Tvsh_Vlera: newRowData.Tvsh_Vlera,
        NrSerik: newRowData.NrSerik,
        Cmimi_Kosto: newRowData.Cmimi_Kosto,
        Skonto: newRowData.Skonto,
      };
      const response = await mainAxios.put(
        `/posdtl/${newRowData.Id}`,
        itemForSecondRequest
      );
      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Artikulli nuk u modifikua",
        });
      }
    } catch (error) {
      console.error("Error updating row:", error);
    }
  };

  const cellEditor = (options) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }
    return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event?.value;
    let orderedSelectedColumns = columns
      ?.filter((col) =>
        selectedColumns?.some((sCol) => sCol?.field === col?.field)
      )
      .map((col) => col.field);
    setselectedcolumns(orderedSelectedColumns);
  };

  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <MultiSelect
        value={filterdColumns}
        options={columns}
        optionLabel="title"
        onChange={onColumnToggle}
        style={{ width: "3em", height: "2rem", marginLeft: "2px" }}
      />
    </div>
  );

  // Dynamically generate columns based on filterdColumns
  const dynamicColumns = filterdColumns?.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    return (
      <Column
        key={uniqueKey}
        style={{ height: "2.6rem" }}
        {...(isEditable && {
          onCellEditComplete: (e) => onCellEditComplete(e),
        })}
        {...(isEditable && { editor: (options) => cellEditor(options) })}
        field={col?.field}
        header={col?.title}
        calculateData={calculateData}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData) => {
          const value = rowData[col?.field];
          let formattedValue;
          if (typeof value === "number") {
            if (Number.isInteger(value)) {
              formattedValue = value.toLocaleString();
            } else {
              formattedValue = value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }
          } else {
            formattedValue = value;
          }
          return (
            <span
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </span>
          );
        }}
      />
    );
  });

  const formatCurrency = (value, currency) => {
    return currency
      ? value.toLocaleString("en-US", { style: "currency", currency })
      : value;
  };

  let columnSum = (field) => {
    let Total = 0;
    let isSasiaColumn = field.toLowerCase() === "sasia";

    for (let row of rows) {
      Total += row[field];
    }
    return formatCurrency(Total, isSasiaColumn ? null : "ALL");
  };

  const footerGroup = () => {
    let checkCols = filterdColumns?.some((column) => {
      return column?.allowSum;
    });
    if (!checkCols) return <></>;

    return (
      <ColumnGroup>
        <Row>
          {filterdColumns?.map((column, i) => {
            if (column?.allowSum) {
              const uniqueKey = `${column?.field}_${i}`;
              return (
                <Column
                  key={uniqueKey}
                  colSpan={1}
                  footer={() => columnSum(column?.field)}
                />
              );
            }
            return <Column key={`empty_${i}`} colSpan={1} />;
          })}
        </Row>
      </ColumnGroup>
    );
  };

  let footerGr = footerGroup();

  const cm = useRef(null);

  const onColReorder = (event) => {
    const { dragIndex, dropIndex } = event;

    let columnsCopy = [...selectedColumns];
    const tempColumn = { ...selectedColumns[dragIndex] };
    columnsCopy[dragIndex] = selectedColumns[dropIndex];
    columnsCopy[dropIndex] = tempColumn;

    setselectedcolumns(columnsCopy);

    toast.current.show({
      severity: "success",
      summary: "Column Reordered",
      life: 3000,
    });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectionRow, setSelectionRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (selectedRowData) => {
    setSelectionRow(selectedRowData);
    setSelectedRowData(selectedRowData);
  };

  return (
    <div className="main_div_data_table_supermarket">
      <Toast ref={toast}></Toast>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="datatable_supermarket">
        <div className="prime-react">
          <DataTable
            // footer={footer}
            reorderablecolumnsdatatable="true"
            cellSelection
            selection={selectionRow}
            // onSelectionChange={
            //   disabled ? undefined : (e) => setSelectionRow(e.value)
            // }
            onContextMenu={
              disabled ? undefined : (e) => cm.current.show(e.originalEvent)
            }
            rowClassName={(rowData) =>
              selectedRowData && selectedRowData.Id === rowData.Id
                ? "selected-row"
                : ""
            }
            onSelectionChange={(e) => {
              const selectedRowData =
                e.value.length > 0 ? e.value[0].rowData : null;
              handleRowClick(selectedRowData);
            }}
            selectionMode="multiple"
            style={{ height: "100%" }}
            contextMenuSelection={selectedProduct}
            scrollable
            contextselection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onColReorder={onColReorder}
            resizableColumns
            columnResizeMode="fit"
            responsiveLayout="scroll"
            size="small"
            value={rows}
            stripedRows
            dataKey="Id"
            autoLayout={true}
            header={header}
            footerColumnGroup={footerGr}
            globalFilterFields={[
              "BarKod",
              "Pershkrim",
              "Njesi_Kodi",
              "Sasia_Print",
              "Sasia",
            ]}
            filterDisplay="menu"
            emptyMessage="Nuk ka te dhena"
            {...props}
          >
            {dynamicColumns.map((col) => {
              return React.cloneElement(col, {
                sortable: !disabled && col.props.sortable,
                editor: disabled ? undefined : col.props.editor,
              });
            })}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PrimeGridSuperRestaurant;
