import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useReactToPrint } from "react-to-print";
import mainAxios from "../../services/axios";

import DynamicTableSkontrino80 from "./dynamicSkontrinoTable80";
import Skontrino80 from "./skontrino80";

import DynamicTable from "./dynamicTable";
import SimpleTableSH from "./simpletable";

import Skontrino80Tatimore from "./Sontrino80Tatimore";

const PrintoPdf1 = ({
  fromFShitje,
  rowsData,
  mbylleTav,
  rows,
  state,
  fisVerificationUrl,
  posBar,
  operator,
  fatureThjesht,
  setPrintPorosia,
  menyrapageses,
  nipt,
  showToast,
  username,
  handleClose,
  konfigurimeRedux,
}) => {
  const componentsRef = useRef(null);
  const componentsRef2 = useRef(null);
  const componentsRef3 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentsRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  const handlePrint3 = useReactToPrint({
    content: () => componentsRef3.current,
  });

  const updateState = async () => {
    try {
      await executePutRequestsWithDelay(rows);
      handlePrint2();
      handleClose();
    } catch (error) {
      console.error("Error updating state:", error);
    } finally {
      handlePrint2();
    }
  };

  const executePutRequestsWithDelay = async (rows) => {
    for (const item of rows) {
      if (item.Printuar !== 1) {
        await makePutRequest(item);
        await delay(100);
      }
    }
  };

  const makePutRequest = async (item) => {
    try {
      const postDataWithoutId = { Printuar: 1 };
      await mainAxios.put(`/posdtl/${item.Id}`, postDataWithoutId, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      console.error(`Error updating item with ID ${item.Id}:`, error);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            {posBar === true ? (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rows.length === 0) {
                    showToast("Nuk keni asnje artikull", {
                      severity: "warn",
                    });
                  } else {
                    updateState();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  borderRadius: "20px",
                }}
              >
                Printo Faturen
              </Button>
            ) : (
              <Button {...bindTrigger(popupState)}>
                <PrintSharp />
              </Button>
            )}

            {posBar && (
              <Button
                onClick={() => {
                  if (rows.length === 0) {
                    mbylleTav("Tavolina u mbyll", "info");
                  } else {
                    mbylleTav("Tavolina u mbyll", "info");
                    handlePrint2();
                  }
                }}
                style={{
                  color: "#fff",
                }}
              >
                Mbylle
              </Button>
            )}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 1,
                  display: "grid",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint();
                    }
                  }}
                  color="primary"
                >
                  <PrintSharp /> Printo Fature Shitje
                </Button>

                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint3();
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <PrintSharp /> Printo Skotrino 80
                </Button>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      {fromFShitje && (
        <div ref={componentsRef3} className="print">
          <Skontrino80Tatimore
            fromftsh={true}
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino80
            rows={rowsData}
            state={state}
            fatureThjesht={true}
          />
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef} className="print">
          <SimpleTableSH
            state={state}
            konfigurimeRedux={konfigurimeRedux}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
            username={username}
          />
          <DynamicTable rows={rowsData} state={state} />
        </div>
      )}

      {posBar === true ? (
        <div ref={componentsRef2} className="print">
          <Skontrino80
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino80 rows={rows} state={state} />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PrintoPdf1;
