import React from "react";
import "../../ReactPDF/print.scss";

const TotalFooterFD = ({ state, FdShoqeruese }) => {
  return (
    <div>
      <div className="flex justify-between totalFooter pt-20">
        {/* Bleresi */}
        <div className="flex flex-col">
          <p
            style={{
              width: "140px",
            }}
            className="flex justify-center"
          >
            DOREZOI
          </p>
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            (emër mbiemër firme)
          </p>

          <hr className="line-hr" />
        </div>
        {/* Transportuesi */}
        {state.Transportuesi_Pershkrim === "" ||
        state.Transportuesi_Pershkrim === null ? null : (
          <div className="flex flex-col">
            <p
              style={{
                width: "140px",
              }}
              className="flex justify-center"
            >
              Transportuesi
            </p>
            <p
              style={{
                marginBottom: "10px",
              }}
            >
              (emër mbiemër firme)
            </p>

            <hr className="line-hr" />
          </div>
        )}

        {FdShoqeruese && (
          <div className="flex flex-col">
            <p
              style={{
                width: "140px",
              }}
              className="flex justify-center"
            >
              Transportuesi
            </p>
            <p
              style={{
                marginBottom: "10px",
              }}
            >
              (emër mbiemër firme)
            </p>

            <hr className="line-hr" />
          </div>
        )}

        <div className="flex flex-col">
          {/* Shitesi */}
          <p
            style={{
              width: "140px",
            }}
            className="flex justify-center"
          >
            PRANOI
          </p>
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            (emër mbiemër firme)
          </p>

          <hr className="line-hr" />
        </div>
      </div>

      {/* Additional content can be added here */}
    </div>
  );
};

export default TotalFooterFD;
