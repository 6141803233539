import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useUser } from "../../../zustand/common";
import { setAuthToken } from "../../../services/axios";
import { getCookie } from "../../../services/helpers";
import { Toast } from "primereact/toast";
import { LoadingOverlayContext } from "../../LoadingOverlay";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getNextKodiFsh,
  getNextKodiFb,
  getReferences,
  getMonedha,
  getMagazina,
  getFurnitor,
  getKlienti,
  getTarifa,
  getQytetet,
  getShtetet,
  getDepartamenti,
  getVeprime,
  getKlasifikim1,
  getKlasifikim2,
  getKlasifikim3,
  getKlasifikim4,
  getTransportuesi,
  getSherbime,
  getMenyraPageses,
  getBanka,
  getArka,
  getLlogari,
  getArtikujPag,
  // getArtikuj,
  getArkaList,
  getBankaList,
  getVetefaturim,
  getDocType,
  getProcesi,
  getPerdorues,
  getGrupi,
  getAgjenti,
  getKonfigurime,
  getTotalArt,
  getIndexFirstFtSh,
  getIndexFirstFtB,
  getIndexFirstFh,
  getIndexFirstFd,
  getCert,
} from "../../../store/globalSlice";
import { Checkbox } from "primereact/checkbox";
import { Button, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo2 from "../../../assets/Logo2.png";
import "./login.scss";

const useGlobalSmallApi = () => {
  const dispatch = useDispatch();

  const globalSmallApi = async (automatikisht) => {
    if (automatikisht === 9) {
      return;
    }
    const endpoints = [
      { action: getCert, path: "/cert" },
      { action: getIndexFirstFtB, path: "/first/faturab" },
      { action: getNextKodiFb, path: "/fb/nextKodi" },
      { action: getNextKodiFsh, path: "/fsh/nextKodi" },
      { action: getIndexFirstFtSh, path: "/first/faturash" },
      { action: getIndexFirstFh, path: "/first/fh" },
      { action: getIndexFirstFd, path: "/first/fd" },
      { action: getReferences, path: "/f_references" },
      { action: getTotalArt, path: "/art/total" },
      { action: getAgjenti, path: "/agjenti" },
      { action: getMonedha, path: "/monedha" },
      { action: getFurnitor, path: "/furnitori/search" },
      { action: getMagazina, path: "/magazina" },
      { action: getKlienti, path: "/klienti" },
      { action: getTarifa, path: "/tarifa" },
      { action: getQytetet, path: "/qyteti" },
      { action: getShtetet, path: "/shteti" },
      { action: getDepartamenti, path: "/departamenti" },
      { action: getKonfigurime, path: "/konfigurim" },
      { action: getVeprime, path: "/skema_veprimit" },
      { action: getKlasifikim1, path: "/klasifikimi1" },
      { action: getKlasifikim2, path: "/klasifikimi2" },
      { action: getKlasifikim3, path: "/klasifikimi3" },
      { action: getKlasifikim4, path: "/klasifikimi4" },
      { action: getTransportuesi, path: "/transporti" },
      { action: getSherbime, path: "/Sherbim" },
      { action: getMenyraPageses, path: "/menyra-pageses" },
      { action: getBanka, path: "/banka" },
      { action: getArka, path: "/arka" },
      { action: getArkaList, path: "/arka/list" },
      { action: getBankaList, path: "/blist" },
      { action: getArtikujPag, path: "/artikull/pag?page=1&&per_page=200" },
      // { action: getArtikuj, path: "/art" },
      { action: getLlogari, path: "/llogari/search" },
      { action: getVetefaturim, path: "/fisSelfIssuingType" },
      { action: getProcesi, path: "/fisprocess" },
      { action: getDocType, path: "/fisdocumenttype" },
      { action: getPerdorues, path: "/get/perdoruesi" },
      { action: getGrupi, path: "/art/kls01" },
    ];

    for (const { action, path } of endpoints) {
      try {
        await new Promise((resolve, reject) => {
          dispatch(action(path))
            .then(() => resolve())
            .catch((error) => reject(error));
        });
      } catch (error) {
        // Retry the request
        try {
          await new Promise((resolve, reject) => {
            dispatch(action(path))
              .then(() => resolve())
              .catch((retryError) => reject(retryError));
          });
        } catch (retryError) {
          // Display alert if retry also fails
          alert("Problem with getting full Data");
          throw retryError;
        }
      }
    }
  };

  return { globalSmallApi };
};
export { useGlobalSmallApi };

const Login = ({ setUserAutomatikisht }) => {
  const { globalSmallApi } = useGlobalSmallApi();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [showPassword, setShowPassword] = useState(true);
  const [checked, setChecked] = useState(false);
  const [cities, setCities] = useState([]);
  const [nipt, setNipt] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  useEffect(() => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const { username, nipt } = JSON.parse(storedData);
      if (username && nipt) {
        setUserName(username);
        setNipt(nipt);
        setChecked(true);
      }
    }
  }, []);

  // hide or show btn password
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onCityChange = (e) => {
    let selectedCities = [...cities];
    if (e.checked) selectedCities.push(e.value);
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);
    setCities(selectedCities);
    setChecked(e.checked);
    if (!e.checked) {
      localStorage.removeItem("loginData");
    }
  };

  const login = useUser((user) => user.login);

  const submit = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      showLoading();
      const res = await login({ username, nipt, password });
      if (res.success) {
        setAuthToken(getCookie("access_token"));
        const { automatikisht, user } = res;

        setUserAutomatikisht(automatikisht);

        if (automatikisht !== 9 && automatikisht !== 10) {
          await globalSmallApi(automatikisht);
        }
        const dataToStore = JSON.stringify({
          username: user.username,
          nipt: user.nipt,
        });

        if (checked) {
          localStorage.setItem("loginData", dataToStore);
        }

        if (automatikisht === 10) {
          navigate("/pos/market");
        } else if (automatikisht === 9) {
          navigate("/pos/bar");
        } else {
          navigate("/fature-shitje");
        }
      } else {
        setError(true);
        toast.current.show({
          severity: "error",
          summary: "Te dhenat jane gabim.",
        });
      }
    } catch (error) {
      setError(true);
      toast.current.show({
        severity: "error",
        summary: "Login failed: " + error.message,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <div>
      <div className="login_main_div flex" style={{ flexDirection: "column" }}>
        <Button
          style={{
            width: 60,
            height: 60,
            // borderRadius: "50%",
            alignSelf: "flex-start",
          }}
          variant="text"
          onClick={() => {
            window.open("https://blog.pss.al/", "_self");
            window.close();
          }}
        >
          <ArrowBackIcon /> Back
        </Button>
        <div className="card_login">
          <Toast ref={toast} />
          <form className="form" onSubmit={submit}>
            <div className="login_logo">
              <img src={Logo2} alt="Background1s" />
            </div>
            <div className="title_form">Sign In</div>

            <TextField
              className="mb-3"
              variant="outlined"
              required
              fullWidth
              label="Ndermarrje"
              size="small"
              type="text"
              value={nipt}
              sx={{ backgroundColor: "#fafafa", color: "#000" }}
              onChange={(e) => setNipt(e.target.value)}
              error={error}
            />

            <TextField
              className="mb-3"
              variant="outlined"
              required
              fullWidth
              label="User name"
              size="small"
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              autoComplete="username"
              error={error}
            />

            <div className="relative">
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Password"
                size="small"
                type={showPassword ? "password" : "text"}
                value={password}
                sx={{ backgroundColor: "#fafafa", color: "#000" }}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                error={error}
              />
              {showPassword ? (
                <VisibilityOffIcon
                  onClick={toggleShowPassword}
                  className="absolute cursor-pointer"
                  style={{ right: 15, top: 8 }}
                />
              ) : (
                <VisibilityIcon
                  onClick={toggleShowPassword}
                  className="absolute cursor-pointer"
                  style={{ right: 15, top: 8 }}
                />
              )}
            </div>
            <div className="flex flex-wrap items-center mb-3 mt-3 justify-between gap-2">
              <div className="flex gap-1 items-center">
                <Checkbox
                  value="Shumice"
                  onChange={onCityChange}
                  checked={checked}
                ></Checkbox>
                <span>Më rikujto!</span>
              </div>
              {/* <span>
                <Link to="/forgot" className="linku">
                  Keni harruar fjalëkalimin ?
                </Link>
              </span> */}
            </div>

            <div className="mb-3 mt-3">
              <Button fullWidth variant="contained" type="submit">
                Hyrje
              </Button>
            </div>

            {/* <div className="text-center">
              <span>
                <Link to="/register" className="linku">
                  Krijoni nje Account
                </Link>
              </span>
            </div> */}
          </form>
        </div>

        <div className="footers">
          ©2024 ParidSmartSolution, Inc. All rights reserved. •Terms of use
          •Privacy Policy
        </div>
      </div>
    </div>
  );
};

export default Login;
