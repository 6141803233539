import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";

const DialogXhiro = ({
  onClose,
  open,
  functionPost,
  setDialogXhiro,
  ngaData,
  deriData,
  mainData,
  selectedOperator,
  dataPosBak,
}) => {
  const formatDateString = (dateString) => {
    const dateObject = new Date(dateString);

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const mbyllXhiron = async () => {
    try {
      const formattedNgaDate = formatDateString(ngaData);
      const formattedDeriDate = formatDateString(deriData);
      const formattedMainDate = formatDateString(mainData);

      const response = await mainAxios.get(
        `/pos/fshngapos?nga='${formattedNgaDate}'&&deri='${formattedDeriDate}'&&o=${selectedOperator}&&voperator=${selectedOperator}&&dfsh='${formattedMainDate}'`
      );

      if (response.status === 200) {
        try {
          // for (let i = 0; i < dataPosBak.length; i++) {
          //   const item = dataPosBak[i];
          // }
          const arraDataPosPerBak = dataPosBak.map((item) => item.ID);

          const response = await mainAxios.post(
            `/pos/perbak`,
            arraDataPosPerBak
          );
          if (response.status === 201) {
            await functionPost();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setDialogXhiro(false);
        }
      } else {
        console.error("Response is null or status is not 200");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Confirmation</DialogTitle>

      <DialogContent>
        Deshironi te mbyllni xhiron per Operatorin: {selectedOperator}
      </DialogContent>

      <div className="buttons_confirmation">
        <Button onClick={mbyllXhiron} color="primary" variant="contained">
          PO
        </Button>

        <Button
          onClick={() => {
            // onClose();
            setDialogXhiro(false);
          }}
          color="primary"
          variant="contained"
        >
          JO
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogXhiro;
