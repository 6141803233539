import React, { useRef } from "react";
import { Button } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";

import SkontrinoXhiro from "./SkontrinoXhiro";
import DynamicXhiroBody from "./DynamicXhiroBody";

import SkontrinoXhiroFis from "./SkontrinoXhiro2";
import DynamicXhiroFis from "./DynamicXhiroFis";

const XhiroDyForma = ({
  rows,
  state,
  selectedUserData,
  posSupermarketListaFaturave,
  nipt,
  selectedId,
  showToast,
  menyra,
  konfigurimeRedux,
}) => {
  const componentsRef2 = useRef(null);

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button
              {...bindTrigger(popupState)}
              variant="contained"
              className="btn_fiskal flex"
              onClick={() => {
                if (rows.length === 0) {
                  showToast("Nuk ka artikuj.");
                  return;
                }
                if (!selectedId) {
                  showToast("Nuk ka fature te selektuar.");
                  return;
                } else {
                  handlePrint2();
                }
              }}
              style={{
                justifyContent: "space-between",
                margin: "5px",
              }}
            >
              <>
                <PrintSharp />
                Printo Faturen
              </>
            </Button>
          </>
        )}
      </PopupState>

      {/* {state.TipPrintimi === "Fiskale" ? (
        <div ref={componentsRef2} className="print">
          <SkontrinoXhiroFis
            operator={operator}
            state={state}
            menyrapageses={menyra}
            nipt={nipt}
          />
          <DynamicXhiroFis rows={rows} state={state} />
        </div>
      ) : ( */}
      <div ref={componentsRef2} className="print">
        <SkontrinoXhiro
          selectedUserData={selectedUserData}
          state={state}
          posSupermarketListaFaturave={posSupermarketListaFaturave}
          nipt={nipt}
          menyra={menyra}
          konfigurimeRedux={konfigurimeRedux}
        />
        <DynamicXhiroBody rows={rows} state={state} />
      </div>
      {/* )} */}
    </>
  );
};

export default XhiroDyForma;
