import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./MainComponent.scss";
import { Button } from "@mui/material";
import MainBotComponent from "../MainBotComponent/MainBotComponent";
import MainTopComponent from "../MainTopComponent/MainTopcomponent";
import mainAxios from "../../../../../services/axios";
import LeftBar from "../LeftBar/LeftBar";
import { Toast } from "primereact/toast";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Badge from "@mui/material/Badge";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import getColor from "../VariableColors/GetColor";
import PrintoPdf1 from "../../../../../components/ReactPDF/PrintoPdf1";
import BottomAppBar from "../../NavBarPOS/NavBarPos";
import TabelaRestaurant from "../DataTableArt/TabelaRestaurant";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";
import PrintoFisPos from "../../../../../components/ReactPDF/PrintoFisPos";
import { formatDate } from "../DateUtils";
import ShtoKlient from "../ShtoKlient/ShtoKlient";
import { shtoArtikull, calculateData } from "./ShtoArtikullFunction";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";
import { useApi } from "../../../../../components/context/NdermarrjeInfo";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";
import { useUser } from "../../../../../zustand/common";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -8,
    top: 1,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const MainComponent = ({
  isMobile,
  newId,
  numTav,
  dtlState,
  mbylleTav,
  setDtlNum,
  klienti,
  handleClose,
  fetchPos,
  defaultState,
  error,
  setError,
  art,
  setNoDataMessage,
  kls01,
  loadingLeft,
  menyra,
  setOpen,
  clickedItems,
  setClickedItems,
  setTotalOfflineCmimi,
  aExistTable,
}) => {
  const { user } = useUser();
  const operator = user.username;
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const { state, setState } = useMyContext();
  const { konfigurimeRedux } = useApi();
  const rows = clickedItems;
  const [artForGroups, setArtForGroups] = useState([]);
  const [artikujtPrint, setArtikujtPrint] = useState([]);
  const [faturaData, setFatura] = useState({});
  const [currentPorosia, setCurrentPorosia] = useState("0");
  const [artData, setArtKls] = useState(art);
  const [printPorosia, setPrintPorosia] = useState("0");
  const [numArt, setNumArt] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isMainTopOpen, setIsMainTopOpen] = useState(false);
  const [totaliFatura, setTotaliFatura] = useState(false);
  const [isLeftBarPrintOpen, setIsLeftBarPrintOpen] = useState(false);
  const [isDataTableOpen, setDataTableOpen] = useState(false);
  const [totalCmimi, setTotalCmimi] = useState(0);
  const [messageDisplay, setMessageDisplay] = useState(false);

  const toast = useRef(null);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };
  useEffect(() => {
    async function mbyllTavIfError(error) {
      if (error) {
        await handleClose(true);
        setError(false);
      }
    }
    mbyllTavIfError(error);
  }, [error, handleClose, setError]);

  useEffect(() => {
    showLoading();
    async function updateArtData() {
      setArtKls(art);
      setArtForGroups(art);
      setNumArt(art.length);
      hideLoading();
    }
    updateArtData();
  }, [art, showLoading, hideLoading]);

  useEffect(() => {
    setClickedItems(dtlState);
  }, [dtlState]);

  useEffect(() => {
    // Calculate the sum of Cmimi values for all items in clickedItems
    const sumCmimi = clickedItems.reduce(
      (acc, item) => acc + (item.Sasia * item.Cmimi || 0),
      0
    );
    setTotalCmimi(sumCmimi.toFixed(2));
    setTotalOfflineCmimi(sumCmimi.toFixed(2));
    setDtlNum(clickedItems.length);
  }, [clickedItems, setDtlNum]);

  const kategoriBtnfunction = async (kodi) => {
    try {
      const dataFilter = artForGroups.filter(
        (item) => item.Art_Kls01_Kodi === kodi
      );
      setArtKls(dataFilter);
    } catch (error) {
      console.error("kategori function error :", error);
    }
  };

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(
      artikull,
      showLoading,
      clickedItems,
      setClickedItems,
      newId,
      currentPorosia,
      operator,
      showToast,
      hideLoading
    );
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const theme = {};
  const ColoredItem = styled(Paper)(({ theme, pershkrimcolor }) => ({
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    marginRight: "10px",
    padding: theme.spacing(1.5),
    textAlign: "center",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    backgroundColor: getColor(theme, pershkrimcolor),
    maxHeight: "90%",
  }));

  const handleSearchChange = useMemo(
    () => (event) => {
      setSearchQuery(event.target.value);
      const filteredArt = artForGroups.filter((item) =>
        item.Pershkrim.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setArtKls(filteredArt);
    },
    [artForGroups, setNoDataMessage]
  );

  const handleToggleLeftBarPrint = () => {
    setIsLeftBarPrintOpen(!isLeftBarPrintOpen);
  };

  const toggleMainTop = () => {
    setIsMainTopOpen(!isMainTopOpen);
  };

  const toggleDataTable = () => {
    setDataTableOpen(!isDataTableOpen);
  };

  const shikoTotalin = () => {
    setTotaliFatura(!totaliFatura);
  };

  const handleAllButton = () => {
    setArtKls(art);
  };

  useEffect(() => {
    if (clickedItems.length === 0) {
      setMessageDisplay(true);
    }
  }, [clickedItems]);

  const handleFiskalizim = async (handlePrint2) => {
    if (clickedItems.length === 0) {
      const message = `Nuk keni asnje artikull ne fature !`;
      showToast(message, { severity: "warn" });
      return;
    }

    const { ...putAfterFisError } = {
      Aktiv: 1,
      Mbyllur: null,
      E_Paguar: 0,
    };

    const { ...putBeforeFis } = {
      Aktiv: 0,
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };

    try {
      const res = await mainAxios.put(`/pos/${newId}`, putBeforeFis, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        try {
          const response = await mainAxios.post(`/pos/${newId}`);
          const firstItem = response.data;

          if (firstItem.ErrorCode !== "0") {
            await mainAxios.put(`/pos/${newId}`, putAfterFisError, {
              headers: { "Content-Type": "application/json" },
            });

            const faultstring = firstItem.faultstring;
            showToast(faultstring, { severity: "error" });

            return;
          } else {
            const response = await mainAxios(`/pos/${newId}`);

            if (response.status === 200) {
              setFatura(response.data);
              await handlePrint2();
              setOpen(false);
              setState(defaultState);
              await fetchPos();
            }
          }
        } catch (error) {
          console.error("Error fiskalizimi:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loadingLeft ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="rectangle1">
          {!isMobile && (
            <div className="rectangle2">
              <>
                <LeftBar
                  state={state}
                  setArtikujtPrint={setArtikujtPrint}
                  shikoTotalin={shikoTotalin}
                  totaliFatura={totaliFatura}
                  printPorosia={printPorosia}
                  setCurrentPorosia={setCurrentPorosia}
                  isMobile={isMobile}
                  clickedItems={clickedItems}
                  onItemClick={handleShtoArtikull}
                  setClickedItems={setClickedItems}
                  toggleDataTable={toggleDataTable}
                  isDataTableOpen={isDataTableOpen}
                  showLoading={showLoading}
                  hideLoading={hideLoading}
                  messageDisplay={messageDisplay}
                  aExistTable={aExistTable}
                />

                <div className="footer">
                  <hr className="hr_pos" />
                  <div
                    className="total_amount flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="total">
                      <h1>Total</h1>
                    </div>
                    <div className="amount">
                      <h2>$ {totalCmimi}</h2>
                    </div>
                  </div>
                  <div
                    className="background_button flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <PrintoPdf1
                      konfigurimeRedux={konfigurimeRedux}
                      handleClose={handleClose}
                      posBar={true}
                      mbylleTav={mbylleTav}
                      fatureThjesht={true}
                      setPrintPorosia={setPrintPorosia}
                      className="print"
                      rows={artikujtPrint}
                      state={state}
                      operator={operator}
                      menyrapageses={menyra}
                      nipt={nipt}
                      showToast={showToast}
                    />

                    <ShtoKlient klienti={klienti} />

                    <PrintoFisPos
                      pos={true}
                      handleFiskalizim={handleFiskalizim}
                      rows={rows}
                      state={faturaData}
                      operator={operator}
                      menyra={menyra}
                      konfigurimeRedux={konfigurimeRedux}
                      nipt={nipt}
                    />
                  </div>
                </div>
              </>
            </div>
          )}
          <div
            className="mainPoss"
            style={{
              display: "flex",
              flexDirection: "column",
              ...(isMobile && { width: "100%", height: "100%" }),
            }}
          >
            <div
              className="header_Top"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                className="menu_Top flex"
                sx={{ color: "gray" }}
                onClick={toggleMainTop}
              >
                {/* <MenuBookIcon sx={{ color: "gray", paddingRight: "5px" }} />  */}
                Menu {isMainTopOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              {!isMobile && (
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon sx={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              )}
            </div>

            <MainTopComponent
              kls01={kls01}
              setArtKls={setArtKls}
              artForGroups={artForGroups}
              isMainTopOpen={isMainTopOpen}
            />

            {/* <hr className="new5" /> */}
            <div
              className="hr_Buttons"
              style={{ height: `${isMainTopOpen ? "10%" : ""}` }}
            >
              <Button
                className="btn_length"
                sx={{
                  backgroundColor: "#dbdbdbaf",
                  color: "black",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "5px",
                  marginRight: "10px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleAllButton}
              >
                <StyledBadge badgeContent={numArt} color="error">
                  {numArt === "" ? <ReplayIcon /> : "ALL"}
                </StyledBadge>
              </Button>

              {!isMainTopOpen &&
                kls01.map((item) => (
                  <ColoredItem
                    key={item.Kodi}
                    theme={theme}
                    pershkrimcolor={item.Pershkrim}
                    onClick={() => kategoriBtnfunction(item.Kodi, item)}
                  >
                    {item.Pershkrim}
                  </ColoredItem>
                ))}
            </div>

            <MainBotComponent
              artData={artData}
              isMainTopOpen={isMainTopOpen}
              clickedItems={clickedItems}
              onItemClick={handleShtoArtikull}
            />
            {isMobile && (
              <footer>
                <BottomAppBar
                  state={state}
                  setArtikujtPrint={setArtikujtPrint}
                  shikoTotalin={shikoTotalin}
                  totaliFatura={totaliFatura}
                  printPorosia={printPorosia}
                  setCurrentPorosia={setCurrentPorosia}
                  isMobile={isMobile}
                  clickedItems={clickedItems}
                  onItemClick={handleShtoArtikull}
                  setClickedItems={setClickedItems}
                  toggleDataTable={toggleDataTable}
                  rows={rows}
                  numTav={numTav}
                  isDataTableOpen={isDataTableOpen}
                  showLoading={showLoading}
                  faturaData={faturaData}
                  hideLoading={hideLoading}
                  messageDisplay={messageDisplay}
                  aExistTable={aExistTable}
                  pos={true}
                  handleFiskalizim={handleFiskalizim}
                  artikujtPrint={artikujtPrint}
                  operator={operator}
                  menyra={menyra}
                  konfigurimeRedux={konfigurimeRedux}
                  nipt={nipt}
                  handleClose={handleClose}
                  posBar={true}
                  mbylleTav={mbylleTav}
                  fatureThjesht={true}
                  setPrintPorosia={setPrintPorosia}
                  className="print"
                  showToast={showToast}
                  totalCmimi={totalCmimi}
                />
              </footer>
            )}
          </div>
          <div
            className="dataTable_Pos"
            style={{
              overflow: "hidden",
              height: isDataTableOpen ? "50%" : "0",
              transition: "height 1s",
            }}
          >
            <TabelaRestaurant
              rows={clickedItems}
              setRows={setClickedItems}
              shtoArtikull={handleShtoArtikull}
              calculateData={handleCalculateData}
            />
          </div>
          <Toast ref={toast} position="top-center" />
        </div>
      )}
    </>
  );
};

export default MainComponent;
