// SplitButton.js
import React, { useState, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MbylljeTurni from "../../../DialogButtons/MbylljeTurni";
import ListaFaturave from "../../../DialogButtons/ListaFaturave";
import ArkaPos from "../../../DialogButtons/ArkaPos";
import mainAxios from "../../../../../../services/axios";
import DialogConfirm from "./DialogConfirm";
import { Toast } from "primereact/toast";
import { formatDate } from "../../DateUtils";
import { Assignment, Receipt, Payment } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
const options = ["Mbyllje Turni", "Lista Faturave", "Arka"];

export default function SplitButton({
  rows,
  state,
  fetchPosSupermarket,
  menyra,
  arka,
  defaultState,
  setState,
}) {
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");
  const toast = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fromListaa, setFromLista] = useState(false);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const handleFiskalizim = async (handlePrint2, fromLista) => {
    const { ...postDataWithoutId } = {
      Aktiv: 0,
      KLFU_Pershkrim: "",
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };
    try {
      const response = await mainAxios.put(
        `/pos/${state.ID}`,
        postDataWithoutId,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status !== 200) {
        showToast("Ndodhi problem me mbylljen e tavolines", {
          severity: "error",
        });
      } else {
        try {
          const response = await mainAxios.post(`/pos/${state.ID}`);
          const firstItem = response.data;
          if (firstItem?.ErrorCode !== "0") {
            const faultstring = firstItem.faultstring;
            showToast(faultstring, { severity: "error" });
          } else {
            const response = await mainAxios(`/pos/${state.ID}`);
            if (response.status === 200) {
              setState(response.data);
              handlePrint2();
            }
          }
        } catch (error) {
          console.error("Error fiskalizimi:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      if (fromLista) {
        setFromLista(true);
      }
      setState(defaultState);
    }
  };

  const handleOpen = (e, index) => {
    if (index === 2) {
      setSelectedIndex(index);
    } else {
      setSelectedNumber(index);
      setShowConfirmation(true);
      return;
    }
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    setSelectedIndex(selectedNumber);
    return;
  };

  return (
    <>
      <Toast ref={toast} />
      <MenuList id="split-button-menu">
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(event) => {
              handleOpen(event, index);
            }}
          >
            <ListItemIcon>
              {index === 0 ? (
                <Assignment style={{ color: "#000" }} />
              ) : index === 1 ? (
                <Receipt style={{ color: "#000" }} />
              ) : (
                <Payment style={{ color: "#000" }} />
              )}
            </ListItemIcon>
            {option}
          </MenuItem>
        ))}
      </MenuList>

      <DialogConfirm
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        selectedNumber={selectedNumber}
        title="Zgjidhni Userin"
        contentText="Vendosni fjalkalimin per userin e selectuar."
      />

      {selectedIndex === 0 && (
        <MbylljeTurni
          open={selectedIndex === 0 && !showConfirmation}
          onClose={() => setSelectedIndex("")}
          rows={rows}
          fetchPosSupermarket={fetchPosSupermarket}
          state={state}
        />
      )}

      {selectedIndex === 1 && (
        <ListaFaturave
          open={selectedIndex === 1 && !showConfirmation}
          onClose={() => setSelectedIndex("")}
          handleFiskalizimi={handleFiskalizim}
          showToast={showToast}
          fetchPosSupermarket={fetchPosSupermarket}
          menyra={menyra}
          state={state}
          setState={setState}
          fromListaa={fromListaa}
          setFromLista={setFromLista}
        />
      )}
      {selectedIndex === 2 && (
        <ArkaPos open={true} onClose={() => setSelectedIndex("")} arka={arka} />
      )}
    </>
  );
}
