import React, { useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import SmallButtonGroup from "../SmallButton";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "primereact/dialog";
import { TextField } from "@mui/material";
import mainAxios from "../../../services/axios";
import "./Klienti.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTransportuesi } from "../../../store/globalSlice";

const Transportuesi = ({ numTrans }) => {
  const defaultState = {
    Pershkrim: "",
    Targa_e_Mjetit: "",
    Adresa: "",
    Nipt: "",
    Tel: "",
    Aktiv: 1,
  };
  const [currentAction, setCurrentAction] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toast = useRef(null);

  //get request per transportuesin
  const transRedux = useSelector((state) => state.globalSlice.transportuesi);
  const transportusi = transRedux;

  const dispatch = useDispatch();

  const onAddClick = () => {
    setCurrentAction("add");
  };

  const putMethod = () => {
    setCurrentAction("modify");
  };

  const submitHanlder = async () => {
    if (currentAction === "add") {
      if (
        !state.Pershkrim.trim() ||
        !state.Nipt.trim() ||
        !state.Targa_e_Mjetit.trim()
      ) {
        toast.current.show({
          severity: "error",
          summary:
            "Plotesoni fushat e kerkuara: " +
            (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
            (!state.Nipt.trim() ? "Nipt, " : "") +
            (!state.Targa_e_Mjetit.trim() ? "Targa " : ""),
        });
        return;
      }
      try {
        const responseTras = await mainAxios.post(`/transporti`, {
          Pershkrim: state.Pershkrim,
          Nipt: state.Nipt,
          Targa_e_Mjetit: state.Targa_e_Mjetit,
          Adresa: state.Adresa,
          Tel: state.Tel,
        });

        if (responseTras.status === 200) {
          setState(defaultState);
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getTransportuesi("/transporti"));
          toast.current.show({
            severity: "success",
            summary: "Transportuesi u regjistrua.",
          });
        }
      } catch (error) {
        console.error("Error Transportuesi: ", error);

        toast.current.show({
          severity: "error",
          summary: "Problem gjatë regjistrimit.",
        });
      }
    }
    if (currentAction === "modify") {
      try {
        const response = await mainAxios.put(`/transporti/${selectedRowId}`, {
          Pershkrim: state.Pershkrim,
          Nipt: state.Nipt,
          Targa_e_Mjetit: state.Targa_e_Mjetit,
          Adresa: state.Adresa,
          Tel: state.Tel,
        });

        if (response.status === 200) {
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getTransportuesi("/transporti"));
          toast.current.show({
            severity: "success",
            summary: "Transportuesi u regjistrua.",
          });
        }
      } catch (error) {
        console.error("Error Transporti: ", error);

        toast.current.show({
          severity: "error",
          summary: "Problem gjatë regjistrimit.",
        });
      }
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const resDelete = await mainAxios.delete(`/transporti/${selectedRowId}`);
      if (resDelete.status === 200) {
        setSelectedRowId(null);
        toast.current.show({
          severity: "info",
          summary: "U fshi.",
        });
        dispatch(getTransportuesi("/transporti"));
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const [columns] = useState([
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Targa_e_Mjetit", title: "Targa" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Adresa: rowData.Adresa,
      Tel: rowData.Tel,
      Nipt: rowData.Nipt,
      Pershkrim: rowData.Pershkrim,
      Targa_e_Mjetit: rowData.Targa_e_Mjetit,
    });
    setSelectedRowId(rowData.Id);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  //  useState i modaleve brenda kategorive ne fletblerje
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim, position) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <AddIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={() => onClick("displayResponsive")}
        >
          Open
        </AddIcon>

        <Dialog
          header=" Transportuesi"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              setSelectedRowId={setSelectedRowId}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              putMethod={putMethod}
              onAddClick={onAddClick}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Nipt"
              type="text"
              value={state?.Nipt}
              onChange={(e) => handleChange("Nipt", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Targa_e_Mjetit"
              type="text"
              value={state?.Targa_e_Mjetit}
              onChange={(e) => handleChange("Targa_e_Mjetit", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Adresa"
              type="text"
              value={state?.Adresa}
              onChange={(e) => handleChange("Adresa", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Tel"
              type="text"
              value={state?.Tel}
              onChange={(e) => handleChange("Tel", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            <div className="border p-2 flex flex-col mt-2">
              <DataTable
                editMode="cell"
                className="datatable-cell"
                selectionMode="single"
                selection={selectedProduct1}
                onSelectionChange={() => setSelectedProduct1(defaultState)}
                size="small"
                scrollHeight="10rem"
                value={transportusi}
                responsiveLayout="scroll"
                contextMenuSelection={selectedProduct}
                contextselection={selectedProduct}
                onContextMenuSelectionChange={(e) =>
                  setSelectedProduct(defaultState)
                }
                onContextMenu={(e) => cm.current.show(e.originalEvent)}
                onRowClick={(e) => handleRowClick(e.data)}
              >
                {dynamicColumns}
              </DataTable>
            </div>
            <Toast ref={toast} />
          </div>
          <span>
            <b>{numTrans}</b>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default Transportuesi;
