import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import useStorage from "../../../../hooks/useStorage";
import { Toast } from "primereact/toast";
import { ContextMenu } from "primereact/contextmenu";

export default function TableListaFaturaveSmSc({
  data,
  fetchDataTabelaFaturat,
  isFromMarket,
}) {
  const [columns] = useState(
    isFromMarket
      ? [
          { field: "KODI", title: "KODI" },
          { field: "DATA", title: "DATA" },
          { field: "KLFU_KODI", title: "Kod KLIENTI" },
          { field: "KLFU_PERSHKRIM", title: "KLIENTI" },
          { field: "OPERATOR", title: "OPERATOR" },
          { field: "VLERA", title: "VLERA", allowSum: true },
        ]
      : [
          { field: "KODI", title: "KODI" },
          { field: "DATA", title: "DATA" },
          { field: "KLFU_KODI", title: "Kod KLIENTI" },
          { field: "KLFU_PERSHKRIM", title: "KLIENTI" },
          { field: "OPERATOR", title: "OPERATOR" },
          { field: "TAVOLINA", title: "TAVOLINA" },
          { field: "VLERA", title: "VLERA", allowSum: true },
        ]
  );

  // Generate row numbers for rows not in the data
  const missingRowNumbers = Array.from(
    { length: data.length + 1 },
    (_, index) => index + 1
  ).filter((num) => !data.find((row) => row.Nr === num));

  const toast = useRef(null);
  const cm = useRef(null);
  const defaultColumnsStorage = columns.map((column) => column.title);
  const gridKey = "listaFaturave";

  // State variables
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );
  useEffect(() => {
    fetchDataTabelaFaturat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to calculate column sum
  let columnSum = (field) => {
    let Total = 0;

    if (data && Array.isArray(data)) {
      for (let row of data) {
        if (row[field]) {
          Total += Number(row[field]);
        }
      }
    }

    return Total > 0 ? Total.toFixed(2) : "0.00";
  };

  // Dynamically generate columns based on filterdColumns
  const dynamicColumns = columns?.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    return (
      <Column
        key={uniqueKey}
        style={{ height: "2.6rem" }}
        field={col?.field}
        header={col?.title}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData, rowIndex) => {
          const value =
            col.field === "index" ? rowIndex + 1 : rowData[col?.field];
          let formattedValue;
          if (typeof value === "number") {
            if (Number.isInteger(value)) {
              formattedValue = value.toLocaleString();
            } else {
              formattedValue = value.toLocaleString();
              formattedValue = value.toFixed(2);
            }
          } else {
            formattedValue = value;
          }
          return (
            <span
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </span>
          );
        }}
      />
    );
  });

  const missingRowNumberColumn = {
    field: "MissingRowNumber",
    title: "Nr",
    body: (rowData) => {
      const index = data.findIndex((row) => row === rowData) + 1;
      return missingRowNumbers.includes(index) ? index : "";
    },
  };

  // Add the "Nr" column as the first column
  dynamicColumns.unshift(
    <Column
      key="missingRowNumberColumn"
      field={missingRowNumberColumn.field}
      header={missingRowNumberColumn.title}
      body={missingRowNumberColumn.body}
      style={{ width: "3rem", textAlign: "center" }}
      headerStyle={{ width: "3rem", textAlign: "center" }}
    />
  );

  // Function to generate footer group
  const footerGroup = () => {
    let checkCols = columns?.some((column) => {
      return column?.allowSum;
    });
    if (!checkCols) return <></>;

    return (
      <ColumnGroup>
        <Row>
          {columns?.map((column, i) => {
            if (column?.allowSum) {
              const uniqueKey = `${column?.field}_${i}`;
              return (
                <Column
                  key={uniqueKey}
                  colSpan={1}
                  footer={() => columnSum(column?.field)}
                  footerStyle={{ textAlign: "right" }}
                />
              );
            }
            return <Column key={`empty_${i}`} colSpan={1} />;
          })}
        </Row>
      </ColumnGroup>
    );
  };

  let footerGr = footerGroup();

  // Event handler for column reorder
  const onColReorder = (event) => {
    const { dragIndex, dropIndex } = event;

    let columnsCopy = [...selectedColumns];
    const tempColumn = { ...selectedColumns[dragIndex] };
    columnsCopy[dragIndex] = selectedColumns[dropIndex];
    columnsCopy[dropIndex] = tempColumn;

    setselectedcolumns(columnsCopy);

    toast.current.show({
      severity: "success",
      summary: "Column Reordered",
      life: 3000,
    });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const dt = useRef(null);

  return (
    <>
      <Toast ref={toast} />
      <ContextMenu ef={cm} />

      <div className="card" style={{ height: "90%", overflow: "auto" }}>
        <DataTable
          reorderablecolumnsdatatable="true"
          cellSelection
          selectionMode="multiple"
          style={{ position: "relative" }}
          ref={dt}
          contextMenuSelection={selectedProduct}
          scrollable
          contextselection={selectedProduct}
          onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
          onColReorder={onColReorder}
          resizableColumns
          responsiveLayout="scroll"
          size="small"
          value={data}
          stripedRows
          dataKey="Id"
          autoLayout={true}
          footerColumnGroup={footerGr}
          filterDisplay="menu"
          emptyMessage="Nuk ka te dhena"
        >
          {dynamicColumns}
        </DataTable>
      </div>
    </>
  );
}
