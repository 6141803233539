import mainAxios from "../../../../../../services/axios";

const shtoArtikull = async (
  artikull,
  rows,
  setRows,
  currentId,
  showToast,
  operator
) => {
  if (!artikull) return;
  let currObj = rows.find((row) => row.Id === artikull?.Id);
  if (currObj) {
    return;
  }
  if (!currentId) {
    showToast("Ska Fatura, klikoni Shtim", { severity: "info" });
    return;
  }

  // Calculate the initial item
  const newItem = {
    ...artikull,
    Sasia: 1,
    Tvsh: 20,
    BarKod: artikull.Barkod,
    Cmimi: artikull.Cmimi ? artikull.Cmimi : 0,
    Skonto: 0,
    Cmimi_me_skonto: 0,
    Cmimi_Pa_Tvsh: 0,
    Vlera: 0,
    Tvsh_Vlera: 0,
    Skonto_Vlera: 0,
    Vlera_Pa_Tvsh: 0,
    Total: (artikull.Cmimi ? artikull.Cmimi : 0) * 1,
  };

  // Calculate the new item data
  const calculatedItem = calculateData(
    { Cmimi: newItem.Cmimi, Barkod: newItem.BarKod },
    newItem
  );
  setRows((rows) => [calculatedItem, ...rows]);

  try {
    const itemForSecondRequest = {
      Master_Id: currentId,
      Pershkrim: calculatedItem.Pershkrim,
      Vlera: calculatedItem.Vlera,
      Sasia: calculatedItem.Sasia,
      Tipi: calculatedItem.Tipi,
      SkontoAgjent: calculatedItem.SkontoAgjent,
      Cmimi: calculatedItem.Cmimi,
      Operator: String(operator),
      Vlera_Pa_Tvsh: calculatedItem.Vlera_Pa_Tvsh,
      Kodi: calculatedItem.Kodi,
      Tvsh: calculatedItem.Tvsh,
      DateGarancie: calculatedItem.DateGarancie,
      NrLL: calculatedItem.NrLL,
      Shenim2: calculatedItem.Shenim2,
      IdOld: calculatedItem.IdOld,
      Skonto_VleraAgjent: calculatedItem.Skonto_VleraAgjent,
      Art_ID: calculatedItem.Art_ID,
      Punonjes_Id: calculatedItem.Punonjes_Id,
      BarKod: calculatedItem.BarKod,
      Njesi_Kodi: calculatedItem.Njesi_Kodi,
      Skonto_Vlera: calculatedItem.Skonto_Vlera,
      Total: calculatedItem.Total,
      Magazina_Kodi: calculatedItem.Magazina_Kodi,
      TipVlere_ID: calculatedItem.TipVlere_ID,
      Tvsh_Vlera: calculatedItem.Tvsh_Vlera,
      NrSerik: calculatedItem.NrSerik,
      Cmimi_Kosto: calculatedItem.Cmimi_Kosto,
      Skonto: calculatedItem.Skonto,
    };

    const response = await mainAxios.post("/posdtl", itemForSecondRequest);
    if (response.status === 200) {
      // Update the Master_Id of the new row with the response.data.Id
      const newRows = [{ ...calculatedItem, Id: response.data.Id }, ...rows];
      setRows(newRows);
    }
  } catch (error) {
    console.error("Error Post item:", error);
  }
};

const calculateData = (field, item) => {
  let {
    Tvsh,
    Cmimi_Pa_Tvsh,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Vlera_Pa_Tvsh,
    Cmimi_me_skonto,
    Vlera,
    Total,
  } = item;

  let tvsh, VLSKONTO, cm_paTVSH, sasia;

  tvsh = (Tvsh + 100) / 100;

  const [[cell, cellVal]] = Object.entries(field);

  // Formulat e kontabilitetit
  // ka nevoje per modifikim shto handle change e.g item.Skonto
  try {
    let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
    let SkontoPrv = Number(cell === "Skonto" ? cellVal : Skonto);
    let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);
    cm_paTVSH = CmimiPrv / tvsh;
    sasia = SasiaPrv;
    VLSKONTO = (sasia * cm_paTVSH * SkontoPrv) / 100;

    if (cell === "Skonto") {
      Skonto = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Skonto_Vlera = VLSKONTO;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Cmimi") {
      Cmimi = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = CmimiPrv * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
    }
    if (cell === "Sasia") {
      Sasia = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
    }
    if (cell === "Vlera") {
      Vlera = cellVal;
      Skonto = 0;
      Skonto_Vlera = 0;
      Cmimi = Vlera / Math.abs(sasia);
      cm_paTVSH = Cmimi / tvsh;
      VLSKONTO = 0;
      Tvsh_Vlera = Vlera - Vlera / tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }

    if (cell === "Skonto_Vlera") {
      Skonto_Vlera = cellVal;
      VLSKONTO = Skonto_Vlera;
      Skonto = (VLSKONTO * 100) / (sasia * cm_paTVSH);
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Vlera = Vlera_Pa_Tvsh * tvsh;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Cmimi_Pa_Tvsh") {
      Cmimi_Pa_Tvsh = cellVal;
      tvsh = Tvsh / 100; //=0.2
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Vlera_Pa_Tvsh = Cmimi_Pa_Tvsh * Sasia;
      Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * (1 + tvsh);
      if (sasia !== 0) {
        Cmimi = Math.abs(Vlera / Sasia);
      }
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
      Tvsh_Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * tvsh;
    }
    if (cell === "Tvsh") {
      Tvsh = cellVal;
      tvsh = Tvsh / 100; //0.2
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Cmimi = (Cmimi_Pa_Tvsh - VLSKONTO / Math.abs(Sasia)) * (1 + tvsh);
      Vlera = Sasia * Cmimi;
      Tvsh_Vlera = Vlera_Pa_Tvsh * tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    Total = Vlera * 1;
    return {
      ...item,
      Tvsh,
      Cmimi_Pa_Tvsh,
      Cmimi_me_skonto,
      Sasia,
      Skonto,
      Cmimi,
      Tvsh_Vlera,
      Skonto_Vlera,
      Vlera_Pa_Tvsh,
      Vlera,
      Total,
    };
  } catch (error) {
    Vlera = 0;
    return item;
  }
};

export { shtoArtikull, calculateData };
