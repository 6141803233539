import React, { useState, useRef, useContext } from "react";
import { Checkbox } from "primereact/checkbox";
import { TextField } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import mainAxios from "../../../services/axios";
import { Toast } from "primereact";
import { getSherbime } from "../../../store/globalSlice";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import SmallButtonGroup from "../SmallButton";
import Tarifat from "../../paneliIartikujve/MiniModalArtikuj/Tepergjithshme/Tarifat";
import { useUser } from "../../../zustand/common";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";

const Sherbime = ({ numSherbime, sherbime }) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const [currentAction, setCurrentAction] = useState("");
  // tarifa redux
  const tarifaRedux = useSelector((state) => state.globalSlice.tarifa);
  const tarifa = tarifaRedux;
  const numTarifa = `${tarifa ? tarifa.length : 0} Rekorde`;

  const njesia = [
    { Kodi: "BIDO", Pershkrim: "BIDO" },
    { Kodi: "COP", Pershkrim: "Cope" },
    { Kodi: "KG", Pershkrim: "Kilogram" },
    { Kodi: "KOL", Pershkrim: "Koli" },
    { Kodi: "KT", Pershkrim: "Kuti" },
    { Kodi: "KV", Pershkrim: "Kuintal" },
    { Kodi: "LIT", Pershkrim: "Litra" },
    { Kodi: "M2", Pershkrim: "Metro Katrore" },
    { Kodi: "M3", Pershkrim: "Metro Kub" },
    { Kodi: "ML", Pershkrim: "Metro Lineare" },
    { Kodi: "ORE", Pershkrim: "Ore Pune" },
    { Kodi: "TON", Pershkrim: "Ton" },
    { Kodi: "TUF", Pershkrim: "tufa zorre 80-90m" },
  ];

  const tip_Vlere_Shitje = [
    { Pershkrim: "ë-Shitje te perjashtuara", Id: "1" },
    { Pershkrim: "f-Shitje pa tvsh", Id: "2" },
    { Pershkrim: "g-Eksporte mallrash", Id: "3" },
    { Pershkrim: "gj-Furnizime me zero", Id: "4" },
  ];

  const defaultState = {
    Aktiv: 1,
    Kodi: "",
    Pershkrim: "",
    Tarifa_Kodi: "",
    Njesi_Kodi: "",
    Cmimi: "",
    NrLL: "",
    NrLL_Blerje: "",
    NrLL_Shitje: "",
    TipVlereBlerje_ID: "",
    TipVlereImport_ID: "",
    TipVlereShitje_ID: "",
  };
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const onAddClick = () => {
    setCurrentAction("add");
  };

  const putMethod = () => {
    setCurrentAction("modify");
  };

  const submitHanlder = async () => {
    try {
      showLoading();
      if (currentAction === "add") {
        if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
          toast.current.show({
            severity: "error",
            summary:
              "Plotesoni fushat e kerkuara: " +
              (!state.Kodi.trim() ? "Kodi, " : "") +
              (!state.Pershkrim.trim() ? "Pershkrim " : ""),
          });
          return;
        }
        try {
          const responseTar = await mainAxios.post(`/Sherbim`, {
            Pershkrim: state.Pershkrim,
            Kodi: state.Kodi,
            Aktiv: state.Aktiv,
            Tarifa_Kodi: state.Tarifa_Kodi,
            Njesi_Kodi: state.Njesi_Kodi,
            Cmimi: state.Cmimi,
            NrLL: state.NrLL, //Sherbim
            NrLL_Blerje: state.NrLL_Blerje, //Blerje
            NrLL_Shitje: state.NrLL_Shitje, //Shitje
            TipVlereBlerje_ID: Number(state.TipVlereBlerje_ID),
            TipVlereImport_ID: Number(state.TipVlereImport_ID),
            TipVlereShitje_ID: Number(state.TipVlereShitje_ID),
          });

          if (responseTar.status === 200) {
            setState(defaultState);
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getSherbime("/Sherbim"));

            toast.current.show({
              severity: "success",
              summary: "Sherbimi u regjistrua.",
            });
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Nuk u regjistrua.",
          });
        }
      }
      if (currentAction === "modify") {
        try {
          const response = await mainAxios.put(`/Sherbim/${selectedRowId}`, {
            Kodi: state.Kodi,
            Pershkrim: state.Pershkrim,
            Nipt: state.Nipt,
            NrLL: state.NrLL,
            Aktiv: state.Aktiv,
            Adresa: state.Adresa,
            Shteti_Kodi: state.Shteti_Kodi,
            Qyteti_Kodi: state.Qyteti_Kodi,
            Tip_Cmimi: state.Tip_Cmimi,
          });

          if (response.status === 200) {
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getSherbime("/Sherbim"));

            toast.current.show({
              severity: "success",
              summary: "Sherbimi u regjistrua.",
            });
          }
        } catch (error) {
          console.error("Error Sherbimi: ", error);

          toast.current.show({
            severity: "error",
            summary: "Problem gjatë regjistrimit.",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Ndodhi problem.",
      });
    } finally {
      hideLoading();
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=Sherbim&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });

        return;
      } else {
        const resDelete = await mainAxios.delete(`/Sherbim/${selectedRowId}`);
        if (resDelete.status === 200) {
          setSelectedRowId(null);
          toast.current.show({
            severity: "info",
            summary: "U fshi.",
          });
          dispatch(getSherbime("/Sherbim"));
        }
      }
    } catch (err) {
      console.error(err);

      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? 1 : 0) : value,
      };
    });
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Cmimi", title: "Cmimi" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      Aktiv: rowData.Aktiv,
      Tarifa_Kodi: rowData.Tarifa_Kodi,
      Njesi_Kodi: rowData.Njesi_Kodi,
      NrLL: rowData.NrLL, //Sherbim
      NrLL_Blerje: rowData.NrLL_Blerje, //Blerje
      NrLL_Shitje: rowData.NrLL_Shitje, //Shitje
      Cmimi: rowData.Cmimi,
    });
    setSelectedRowId(rowData.Id);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  //  useState i modaleve brenda kategorive ne fletblerje
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    return check ? (
      <Column
        key={uniqueKey}
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={uniqueKey} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <Button
          style={{
            marginLeft: "1em",
            width: "3em",
            height: "2em",
            background: "rgb(21, 100, 173)",
            color: "#fff",
          }}
          onClick={() => onClick("displayResponsive")}
        >
          Shto
        </Button>

        <Dialog
          header="Sherbime"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              setSelectedRowId={setSelectedRowId}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              putMethod={putMethod}
              onAddClick={onAddClick}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            {/* <span className='absolute z-20' style={{top:-15,color:"#1971c2",backgroundColor:"white",fontWeight:500}}>Skema</span> */}

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={state?.Kodi}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <div className="flex justify-between">
              <div className="flex items-center">
                <Checkbox
                  disabled={disabled}
                  checked={state?.Aktiv === 1}
                  onChange={(e) =>
                    handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0)
                  }
                />
                {/* <Checkbox
                  disabled={disabled}
                  checked={state.Aktiv}
                  onChange={(e) => handleChangeAktiv("Aktiv", e.target.checked)}
                /> */}
                <label
                  label
                  style={{ marginLeft: "5px", fontWeight: 500 }}
                  htmlFor="cb2"
                  className="p-checkbox-label"
                >
                  Aktiv
                </label>
              </div>
            </div>

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Cmimi"
              type="text"
              value={state?.Cmimi}
              onChange={(e) => handleChange("Cmimi", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Sherbim"
              type="text"
              value={state?.NrLL}
              onChange={(e) => handleChange("NrLL", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Blerje"
              type="text"
              value={state?.NrLL_Blerje}
              onChange={(e) => handleChange("NrLL_Blerje", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Shitje"
              type="text"
              value={state?.NrLL_Shitje}
              onChange={(e) => handleChange("NrLL_Shitje", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <Form.Select
              className="mt-2 w-7/12"
              disabled={disabled}
              size="sm"
              value={state.Njesi_Kodi || ""}
              onChange={(e) => {
                handleChange("Njesi_Kodi", e.target.value);
              }}
            >
              <option label="Njesia" />
              {njesia?.map((item) => {
                return (
                  <option key={item.Kodi} className="selekt" value={item.Kodi}>
                    {item.Pershkrim}
                  </option>
                );
              })}
            </Form.Select>

            <div className="flex items-center">
              <Form.Select
                className="mt-2 w-7/12"
                disabled={disabled}
                size="sm"
                value={state?.Tarifa_Kodi}
                onChange={(e) => handleChange("Tarifa_Kodi", e.target.value)}
              >
                <option label="Tarifa" />
                {tarifa?.map((list) => {
                  return (
                    <option key={list.Kodi} value={list?.Kodi}>
                      {list?.Pershkrim}
                    </option>
                  );
                })}
              </Form.Select>

              <span
                className="flex items-center"
                style={{
                  border: "solid 1px #ddd",
                  backgroundColor: "#1971c2",
                  color: "#fff",
                }}
              >
                <Tarifat numTarifa={numTarifa} tarifa={tarifa} />
              </span>
            </div>

            <Form.Select
              className="mt-2 w-7/12"
              disabled={disabled}
              size="sm"
              value={state.TipVlereShitje_ID || ""}
              onChange={(e) => {
                handleChange("TipVlereShitje_ID", e.target.value);
              }}
            >
              <option label="Tip Vlere Shitje" />
              {tip_Vlere_Shitje?.map((item) => {
                return (
                  <option key={item.Kodi} className="selekt" value={item.Kodi}>
                    {item.Pershkrim}
                  </option>
                );
              })}
            </Form.Select>

            <Form.Select
              className="mt-2 w-7/12"
              disabled={disabled}
              size="sm"
              value={state.TipVlereBlerje_ID || ""}
              onChange={(e) => {
                handleChange("TipVlereBlerje_ID", e.target.value);
              }}
            >
              <option label="Tip Vlere Blerje" />
              {tip_Vlere_Shitje?.map((item) => {
                return (
                  <option key={item.Kodi} className="selekt" value={item.Kodi}>
                    {item.Pershkrim}
                  </option>
                );
              })}
            </Form.Select>

            <Form.Select
              className="mt-2 w-7/12"
              disabled={disabled}
              size="sm"
              value={state.TipVlereImport_ID || ""}
              onChange={(e) => {
                handleChange("TipVlereImport_ID", e.target.value);
              }}
            >
              <option label="Tip Vlere Import" />
              {tip_Vlere_Shitje?.map((item) => {
                return (
                  <option key={item.Kodi} className="selekt" value={item.Kodi}>
                    {item.Pershkrim}
                  </option>
                );
              })}
            </Form.Select>

            <div className="border p-2 flex flex-col mt-2">
              <div className="flex items-center">
                <DataTable
                  editMode="cell"
                  selectionMode="single"
                  selection={selectedProduct1}
                  onSelectionChange={() => setSelectedProduct1(defaultState)}
                  size="small"
                  scrollHeight="10rem"
                  value={sherbime}
                  responsiveLayout="scroll"
                  style={{ width: "100%" }}
                  contextMenuSelection={selectedProduct}
                  contextselection={selectedProduct}
                  onContextMenuSelectionChange={(e) =>
                    setSelectedProduct(defaultState)
                  }
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  onRowClick={(e) => handleRowClick(e.data)}
                >
                  {dynamicColumns}
                </DataTable>
              </div>
              <Toast ref={toast} />
            </div>
          </div>
          <span>
            <b>{numSherbime}</b>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default Sherbime;
