// Importing necessary dependencies and services
import create from "zustand";
import mainAxios, { setAuthToken } from "../services/axios";
import { expireCookie, setCookie } from "../services/helpers";

// Creating and exporting the Zustand store
export const useUser = create((set) => ({
  user: null,

  // Logout function
  logout: () => {
    set({ user: null });
    setAuthToken(); // Optionally clear authentication token
    expireCookie("access_token");
    // Navigate to /login after logout
    window.location.href = "/login";
  },

  // Login function
  login: async ({ username, nipt, password }) => {
    try {
      // Making a login request to the server
      const response = await mainAxios.post("/login", {
        username,
        nipt,
        password,
      });

      // Destructuring the response data
      const { access_token, automatikisht, refresh_token, ...user } =
        response?.data;

      if (response.status === 200) {
        set({ user });
        setAuthToken(access_token); // Setting the authentication token
        setCookie("access_token", access_token); // Setting the access token in a cookie
        // Use automatikisht value here as needed
        return { success: true, automatikisht, user }; // Returning success status, automatikisht, and user data
      }

      return { success: false }; // Returning false for an unsuccessful login
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  },
}));
