import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print.scss";
import TotalFooter from "../FaqetEprintimit/TotalFooter";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return number.toFixed(2);
  }
  return "N/A";
};

const DynamicTable = ({ rows, state }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="solid-border">{rowNumber}</td>
        <td>{info.Pershkrim}</td>
        <td>{info.Njesi_Kodi}</td>
        <td>{info.Sasia}</td>
        <td>{formatNumber(info.Cmimi_Pa_Tvsh)}</td>
        <td>{formatNumber(info.Vlera_Pa_Tvsh)}</td>
        <td>{formatNumber(info.Tvsh_Vlera)}</td>
        <td>{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  const groupedRows = rows.reduce((acc, info) => {
    const key = info.Tvsh.toString();
    if (!acc[key]) {
      acc[key] = { totalVleraPaTvsh: 0, totalVlera: 0 };
    }
    acc[key].totalVleraPaTvsh += info.Vlera_Pa_Tvsh;
    acc[key].totalVlera += info.Vlera;
    return acc;
  }, {});

  const DisplayDataTable2 = Object.entries(groupedRows).map(
    ([tvsh, totals]) => {
      let tipi = "";
      if (tvsh === "6") {
        tipi = "D";
      } else if (tvsh === "20") {
        tipi = "B";
      } else if (tvsh === "10") {
        tipi = "C";
      } else {
        tipi = "A";
      }

      return (
        <tr key={tvsh}>
          <td>{tipi}</td>
          <td>{tvsh}</td>
          <td>{formatNumber(totals.totalVleraPaTvsh)}</td>
          <td>{formatNumber(totals.totalVlera)}</td>
        </tr>
      );
    }
  );

  const sums = {
    Vlera_pa_Skonto: formatNumber(
      rows.reduce(
        (total, info) => total + info.Vlera * state.Kursi + info.Skonto_Vlera,
        0
      )
    ),

    Vlera_e_Skontos: formatNumber(
      rows.reduce((total, info) => total + info.Skonto_Vlera, 0)
    ),
    Tvsh: formatNumber(rows.reduce((total, info) => info.Tvsh, 0)),
    Tvsh_Vlera: formatNumber(
      rows.reduce((total, info) => total + info.Tvsh_Vlera * state.Kursi, 0)
    ),
    Vlera_Pa_Tvsh: formatNumber(
      rows.reduce((total, info) => total + info.Vlera_Pa_Tvsh, 0)
    ),
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
    Total: formatNumber(rows.reduce((total, info) => total + info.Total, 0)),
  };
  return (
    <table className="HeaderTable border">
      {/* <thead>
        <tr>
          <td className="title" colSpan={2}>
            Printed at: {new Date().toLocaleString()}
          </td>
        </tr>
      </thead> */}
      <table className="table table-striped bordered table-body">
        <thead className="thead thead-striped ">
          {/* Kolona 1 */}
          <tr>
            <th>Nr</th>
            <th>Pershkrimi i Mallit</th>
            <th>Njesia e matjes</th>
            <th>Sasia</th>
            <th>Cmimi per njesi</th>
            <th>Vlera pa TVSH (4*6)</th>
            <th>TVSH (6*20%)</th>
            <th>Vlera Total (6*7)</th>
          </tr>
        </thead>

        <tbody className="tbody-print">
          {DisplayData}

          <tr>
            {state.Shenim == null ? (
              <td colSpan={3}></td>
            ) : (
              <td colSpan={3}>Shenim: {state.Shenim}</td>
            )}
            <td colSpan={2}>TOTALI NE {state.Mon}</td>
            <td>{sums.Vlera_Pa_Tvsh}</td>
            <td>{sums.Tvsh_Vlera}</td>
            <td>{sums.Total}</td>
          </tr>

          {state.Mon !== "ALL" && (
            <tr>
              {state.Shenim == null ? (
                <td colSpan={3}></td>
              ) : (
                <td colSpan={3}>Shenim: {state.Shenim}</td>
              )}
              <td colSpan={2}>TOTALI NE ALL</td>
              <td>{formatNumber(sums.Vlera_Pa_Tvsh * state.Kursi)}</td>
              <td>{formatNumber(sums.Tvsh_Vlera * state.Kursi)}</td>
              <td>{formatNumber(sums.Total * state.Kursi)}</td>
            </tr>
          )}
        </tbody>
        {state.Mon !== "ALL" && (
          <tfoot>
            <tr className="space">
              <td colSpan={3}>
                KURSI I KEMBIMIT {state.Mon}/ALL:{state.Kursi}
              </td>
              <td colSpan={4}></td>
              <td colSpan={1}>Totali permbledhes</td>
            </tr>
          </tfoot>
        )}
      </table>

      <div className="footer-bleresi ">
        <table className="table_tvsh table bordered">
          <thead>
            <tr>
              <th>Tipi</th>
              <th>TVSH</th>
              <th>Vlera pa TVSH</th>
              <th>Vlera me TVSH</th>
            </tr>
          </thead>
          <tbody>{DisplayDataTable2}</tbody>
        </table>
        <TotalFooter state={state} className="m-2" />

        {/* Transportuesi */}
        {state.Transportuesi_Pershkrim === "" ||
        state.Transportuesi_Pershkrim === null ? null : (
          <div className="tr_transportues">
            <tr>
              <td className="border-true transportuesi">
                <div className="solid-border">
                  Transportuesi: {state.Transportuesi_Pershkrim}
                </div>
                <div className="solid-border"> Adresa: {state.Adresa}</div>
                <div className="solid-border">
                  NIPT: {state.Transportuesi_Nipt}
                </div>
                <div className="solid-border">
                  {" "}
                  Targa: {state.Targa_e_Mjetit}
                </div>
              </td>
            </tr>
          </div>
        )}
      </div>
      <tfoot>
        <tr>
          <td className="footer-gjeneruar" colSpan={8}>
            Gjeneruar nga Parid Smart Solution
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicTable;
