// NavBarSuperMarket.js
import React, { useState, useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import "./NavBarSuperMarket.scss";
import { People } from "@mui/icons-material";
import { Button } from "@mui/material";
import LoginSupermarket from "./LoginSupermarket";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { useUser } from "../../../../../zustand/common";
import { useNavigate } from "react-router-dom";

const NavBarSuperMarket = ({
  nipt,
  userAutomatikisht,
  setUserAutomatikisht,
  operator,
}) => {
  const navigate = useNavigate();
  const { logout } = useUser();
  const [logoutbtn, setLogout] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [perdorues, setPerdorues] = useState([]);

  const toast = useRef(null);
  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      position: "top-center",
      detail: options.detail || "",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };

  useEffect(() => {
    if (!logoutbtn) {
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

      const handleUnload = () => {
        navigate("/login");
        logout();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    } else {
      logout();
    }
  }, [logout, logoutbtn]);

  const perdoruesFromGlobal = useSelector(
    (state) => state.globalSlice.perdorues
  );

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (confirmed) => {
    setOpenDialog(false);

    if (confirmed) {
      setLogout(true);
    }
  };

  const fetchDataPerdorues = async () => {
    try {
      const filteredData = perdoruesFromGlobal.filter(
        (item) => item.HAPAUTOMATIKISHT === 0 || item.HAPAUTOMATIKISHT === 10
      );

      setPerdorues(filteredData);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="nav_supermarket flex">
      <Toast ref={toast} position="top-center" />
      <div className="operatori_name flex">
        <Button
          variant="contained"
          color="primary"
          startIcon={<People />}
          onClick={() => {
            setPopupOpen(true);
            fetchDataPerdorues();
          }}
          size="large"
        >
          {operator}
        </Button>
      </div>
      <div className="buttons_right">
        <IconButton
          className="btn_1_supermarket_nav"
          onClick={() => {
            toggleFullscreen();
          }}
          sx={{ ml: 2 }}
        >
          {!isFullscreen ? (
            <OpenInFullIcon
              sx={{
                width: 25,
                height: 25,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            />
          ) : (
            <CloseFullscreenIcon
              sx={{
                width: 25,
                height: 25,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
                marginRight: "5px",
              }}
            />
          )}
        </IconButton>

        <IconButton
          edge={false}
          color="inherit"
          onClick={handleOpenDialog}
          aria-label="close"
          sx={{
            width: 30,
            height: 30,
            color: "#000",
            backgroundColor: "#dbdbdbaf",
            borderRadius: "10%",
          }}
        >
          <CloseIcon />
        </IconButton>

        <ConfirmationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={(confirmed) => handleCloseDialog(confirmed)}
          userAutomatikisht={userAutomatikisht}
        />

        <LoginSupermarket
          isOpen={isPopupOpen}
          nipt={nipt}
          onClose={() => setPopupOpen(false)}
          setUserAutomatikisht={setUserAutomatikisht}
          perdorues={perdorues}
          showToast={showToast}
        />
      </div>
    </div>
  );
};

export default NavBarSuperMarket;
