// DynamicTableSkontrino80.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print80Skontrino.scss";
import QRCode from "qrcode.react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "N/A";
};

const DynamicTableSkontrino80 = ({ rows, state }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="columnsPershkrim">{info.Pershkrim}</td>
        <td className="columnsPershkrim">{info.Sasia} x</td>
        <td className="columnsPershkrim">{formatNumber(info.Cmimi)}</td>
        <td className="columnsVlera">{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  const sums = {
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
  };
  return (
    <table className="hide-border">
      <thead>
        <tr
          className="table-head"
          style={{
            textAlign: "center",
          }}
        >
          <th className="th">Artikull</th>
          <th className="th">Sasia</th>
          <th className="th">Cmimi</th>
          <th className="th">Vlera</th>
        </tr>
      </thead>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tbody>{DisplayData}</tbody>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tr>
        <td className="columnsTotal" colSpan={1}>
          <h2>Total</h2>
        </td>
        <td colSpan={2}></td>
        <td className="columnsTotal columnsvleracenter" colSpan={3}>
          <h2>{sums.Vlera}</h2>
        </td>
        <td colSpan={4}></td>
      </tr>

      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tfoot>
        <tr>
          <td colSpan={5} className="columnsFis">
            NSLF: {state?.NSLF}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="columnsFis">
            NIVF: {state?.NIVF}
          </td>
        </tr>

        <tr>
          <td
            colSpan={4}
            style={{
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Paper>
                <QRCode
                  style={{ width: 110, height: 110 }}
                  value={state.fisVerificationUrl}
                />
              </Paper>
            </Box>
          </td>
        </tr>

        <tr>
          <td colSpan={5} className="footer">
            Gjeneruar nga Parid Smart Solution
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicTableSkontrino80;
