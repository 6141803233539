import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./printSkontrinoXhiro.css";
import QRCode from "qrcode.react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "N/A";
};

const DynamicXhiroMain = ({ rows, state }) => {
  // QR code to fis URL
  const doubleClick = async (fisVerificationUrl) => {
    try {
      if (fisVerificationUrl === null) {
        return;
      } else {
        window.open(fisVerificationUrl, "_blank");
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="pershkrim">{info.Pershkrim}</td>
        <td className="columns">{info.Sasia} x</td>
        <td className="columns">{formatNumber(info.Cmimi)}</td>
        <td className="columns">{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  const sums = {
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
  };

  return (
    <div className="print_div">
      <table className="hide-border">
        <thead>
          <tr
            className="table-head"
            style={{
              textAlign: "center",
            }}
          >
            <th className="th">Artikull</th>
            <th className="th">Sasia</th>
            <th className="th">Cmimi</th>
            <th className="th">Vlera</th>
          </tr>
        </thead>
        <tbody>{DisplayData}</tbody>
        <tr>
          <td colSpan={5}>
            <hr className="dashed-line" />
          </td>
        </tr>
        <tr>
          <td className="columnsTotal" colSpan={1}>
            Total
          </td>
          <td className="columnsTotalVlera" colSpan={4}>
            {sums.Vlera}
          </td>
        </tr>

        <tr>
          <td colSpan={5}>
            <hr className="line" />
          </td>
        </tr>
        <tfoot>
          {state?.NIVF && (
            <>
              <tr className="columnsSkontrino">NSLF: {state?.NSLF}</tr>
              <tr className="columnsSkontrino">NIVF: {state?.NIVF}</tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    paddingBottom: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Paper
                      onClick={() => doubleClick(state.fisVerificationUrl)}
                    >
                      <QRCode
                        style={{ width: 100, height: 100 }}
                        value={state.fisVerificationUrl}
                      />
                    </Paper>
                  </Box>
                </td>
              </tr>
            </>
          )}
        </tfoot>
      </table>
      <div className="footer_text">
        <h2>Gjeneruar nga Parid Smart Solution</h2>
      </div>
    </div>
  );
};

export default DynamicXhiroMain;
