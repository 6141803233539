import React from "react";
import Barcode from "react-barcode";

function SkontrinoBarcode({ barcodeNmr }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {barcodeNmr ? (
        <Barcode value={barcodeNmr} />
      ) : (
        <div className="flex flex-col items-center">
          <span>Ky artikull,</span>
          <span>nuk ka barkod</span>
        </div>
      )}
    </div>
  );
}

export default SkontrinoBarcode;
