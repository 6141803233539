import axios from "axios";
import { getCookie } from "./helpers";
import { useNavigate } from "react-router-dom";

// Set default content type for all POST requests
axios.defaults.headers.post["Content-Type"] = "application/json";

// Create a new Axios instance with the main configuration
export const mainAxios = new axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

// Function to set the authentication token in the headers
export const setAuthToken = (token) => {
  if (token) {
    // Applying the token to the headers
    mainAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    // Deleting the token from the headers
    delete mainAxios.defaults.headers.common["Authorization"];
  }
};

// Axios Interceptor component to handle request and response
const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  // Request interceptor to add the authentication token to the headers
  mainAxios.interceptors.request.use(
    (request) => {
      const token = getCookie("access_token");
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor to handle errors and redirects
  mainAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 422)
      ) {
        // Expire the cookie "access_token"
        document.cookie =
          "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // Redirect to the / route on 401 Unauthorized
        navigate("/");
        return Promise.resolve(error.response);
      }
      return Promise.reject(error);
    }
  );

  return children;
};

export default mainAxios; // Exporting the main Axios instance
export { AxiosInterceptor }; // Exporting the Axios Interceptor component
