import React, { useRef } from "react";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";
import InfoIcon from "@mui/icons-material/Info";
import SkontrinoBarcode from "./ReactPDF/SkontrinoBarcode";

const PrintoBarcode = ({ rowData }) => {
  const componentsRef2 = useRef(null);

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  let barcodeNmr = "";
  if (rowData && rowData.Barkod) {
    barcodeNmr = rowData.Barkod.toString().padStart(12, "0");
  } else if (rowData && rowData.BarKod) {
    barcodeNmr = rowData.BarKod.toString().padStart(12, "0");
  }
  return (
    <>
      <InfoIcon
        onClick={() => {
          handlePrint2(rowData);
        }}
      />

      <div ref={componentsRef2} className="print">
        <SkontrinoBarcode barcodeNmr={barcodeNmr} />
      </div>
    </>
  );
};

export default PrintoBarcode;
