import React, { useContext, useRef } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import mainAxios from "../../../../../services/axios";
import { Toast } from "primereact/toast";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";
import { getPosByOperator } from "../../../../../store/globalPos";
import { useDispatch } from "react-redux";
import { useUser } from "../../../../../zustand/common";

const ShtimButton = ({ state }) => {
  const { user } = useUser();
  const operator = user.username;
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const toastRef = useRef(null);
  const dispatch = useDispatch();

  // Get the date part of the current date
  const currentDate = new Date().toISOString().split("T")[0];

  // Get formatted date and time
  const formattedDateTime = new Date().toISOString();

  // Post request data
  const postData = {
    Nipt: String(nipt),
    Aktiv: Number(1),
    Hapur: formattedDateTime,
    Data: `${currentDate}T00:00:00`,
    E_Paguar: Number(state.E_Paguar),
    Operator: String(operator),
    Tavolina: null,
    Tip_Cmimi: state.Tip_Cmimi,
    Tipi: state.Tipi,
    KLFU_Pershkrim: "",
    TipPrintimi: -1,
    Menyra_Pageses_Id: 6,
    Updated: formattedDateTime,
  };

  const handleShtimClick = async () => {
    showLoading();

    try {
      const response = await mainAxios.post("/pos", postData, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        dispatch(getPosByOperator(`/pos/filter?operator=${operator}`));
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      <Button
        variant="contained"
        className="btn_shtim_supermarket"
        onClick={handleShtimClick}
        style={{ backgroundColor: "#0C2D57", justifyContent: "space-between" }}
      >
        <AddIcon />
        Shtim
      </Button>
    </>
  );
};

export default ShtimButton;
