// PopUpConfirm.js
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Dialog, Button } from "@mui/material";
import "../../dynamicComponent/ConfirmationDialog.scss";

const PopUpConfirm = ({ onConfirm, onCancel }) => {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{ style: { padding: "20px" } }}
    >
      <div style={{ alignSelf: "center" }}>
        <ErrorOutlineIcon
          className="animate-bounce mt-3"
          style={{ color: "#1971c2" }}
        />
      </div>

      <h2>Anullo ndryshimet ne dokument!</h2>

      <div className="button_modifikim_confirm">
        <Button
          onTouchStart={onConfirm}
          onClick={onConfirm}
          variant="contained"
        >
          Po
        </Button>
        <Button onTouchStart={onCancel} onClick={onCancel} variant="contained">
          Jo
        </Button>
      </div>
    </Dialog>
  );
};

export default PopUpConfirm;
