import React from "react";
import Transportuesi from "../../minimodal/TransportShitje";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const TabTrans = ({
  contextKey,
  toggleState,
  disabled,
  state,
  setState,
  handleChange,
  formatDate,
  includeShenim,
}) => {
  // redux transportuesi (get requesti i Transportit)
  const transportiRedux = useSelector(
    (state) => state.globalSlice.transportuesi
  );
  const transportuesi = transportiRedux.map((item) => item.Pershkrim);

  const numTrans = transportuesi.length;

  const TransportType = async (selectedValue) => {
    try {
      const selectedTransport =
        transportiRedux.find((item) => item.Pershkrim === selectedValue) || {};

      if (selectedTransport) {
        setState((prevState) => ({
          ...prevState,
          Transportuesi_Nipt: selectedTransport.Nipt,
          Targa_e_Mjetit: selectedTransport.Targa_e_Mjetit,
          Transportuesi_Adresa: selectedTransport.Adresa,
          Transportuesi_Pershkrim: selectedTransport.Pershkrim,
          Transport_Shenim: selectedTransport.Shenim,
          Transport_Data: selectedTransport.Data,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // if we dont have value on textField transportuesi
  const handleTransportTypeChange = (event, newValue) => {
    if (newValue) {
      TransportType(newValue);
    }
    if (!newValue) {
      setState((prevState) => ({
        ...prevState,
        Transportuesi_Nipt: null,
        Targa_e_Mjetit: null,
        Transportuesi_Adresa: null,
        Transportuesi_Pershkrim: null,
        Transport_Shenim: null,
        Transport_Data: null,
      }));
      return;
    }
  };

  return (
    <div className={toggleState ? "content active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2" style={{ height: "125px" }}>
        <div className="rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-Transportuesi_Pershkrim"
            options={transportuesi}
            value={state?.Transportuesi_Pershkrim || null}
            sx={{ width: "100%" }}
            onChange={(event, newValue) => {
              handleTransportTypeChange(event, newValue);
            }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Transportuesi_Pershkrim" />
            )}
          />
          <div className="  hover:scale-110 transition-all">
            <Transportuesi numTrans={numTrans} />
          </div>
        </div>

        <TextField
          disabled={disabled}
          type="text"
          onChange={(e) => handleChange("Transportuesi_Nipt", e.target.value)}
          label="Transportuesi_Nipt"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Transportuesi_Nipt || ""}
        />

        <TextField
          disabled={disabled}
          type="text"
          onChange={(e) => handleChange("Targa_e_Mjetit", e.target.value)}
          label="Targa_e_Mjetit"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Targa_e_Mjetit || ""}
        />

        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Transport Data"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Transport_Data)}
          onChange={(e) => handleChange("Transport_Data", e.target.value)}
          size="small"
        />
        {includeShenim && (
          <textarea
            //  fullWidth
            disabled={disabled}
            cols="38"
            rows="3"
            value={state?.Transport_Shenim || ""}
            onChange={(e) => handleChange("Transport_Shenim", e.target.value)}
            placeholder="Transport_Shenim"
            className="shenim resize-none rounded-md bg-white"
          ></textarea>
        )}
      </div>
    </div>
  );
};

export default TabTrans;
