// Function to add an item to the DataTable
const shtoArtikull = (artikull, rows, setRows, setTipiDTL, state) => {
  if (!artikull) return;

  setTipiDTL(artikull.Tipi);

  const newItem = {
    ...artikull,
    Sasia: 1,
    Tvsh: 20,
    Cmimi: artikull.Cmimi || 0,
    BarKod: artikull.Barkod,
    Skonto: 0,
    Cmimi_me_skonto: 0,
    Cmimi_Pa_Tvsh: 0,
    Vlera: 0,
    Tvsh_Vlera: 0,
    Skonto_Vlera: 0,
    Vlera_Pa_Tvsh: 0,
    Total: (artikull.Cmimi ? artikull.Cmimi : 0) * state.Kursi,
  };

  // Calculate the new item data
  const calculatedItem = calculateData(
    { Cmimi: newItem.Cmimi },
    newItem,
    state
  );

  setRows((rows) => [calculatedItem, ...rows]);
};

// Function to calculate data based on provided field
const calculateData = (field, item, state) => {
  let {
    Tvsh,
    Cmimi_Pa_Tvsh,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Vlera_Pa_Tvsh,
    Cmimi_me_skonto,
    Vlera,
    Total,
  } = item;

  let tvshRate = state.Eksport === 1 ? 1 : (Tvsh + 100) / 100;
  let skontoValue, priceExcludingTax, quantity;

  const [[key, value]] = Object.entries(field);

  try {
    let basePrice = Number(key === "Cmimi" ? value : Cmimi);
    let discount = Number(key === "Skonto" ? value : Skonto);
    quantity = Number(key === "Sasia" ? value : Sasia);
    priceExcludingTax = basePrice / tvshRate;
    skontoValue = (quantity * priceExcludingTax * discount) / 100;

    switch (key) {
      case "Skonto":
        Skonto = value;
        break;
      case "Cmimi":
        Cmimi = value;
        break;
      case "Sasia":
        Sasia = value;
        break;
      case "Vlera":
        Vlera = value;
        Skonto = 0;
        skontoValue = 0;
        Cmimi = Vlera / Math.abs(quantity);
        priceExcludingTax = Cmimi / tvshRate;
        break;
      case "Skonto_Vlera":
        Skonto_Vlera = value;
        skontoValue = Skonto_Vlera;
        Skonto = (skontoValue * 100) / (quantity * priceExcludingTax);
        break;
      case "Cmimi_Pa_Tvsh":
        Cmimi_Pa_Tvsh = value;
        tvshRate = Tvsh / 100;
        skontoValue = (quantity * Cmimi_Pa_Tvsh * Skonto) / 100;
        Vlera_Pa_Tvsh = Cmimi_Pa_Tvsh * quantity;
        Vlera = (Vlera_Pa_Tvsh - skontoValue) * (1 + tvshRate);
        Cmimi = Vlera / Math.abs(quantity);
        break;
      case "Tvsh":
        Tvsh = value;
        tvshRate = Tvsh / 100;
        skontoValue = (quantity * Cmimi_Pa_Tvsh * Skonto) / 100;
        Cmimi =
          (Cmimi_Pa_Tvsh - skontoValue / Math.abs(quantity)) * (1 + tvshRate);
        break;
      default:
        break;
    }

    Vlera = (quantity * priceExcludingTax - skontoValue) * tvshRate;
    Vlera_Pa_Tvsh = quantity * priceExcludingTax - skontoValue;
    Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
    Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    Total = Vlera * state.Kursi;

    // Round values to 1 decimal place
    return {
      ...item,
      Tvsh,
      Cmimi_Pa_Tvsh: parseFloat(Cmimi_Pa_Tvsh.toFixed(1)),
      Cmimi_me_skonto: parseFloat(Cmimi_me_skonto.toFixed(1)),
      Sasia: quantity,
      Skonto,
      Cmimi: parseFloat(Cmimi.toFixed(1)),
      Tvsh_Vlera: parseFloat(Tvsh_Vlera.toFixed(1)),
      Skonto_Vlera: parseFloat(Skonto_Vlera.toFixed(1)),
      Vlera_Pa_Tvsh: parseFloat(Vlera_Pa_Tvsh.toFixed(1)),
      Vlera: parseFloat(Vlera.toFixed(1)),
      Total: parseFloat(Total.toFixed(1)),
    };
  } catch (error) {
    console.error("Calculation error: ", error);
    return item;
  }
};

export { shtoArtikull, calculateData };

// // Version i 1
// // Shtimi i nje Artikulli nga lista ne DataTable
// const shtoArtikull = (artikull, rows, setRows, setTipiDTL, state) => {
//   if (!artikull) return;
//   // let currObj = rows.find((row) => row.Id === artikull?.Id);
//   // if (currObj) {
//   //   return;
//   // }
//   setTipiDTL(artikull.Tipi);
//   // Calculate the initial item
//   const newItem = {
//     ...artikull,
//     Sasia: 1,
//     Tvsh: 20,
//     Cmimi: artikull.Cmimi ? artikull.Cmimi : 0,
//     BarKod: artikull.Barkod,
//     Skonto: 0,
//     Cmimi_me_skonto: 0,
//     Cmimi_Pa_Tvsh: 0,
//     Vlera: 0,
//     Tvsh_Vlera: 0,
//     Skonto_Vlera: 0,
//     Vlera_Pa_Tvsh: 0,
//     Total: (artikull.Cmimi ? artikull.Cmimi : 0) * state.Kursi,
//   };

//   // Calculate the new item data
//   const calculatedItem = calculateData(
//     { Cmimi: newItem.Cmimi },
//     newItem,
//     state
//   );

//   setRows((rows) => [calculatedItem, ...rows]);
// };

// const calculateData = (field, item, state) => {
//   let {
//     Tvsh,
//     Cmimi_Pa_Tvsh,
//     Sasia,
//     Skonto,
//     Cmimi,
//     Tvsh_Vlera,
//     Skonto_Vlera,
//     Vlera_Pa_Tvsh,
//     Cmimi_me_skonto,
//     Vlera,
//     Total,
//   } = item;

//   let tvsh, VLSKONTO, cm_paTVSH, sasia;

//   if (state.Eksport === 1) {
//     Tvsh = 0;
//     tvsh = 1;
//   } else {
//     tvsh = (Tvsh + 100) / 100;
//   }

//   const [[cell, cellVal]] = Object.entries(field);
//   try {
//     let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
//     let SkontoPrv = Number(cell === "Skonto" ? cellVal : Skonto);
//     let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);
//     cm_paTVSH = CmimiPrv / tvsh;
//     sasia = SasiaPrv;
//     VLSKONTO = (sasia * cm_paTVSH * SkontoPrv) / 100;

//     if (cell === "Skonto") {
//       Skonto = cellVal;
//       Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
//       Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
//       Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
//       Cmimi_Pa_Tvsh = cm_paTVSH;
//       Skonto_Vlera = VLSKONTO;
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//     }
//     if (cell === "Cmimi") {
//       Cmimi = cellVal;
//       Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
//       Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
//       Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
//       Cmimi_Pa_Tvsh = cm_paTVSH;
//       Cmimi_me_skonto = CmimiPrv * (1 - Skonto / 100);
//       Skonto_Vlera = VLSKONTO;
//     }
//     if (cell === "Sasia") {
//       Sasia = cellVal;
//       Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
//       Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
//       Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
//       Cmimi_Pa_Tvsh = cm_paTVSH;
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//       Skonto_Vlera = VLSKONTO;
//     }
//     if (cell === "Vlera") {
//       Vlera = cellVal;
//       Skonto = 0;
//       Skonto_Vlera = 0;
//       Cmimi = Vlera / Math.abs(sasia);
//       cm_paTVSH = Cmimi / tvsh;
//       VLSKONTO = 0;
//       Tvsh_Vlera = Vlera - Vlera / tvsh;
//       Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
//       Cmimi_Pa_Tvsh = cm_paTVSH;
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//     }
//     if (cell === "Skonto_Vlera") {
//       Skonto_Vlera = cellVal;
//       VLSKONTO = Skonto_Vlera;
//       Skonto = (VLSKONTO * 100) / (sasia * cm_paTVSH);
//       Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
//       Vlera = Vlera_Pa_Tvsh * tvsh;
//       Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//     }
//     if (cell === "Cmimi_Pa_Tvsh") {
//       Cmimi_Pa_Tvsh = cellVal;
//       tvsh = Tvsh / 100;
//       VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
//       Vlera_Pa_Tvsh = Cmimi_Pa_Tvsh * Sasia;
//       Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * (1 + tvsh);
//       if (sasia !== 0) {
//         Cmimi = Math.abs(Vlera / Sasia);
//       }
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//       Skonto_Vlera = VLSKONTO;
//       Tvsh_Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * tvsh;
//     }
//     if (cell === "Tvsh") {
//       Tvsh = cellVal;
//       tvsh = Tvsh / 100;
//       VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
//       Cmimi = (Cmimi_Pa_Tvsh - VLSKONTO / Math.abs(Sasia)) * (1 + tvsh);
//       Vlera = Sasia * Cmimi;
//       Tvsh_Vlera = Vlera_Pa_Tvsh * tvsh;
//       Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
//     }
//     Total = Vlera * state.Kursi;

//     // Round values to 1 decimal place
//     Vlera = parseFloat(Vlera.toFixed(1));
//     Cmimi = parseFloat(Cmimi.toFixed(1));
//     Total = parseFloat(Total.toFixed(1));
//     Tvsh_Vlera = parseFloat(Tvsh_Vlera.toFixed(1));
//     Skonto_Vlera = parseFloat(Skonto_Vlera.toFixed(1));
//     Vlera_Pa_Tvsh = parseFloat(Vlera_Pa_Tvsh.toFixed(1));
//     Cmimi_Pa_Tvsh = parseFloat(Cmimi_Pa_Tvsh.toFixed(1));
//     Cmimi_me_skonto = parseFloat(Cmimi_me_skonto.toFixed(1));

//     return {
//       ...item,
//       Tvsh,
//       Cmimi_Pa_Tvsh,
//       Cmimi_me_skonto,
//       Sasia,
//       Skonto,
//       Cmimi,
//       Tvsh_Vlera,
//       Skonto_Vlera,
//       Vlera_Pa_Tvsh,
//       Vlera,
//       Total,
//     };
//   } catch (error) {
//     Vlera = 0;
//     return item;
//   }
// };
// export { shtoArtikull, calculateData };
