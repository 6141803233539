import React from "react";
import mainAxios from "../../../../../services/axios";

const HandleInputChange = ({
  item,
  showLoading,
  clickedItems,
  hideLoading,
  setClickedItems,
  index,
}) => {
  const handleInputChange = async (index, newValue) => {
    try {
      showLoading();
      const updatedItems = [...clickedItems];
      // updatedItems[index].Sasia = newValue || 1; // Set to 1 if null or 0
      updatedItems[index].Sasia =
        isNaN(newValue) || newValue === null ? 1 : newValue;

      setClickedItems(updatedItems);

      const newItemFields = {
        Sasia: newValue.toFixed(2),
        Vlera: updatedItems[index].Vlera.toFixed(2),
        Vlera_Pa_Tvsh: updatedItems[index].Vlera_Pa_Tvsh.toFixed(2),
        Cmimi: updatedItems[index].Cmimi.toFixed(2),
        Tvsh_Vlera: updatedItems[index].Tvsh_Vlera.toFixed(2),
      };

      // Send a POST request to update the item
      await mainAxios.put(`/posdtl/${updatedItems[index].Id}`, newItemFields);
    } catch (error) {
      console.error("Error handling item:", error);
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="number_leftbar">
      <input
        type="number"
        value={
          Number.isInteger(item.Sasia) ? item.Sasia : item.Sasia.toFixed(2)
        }
        onChange={(e) => handleInputChange(index, parseFloat(e.target.value))}
        style={{
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
        }}
      />
    </div>
  );
};

export default HandleInputChange;
