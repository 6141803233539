import React, { useRef } from "react";
import { Button } from "@mui/material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

import DynamicFisSupermarket from "./DynamicFisSupermarket";
import SkontrinoFisSupermarket from "./SkontrinoFisSupermarket";

const PrintoFisSupermarket = ({
  rows,
  state,
  selectedUserData,
  posSupermarketListaFaturave,
  nipt,
  selectedId,
  showToast,
  handleFiskalizimi,
  menyra,
  konfigurimeRedux,
  operator,
}) => {
  const componentsRef2 = useRef(null);
  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  let fromLista = posSupermarketListaFaturave;

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {() => (
          <>
            {posSupermarketListaFaturave ? (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (!selectedId) {
                    showToast("Nuk ka fature te selektuar.");
                    return;
                  } else if (!state.NIVF) {
                    handleFiskalizimi(handlePrint2, fromLista);
                  } else if (state.NIVF) {
                    handlePrint2();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  marginRight: "5px",
                  backgroundColor: "green",
                }}
              >
                <PointOfSaleIcon />
                Fiskal Riprint
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rows.length === 0) {
                    showToast("Nuk ka artikuj.");
                    return;
                  }
                  if (!selectedId) {
                    showToast("Nuk ka fature te selektuar.");
                    return;
                  }
                  if (!state.NIVF) {
                    handleFiskalizimi(handlePrint2);
                  } else if (state.NIVF) {
                    handlePrint2();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "green",
                }}
              >
                <PointOfSaleIcon />
                FISKALIZO
              </Button>
            )}
          </>
        )}
      </PopupState>

      <div ref={componentsRef2} className="print">
        <SkontrinoFisSupermarket
          konfigurimeRedux={konfigurimeRedux}
          selectedUserData={selectedUserData}
          state={state}
          posSupermarketListaFaturave={posSupermarketListaFaturave}
          nipt={nipt}
          menyra={menyra}
          operator={operator}
        />

        <DynamicFisSupermarket rows={rows} state={state} />
      </div>
    </>
  );
};

export default PrintoFisSupermarket;
