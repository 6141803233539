import React from "react";
import "../../ReactPDF/print.scss";

const TotalFooterFH = () => {
  return (
    <div>
      <div className="flex justify-between totalFooter pt-20">
        {/* Dorezuesi */}
        <div className="flex flex-col">
          <p>(emër mbiemër firme)</p>
          <p
            style={{
              width: "140px",
              marginBottom: "10px",
            }}
            className="flex justify-center"
          >
            DOREZOI
          </p>
          <hr className="line-hr" />
        </div>

        <div className="flex flex-col">
          {/* Pranuesi */}
          <p>(emër mbiemër firme)</p>
          <p
            style={{
              width: "140px",
              marginBottom: "10px",
            }}
            className="flex justify-center"
          >
            PRANOI
          </p>
          <hr className="line-hr" />
        </div>
      </div>

      {/* Additional content can be added here */}
    </div>
  );
};

export default TotalFooterFH;
