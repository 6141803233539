import React from "react";
import TableListaFaturave from "../../POS/Restaurant/DataTablePos/TableListaFaturave";
// import TableSecondListaFaturave from "../../POS/Restaurant/DataTablePos/TableSecondListaFaturave";

function DataTable({
  data,
  setSelectedId,
  setFaturaNIVF,
  setFaturaData,
  selectedOperator,
  // data2,
  setData2,
  setState,
  loading,
  setDataCheck,
  // setData,
  selectedId,
  state,
}) {
  const tableContainerStyle = {
    display: "flex",
    marginTop: "5px",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  };

  const tableStyle = {
    height: "98%",
    // overflow: "hidden",
    // flex: "50%",
  };

  // const tableStyle2 = {
  //   overflow: "hidden",
  //   flex: "50%",
  // };

  return (
    <div style={tableContainerStyle}>
      <div style={tableStyle}>
        <TableListaFaturave
          rows={data}
          setData2={setData2}
          loading={loading}
          setSelectedId={setSelectedId}
          setDataCheck={setDataCheck}
          setFaturaNIVF={setFaturaNIVF}
          setFaturaData={setFaturaData}
          operator={selectedOperator}
          setState={setState}
          state={state}
          selectedId={selectedId}
        />
      </div>
      {/* <div style={tableStyle2}>
        <TableSecondListaFaturave rows={data2} loading={loading} />
      </div> */}
    </div>
  );
}

export default DataTable;
