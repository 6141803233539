import React from "react";
import mainAxios from "../../../../../services/axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const HandleItemClick = ({
  showLoading,
  hideLoading,
  showToast,
  setClickedItems,
  clickedItems,
  item,
}) => {
  const handleItemClick = async (index, operation) => {
    try {
      showLoading();
      const existingItemIndex = clickedItems.findIndex(
        (clickedItem) =>
          clickedItem.Pershkrim === index.Pershkrim &&
          clickedItem.Kodi === index.Kodi
      );

      if (existingItemIndex !== -1) {
        const updatedItems = [...clickedItems];
        let tvsh = (updatedItems[existingItemIndex].Tvsh + 100) / 100;
        updatedItems[existingItemIndex].Skonto_Vlera =
          (updatedItems[existingItemIndex].Sasia *
            (updatedItems[existingItemIndex].Cmimi / tvsh) *
            updatedItems[existingItemIndex].Skonto) /
          100;
        updatedItems[existingItemIndex].Sasia += operation;
        updatedItems[existingItemIndex].Vlera =
          updatedItems[existingItemIndex].Sasia *
          updatedItems[existingItemIndex].Cmimi;
        updatedItems[existingItemIndex].Vlera_Pa_Tvsh =
          updatedItems[existingItemIndex].Sasia *
            (updatedItems[existingItemIndex].Cmimi / tvsh) -
          updatedItems[existingItemIndex].Skonto_Vlera;
        updatedItems[existingItemIndex].Tvsh_Vlera =
          updatedItems[existingItemIndex].Vlera -
          updatedItems[existingItemIndex].Vlera_Pa_Tvsh;

        const newItemFields = {
          Sasia: updatedItems[existingItemIndex].Sasia.toFixed(2),
          Vlera: updatedItems[existingItemIndex].Vlera.toFixed(2),
          Vlera_Pa_Tvsh:
            updatedItems[existingItemIndex].Vlera_Pa_Tvsh.toFixed(2),
          Cmimi: updatedItems[existingItemIndex].Cmimi.toFixed(2),
          Tvsh_Vlera: updatedItems[existingItemIndex].Tvsh_Vlera.toFixed(2),
        };

        if (operation === 0) {
          // Delete operation
          await mainAxios.delete(`/posdtl/${index.Id}`);
          updatedItems.splice(existingItemIndex, 1);
          setClickedItems(updatedItems);
        } else {
          // Update operation with delay
          setTimeout(async () => {
            try {
              const response = await mainAxios.put(
                `/posdtl/${index.Id}`,
                newItemFields
              );
              if (response.status === 200) {
                setClickedItems(updatedItems);
              } else {
                showToast("Problem Gjat Modifikimit.");
              }
            } catch (error) {
              console.error("Error updating item:", error);
              showToast("Problem Gjat Modifikimit.");
            }
          }, 100); // 1 second delay
        }
      }
    } catch (error) {
      console.error("Error handling item:", error);
      showToast("Problem gjate fshirjes");
    } finally {
      hideLoading();
    }
  };

  return (
    <div direction="row" className="stack_Buttons">
      <button onClick={() => handleItemClick(item, 1)}>
        <AddCircleOutlineIcon sx={{ color: "#000" }} />
      </button>

      <button
        sx={{
          backgroundColor: item.Sasia === 1 ? "#fff" : "#dbdbdbaf",
        }}
        onClick={() => handleItemClick(item, -1)}
        disabled={item.Sasia === 1}
      >
        <RemoveCircleOutlineIcon
          sx={{ color: item.Sasia === 1 ? "gray" : "#000" }}
        />
      </button>

      <button onClick={() => handleItemClick(item, 0)}>
        <HighlightOffIcon sx={{ color: "#E14D2A" }} />
      </button>
    </div>
  );
};

export default HandleItemClick;
