// Supermarket Lista e Faturave

import React, { useRef, useState, useContext } from "react";
import { Button } from "@mui/material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";
import Xhiro from "./xhiro80";
import DynamicXhiro from "./dynamicXhiro";
import mainAxios from "../../services/axios";
import FunctionsIcon from "@mui/icons-material/Functions";
import FooterXhiro from "./footerXhiro";
import { LoadingOverlayContext } from "../LoadingOverlay";

const PrintoTurnin = ({ state, operator, dataNga, dataDeri }) => {
  const [dataPrintAll, setDataPrintAll] = useState([]);

  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const componentsRef = useRef(null);

  // Use the useReactToPrint hook to handle printing
  const handlePrint2 = useReactToPrint({
    content: () => componentsRef.current,
  });

  const printXhiro = async () => {
    try {
      showLoading();
      const responseAll = await mainAxios(
        `/posdtl/datafilter/${dataNga}/${dataDeri}/${operator}`
      );

      if (responseAll.status === 200) {
        setDataPrintAll(responseAll.data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      handlePrint2();
      hideLoading();
    }
  };

  // Extract unique DATA values from the response
  const uniqueDataValues = [...new Set(dataPrintAll.map((item) => item.DATA))];

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button
              disabled={!operator}
              variant="contained"
              startIcon={<FunctionsIcon />}
              onClick={printXhiro}
              style={{
                justifyContent: "space-between",
                margin: "5px",
              }}
            >
              Printo Xhiro
            </Button>

            {/* <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            ></Popover> */}
          </>
        )}
      </PopupState>

      <div ref={componentsRef} className="print">
        <Xhiro selectedOperator={operator} />

        {/* Check if there are no data rows for the specific operator */}
        {dataPrintAll.length === 0 && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>{`Xhiro Per Userin ${operator} Nuk Ka Te Dhëna`}</h2>
          </div>
        )}

        {/* Render DynamicXhiro for each unique DATA value */}
        {uniqueDataValues.map((dataValue, index) => {
          const formattedDataValue = dataValue.split(" ")[0];
          // Filter rows by DATA and OPERATOR
          const filteredRows = dataPrintAll.filter(
            (item) =>
              item.DATA === dataValue &&
              item.OPERATOR.toLowerCase() === operator.toLowerCase()
          );
          // Render DynamicXhiro only if there are matching rows
          if (filteredRows.length > 0) {
            return (
              <DynamicXhiro
                key={index}
                rows={filteredRows}
                state={state}
                selectedOperator={operator}
                dataNga={formattedDataValue}
              />
            );
          }
          // If there are no matching rows, return null
          return null;
        })}

        <FooterXhiro />
      </div>
    </>
  );
};

export default PrintoTurnin;
