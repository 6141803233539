import Draggable from "react-draggable";

const DivComponent = ({ div, handleSelectDiv, customizeDiv, enable }) => {
  return (
    <Draggable
      key={div.id}
      disabled={!enable}
      defaultPosition={{ x: div.x, y: div.y }}
    >
      <div
        style={{
          width: div.width,
          height: div.height,
          backgroundColor: div.color,
        }}
        onClick={() => {
          if (enable) {
            handleSelectDiv(div);
          }
        }}
      >
        {enable && (
          <button className="customize-button" onClick={customizeDiv}>
            Customize
          </button>
        )}
      </div>
    </Draggable>
  );
};

export default DivComponent;
