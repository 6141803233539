import React, { useState, useRef, useEffect, useContext } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import SmallButtonGroup from "../SmallButton";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "primereact/dialog";
import { Checkbox, TextField } from "@mui/material";
import mainAxios from "../../../services/axios";
import "./Klienti.scss";
import { useDispatch } from "react-redux";
import { getMagazina } from "../../../store/globalSlice";
import { useUser } from "../../../zustand/common";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";

const Magazina = ({ magazina }) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const [lastKodi, setLastKodi] = useState("");
  const [currentAction, setCurrentAction] = useState("");

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (magazina.length > 0) {
          const lastKodi = magazina[magazina.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("M01");
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [magazina]);

  const rekorde = `${magazina.length} Rekorde`;
  const defaultState = {
    Kodi: `${lastKodi}`,
    Pershkrim: "",
    Qyteti_Kodi: "",
    Aktiv: 1,
  };
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toast = useRef(null);
  const dispatch = useDispatch();

  const onAddClick = () => {
    setCurrentAction("add");
  };

  const putMethod = () => {
    setCurrentAction("modify");
  };

  const submitHanlder = async () => {
    try {
      showLoading();
      if (currentAction === "add") {
        const exists = magazina.some((item) => item.Kodi === state.Kodi);
        if (exists) {
          toast.current.show({
            severity: "warn",
            summary: "Kodi është i njëjtë",
            detail: "Ju lutem vendosni një Kod të ndryshëm.",
          });
          return;
        }

        if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
          toast.current.show({
            severity: "error",
            summary:
              "Plotesoni fushat e kerkuara: " +
              (!state.Kodi.trim() ? "Kodi, " : "") +
              (!state.Pershkrim.trim() ? "Pershkrim " : ""),
          });
          return;
        }
        try {
          const responseMag = await mainAxios.post(`/magazina`, {
            Pershkrim: state.Pershkrim,
            Kodi: state.Kodi,
            Qyteti_Kodi: state.Qyteti_Kodi,
            Aktiv: state.Aktiv,
          });

          if (responseMag.status === 200) {
            setState(defaultState);
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getMagazina("/magazina"));

            toast.current.show({
              severity: "success",
              summary: "Magazina u regjistrua.",
            });
          }
        } catch (error) {
          console.error("Error Magazina: ", error);
          toast.current.show({
            severity: "error",
            summary: "Problem gjatë regjistrimit.",
          });
        }
      }
      if (currentAction === "modify") {
        if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
          toast.current.show({
            severity: "error",
            summary:
              "Plotesoni fushat e kerkuara: " +
              (!state.Kodi.trim() ? "Kodi, " : "") +
              (!state.Pershkrim.trim() ? "Pershkrim " : ""),
          });
          return;
        }
        try {
          const response = await mainAxios.put(`/magazina/${selectedRowId}`, {
            Pershkrim: state.Pershkrim,
            Kodi: state.Kodi,
            Qyteti_Kodi: state.Qyteti_Kodi,
            Aktiv: state.Aktiv,
          });

          if (response.status === 200) {
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getMagazina("/magazina"));

            toast.current.show({
              severity: "success",
              summary: "Magazina u regjistrua.",
            });
          }
        } catch (error) {
          console.error("Error Magazina: ", error);

          toast.current.show({
            severity: "error",
            summary: "Problem gjatë regjistrimit.",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Ndodhi problem.",
      });
    } finally {
      hideLoading();
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=MAGAZINA&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });

        return;
      } else {
        const resDelete = await mainAxios.delete(`/magazina/${selectedRowId}`);
        if (resDelete.status === 200) {
          setSelectedRowId(null);

          toast.current.show({
            severity: "info",
            summary: "U fshi.",
          });
          dispatch(getMagazina("/magazina"));
        }
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Qyteti_Kodi", title: "Qyteti" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      Qyteti_Kodi: rowData.Qyteti_Kodi,
      Aktiv: rowData.Aktiv,
    });
    setSelectedRowId(rowData.Id);
  };

  const textEditor = (options, col) => {
    return check ? (
      <InputText
        disabled={disabled}
        className="cursor-pointer inp"
        type={col?.fieldType ?? "text"}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    ) : (
      <InputText
        disabled={() => setDisabled(true)}
        className="cursor-pointer inp"
        type={col?.fieldType ?? "text"}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  //  useState i modaleve brenda kategorive ne fletblerje
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <AddIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={() => onClick("displayResponsive")}
        >
          Open
        </AddIcon>

        <Dialog
          header=" Magazine"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              setSelectedRowId={setSelectedRowId}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              putMethod={putMethod}
              onAddClick={onAddClick}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={state?.Kodi}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            />
            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />
            {/* <div className="flex justify-between">
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Qyteti"
                size="small"
                value={state?.Qyteti_Kodi}
                onChange={(e) => handleChange("Qyteti_Kodi", e.target.value)}
                className="mt-2 w-7/12"
              />
              <div className="flex items-center">
                <Checkbox
                  disabled={disabled}
                  checked={state.Aktiv === 1}
                  onChange={(e) => handleChangeAktiv("Aktiv", e.target.checked)}
                />

                <label
                  label
                  style={{ marginLeft: "5px", fontWeight: 500 }}
                  htmlFor="cb2"
                  className="p-checkbox-label"
                >
                  Aktiv
                </label>
              </div>
            </div> */}
            <div className="border p-2 flex flex-col mt-2">
              <DataTable
                className="datatable-cell"
                editMode="cell"
                selectionMode="single"
                selection={selectedProduct1}
                onSelectionChange={() => setSelectedProduct1(defaultState)}
                size="small"
                scrollHeight="10rem"
                value={magazina}
                responsiveLayout="scroll"
                contextMenuSelection={selectedProduct}
                contextselection={selectedProduct}
                onContextMenuSelectionChange={(e) =>
                  setSelectedProduct(defaultState)
                }
                onContextMenu={(e) => cm.current.show(e.originalEvent)}
                onRowClick={(e) => handleRowClick(e.data)}
              >
                {dynamicColumns}
              </DataTable>
            </div>
            <Toast ref={toast} />
          </div>
          <span>
            <span>{rekorde}</span>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default Magazina;
