import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import mainAxios from "../../services/axios";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { createSelector } from "reselect";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";

const ArkaButtons = [
  {
    key: "3-0",
    label: "Arka (TCRCode)",
    type: "input",
    value: "",
    reduxGet: "arkaList",
  },
  {
    key: "2-9",
    label: "Veprimi",
    type: "input",
    value: "",
    reduxGet: "oper",
  },
  {
    key: "2-8",
    label: "Vlera",
    type: "input",
    value: "",
  },
];
const ArkaDialog = ({ visible, onHide, content, label, raportButtonsKeys }) => {
  const [selectedButtonKey, setSelectedButtonKey] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState({});
  const [isTCRCodeVisible, setIsTCRCodeVisible] = useState(true);
  const toast = useRef(null);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      position: "top-center",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };

  const handleSubmit = async () => {
    try {
      // Log each selectedValue dynamically
      // Object.keys(selectedValues).forEach((key) => {
      //   console.log(`"KEY: ${key}:`, "Selectedvalue", selectedValues[key]);
      // });

      // Extracting values from selectedValues
      const {
        "3-0": arkaValue,
        "2-9": veprimiValue,
        "2-8": vleraValue,
        // "2-7": shenimValue,
      } = selectedValues;

      // Constructing the query parameters string
      const queryParams = `vlera=${vleraValue?.Pershkrim || ""}&oper=${
        veprimiValue?.Kodi || ""
      }&TCRCode=${arkaValue?.TCRCode || ""}`;

      // Making the request with query parameters in the URL
      const response = await mainAxios.get(`/arka/pfissarka?${queryParams}`);

      setSelectedValues({});

      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];

        if (response.data[0]?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        } else {
          const message = `${veprimiValue?.Pershkrim || ""} u krye me sukses !`;
          showToast(message, { severity: "success" });
        }
      } else {
        console.error("Response data is not an array or is empty.");
      }
    } catch (err) {
      console.error("Error posting data:", err);

      toast.current.show({
        severity: "error",
        summary: "Deklarimi i Arkes deshtoi.",
      });

      // Clear the form fields and response data when there is an error.
      setSelectedValues({});
    }
  };

  const selectGlobalSlice = (state) => state.globalSlice;

  const memoizedSelector = createSelector(
    [selectGlobalSlice],
    (globalSlice) => {
      // Your selector logic here
      return globalSlice[selectedButton?.reduxGet] || [];
    }
  );

  const raportButtons = ArkaButtons.filter((rb) =>
    raportButtonsKeys.includes(rb.key)
  );

  const selectedButton = raportButtons.find(
    (button) => button.key === selectedButtonKey
  );

  const dataFromRedux = useSelector(memoizedSelector);

  const mappedData = dataFromRedux.map((item) => ({
    Kodi: item.Kodi,
    Pershkrim: item.Pershkrim || item.Kodi,
    TCRCode: item.TCRCode || item.TCRCode,
  }));

  const filteredData = mappedData.filter(
    (item) =>
      (item.Kodi &&
        item.Kodi.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.Pershkrim &&
        item.Pershkrim.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.TCRCode &&
        item.TCRCode.toLowerCase().includes(searchValue.toLowerCase()))
  );

  const handleRowClick = (item) => {
    if (selectedButtonKey) {
      setSelectedValues((prevValues) => ({
        ...prevValues,

        [selectedButtonKey]: item,
      }));
      setSelectedButtonKey(null);
    }
  };

  const handleInputChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: { ...prevValues[key], Pershkrim: value },
    }));
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  useEffect(() => {
    const hasTCRCode = mappedData.some((item) => item.TCRCode);
    setIsTCRCodeVisible(hasTCRCode);
  }, [mappedData]);

  return (
    <Dialog
      header={content}
      visible={visible}
      onHide={onHide}
      style={!isMobile ? { width: "20%" } : { width: "80%" }}
    >
      <Toast ref={toast} />

      <p>{label}</p>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {raportButtons.map((button) => (
            <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
              <TextField
                id={button.key}
                label={button.label}
                type={button.type}
                style={{ width: "100%" }}
                className="textfieldRaport"
                value={selectedValues[button.key]?.Pershkrim || ""}
                onChange={(e) => handleInputChange(button.key, e.target.value)}
                InputProps={
                  button.reduxGet
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedButtonKey !== button.key ? (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(button.key)}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(null)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }
                    : null
                }
              />
              {selectedButtonKey === button.key && (
                <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
                  <Paper
                    style={{
                      position: "absolute",
                      width: "90%",
                      zIndex: 9999,
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    {" "}
                    <Box px={2} py={1}>
                      <TextField
                        id="filled-search"
                        placeholder="Search "
                        type="search"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </Box>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                    >
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Kodi</TableCell>
                            <TableCell align="center">Pershkrim</TableCell>
                            {isTCRCodeVisible && (
                              <TableCell align="right">TCRCode</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData.map((item, index) => (
                            <TableRow
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRowClick(item)}
                            >
                              <TableCell component="th" scope="row">
                                {item.Kodi}
                              </TableCell>
                              <TableCell align="center">
                                {item.Pershkrim}
                              </TableCell>
                              {isTCRCodeVisible ? (
                                <TableCell align="right">
                                  {item.TCRCode}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        <Button
          style={{
            width: "100%",
            color: "white",
            marginTop: "10px",
            backgroundColor: "#1c80cf",
          }}
          onClick={handleSubmit}
        >
          Regjistrim
        </Button>
      </Box>
    </Dialog>
  );
};

export default ArkaDialog;
