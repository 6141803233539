import React, { useRef } from "react";
import { Button } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";

import SkontrinoSupermarket from "./SkontrinoSupermarket";
import DynamicSupermarket80 from "./DynamicSupermarket80";

const PrintoSupermarket = ({
  rows,
  state,
  selectedUserData,
  posSupermarketListaFaturave,
  nipt,
  selectedId,
  showToast,
  menyra,
  operator,
  konfigurimeRedux,
}) => {
  const componentsRef2 = useRef(null);

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {() => (
          <>
            {posSupermarketListaFaturave ? (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rows.length === 0) {
                    showToast("Nuk ka artikuj.");
                    return;
                  }
                  if (!selectedId) {
                    showToast("Nuk ka fature te selektuar.");
                    return;
                  } else {
                    handlePrint2();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  margin: "5px",
                }}
              >
                <PrintSharp /> Riprint Faturen
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rows.length === 0) {
                    showToast("Nuk ka artikuj.");
                    return;
                  }
                  if (!selectedId) {
                    showToast("Nuk ka fature te selektuar.");
                    return;
                  } else {
                    handlePrint2();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <>
                  <PrintSharp />
                  Printo Faturen
                </>
              </Button>
            )}
          </>
        )}
      </PopupState>

      <div ref={componentsRef2} className="print">
        <SkontrinoSupermarket
          selectedUserData={selectedUserData}
          state={state}
          posSupermarketListaFaturave={posSupermarketListaFaturave}
          nipt={nipt}
          menyra={menyra}
          operator={operator}
          konfigurimeRedux={konfigurimeRedux}
        />
        <DynamicSupermarket80 rows={rows} state={state} />
      </div>
    </>
  );
};

export default PrintoSupermarket;
