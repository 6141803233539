import React from "react";
import { Dialog, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "../../dynamicComponent/ConfirmationDialog.scss";

function DeleteConfirmationArtikuj({
  onConfirm,
  onCancel,
  setDisabled,
  setState,
  setButtonClicked,
  defaultState,
  currentId,
  contexDelete,
}) {
  return (
    <>
      <Dialog
        open={true}
        onClose={onCancel}
        PaperProps={{ style: { padding: "20px" } }}
      >
        <div style={{ display: "inline-block", alignSelf: "center" }}>
          <ErrorOutlineIcon
            className="animate-bounce mt-1"
            style={{ color: "#BF3131" }}
          />
        </div>

        <h2>
          A jeni te sigurt per te fshire {contexDelete} me ID: {currentId}?
        </h2>
        <div className="button_modifikim_confirm">
          <Button
            onClick={() => {
              onConfirm();
              setDisabled(true);
              setState(defaultState);
              setButtonClicked(false);
            }}
            onTouchStart={() => {
              onConfirm();
              setDisabled(true);
              setState(defaultState);
              setButtonClicked(false);
            }}
            variant="contained"
          >
            Po
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
            onTouchStart={() => {
              onCancel();
            }}
            variant="contained"
          >
            Jo
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default DeleteConfirmationArtikuj;
