import React from "react";
import "./print80Skontrino.scss";

function SkontrinoXhiro({
  state,
  nipt,
  posSupermarketListaFaturave,
  menyra,
  konfigurimeRedux,
}) {
  // Find the Menyra Pageses entry by ID and return its description
  let pagesaPershkrim = "";
  if (menyra) {
    const menyraPershkrim = menyra.find(
      (menyraP) => menyraP.Id === state.Menyra_Pageses_Id
    );
    pagesaPershkrim = menyraPershkrim ? menyraPershkrim.Pershkrim : "";
  }

  // Safely access the first element of konfigurimeRedux
  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  // Handle the case where ndermarrje might be null or undefined
  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }

  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <b>Fature Porosi</b>
          </td>
          {/* <td className="tds-footer" colSpan={4}>
            Ndermarrje: {selectedUserData && selectedUserData.Ndermarrje_Sel}
          </td> */}
          <td className="title1" colSpan={4}>
            {ndermarrje.Pershkrim}
          </td>
          <td className="tds-footer" colSpan={4}>
            {nipt}
          </td>

          <td className="tds-footer" colSpan={4}>
            {/* Adresa:{selectedUserData.Adresa} */}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <hr className="dashed-line" />
          </td>
        </tr>
        <td className="tds-footer" colSpan={4}>
          Operator: {state.OPERATOR}
        </td>
        <tr>
          <td className="columnsSkontrino">
            Data dhe Ora :{" "}
            {state.UPDATED ||
            state.Updated ||
            state.Hapur ||
            state.DATA ||
            state.Data
              ? (
                  state.UPDATED ||
                  state.Updated ||
                  state.Hapur ||
                  state.DATA ||
                  state.Data
                ).substring(0, 19) // Extract the time part (after T) with 6 numbers
              : null}
          </td>
        </tr>

        {state.TAVOLINA && (
          <tr>
            <td className="columnsSkontrino">Tavolina: {state.TAVOLINA}</td>
          </tr>
        )}

        <tr>
          <td className="columnsSkontrino">
            Menyra e Pageses: {pagesaPershkrim}
          </td>
        </tr>

        {state.KLFU_PERSHKRIM && (
          <tr>
            <td className="columnsSkontrino" colSpan={4}>
              Klienti: {state.KLFU_PERSHKRIM}
            </td>
          </tr>
        )}
      </thead>
      <hr />
      <tbody>
        <tr>
          <td colSpan={4} className="hr-dashed"></td>
        </tr>
      </tbody>
    </table>
  );
}

export default SkontrinoXhiro;
