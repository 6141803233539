import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import getColor from "../VariableColors/GetColor";
import "./MainBotCom.scss";
import noDataicon from "../../../../../assets/no-data.png";

const MainBotComponent = ({ artData, onItemClick, isMainTopOpen }) => {
  const ColoredItem = styled(Paper)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // backgroundColor: getColor(theme, pershkrimcolor),
    height: "70px",
    cursor: "pointer",
  }));

  const ColorIndicator = styled("div")(({ theme, pershkrimcolor }) => ({
    position: "absolute",
    top: 0,
    left: "2%",
    width: "2%",
    height: "90%",
    borderRadius: "15%",
    overflow: "hidden",
    backgroundColor: getColor(theme, pershkrimcolor),
    "&::before": {
      content: '""',
      display: "block",
      // paddingTop: "100%",
    },
  }));

  function FormRow({ data, theme }) {
    return (
      <React.Fragment>
        {data.map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={item.Kodi}
            onClick={() => onItemClick(item)}
          >
            <ColoredItem theme={theme} pershkrimcolor={item.Pershkrim}>
              <ColorIndicator theme={theme} pershkrimcolor={item.Pershkrim} />
              <div className="inside_Art">
                <div className="PershrkimArt">{item.Pershkrim}</div>
                <div className="CmimiArt">{item.Cmimi} Lek</div>
                <div>{item.Njesi_Kodi}</div>
              </div>
            </ColoredItem>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  const theme = {};

  return (
    <div
      className="mainBot"
      style={{
        height: isMainTopOpen ? "100%" : "100%",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {artData.length === 0 && (
            <div
              style={{ textAlign: "center", padding: "10px", color: "gray" }}
            >
              <div className="flex direction-column">
                <img
                  src={noDataicon}
                  alt="No data"
                  style={{ width: "100px", height: "100px" }}
                />
                <p>Nuk u gjend asnje artikull.</p>
              </div>
            </div>
          )}
          {Array.from({ length: Math.ceil(artData.length / 4) }).map(
            (_, rowIndex) => (
              <Grid container item spacing={3} key={rowIndex}>
                <FormRow
                  theme={theme}
                  data={artData.slice(rowIndex * 4, rowIndex * 4 + 4)}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default MainBotComponent;
