import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";
import CloseIcon from "@mui/icons-material/Close";

export default function ArkaPos({ open, onClose, arka }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const oper = useSelector((state) => state.globalSlice.oper);
  const [vleraValue, setVleraValue] = useState(null);
  const [veprimiValue, setVeprimiValue] = useState(null);
  const [arkaValue, setArkaValue] = useState(null);
  const toast = useRef(null);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      position: "top-center",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };

  const handleSubmit = async () => {
    try {
      const queryParams = `vlera=${vleraValue || ""}&oper=${
        veprimiValue?.Kodi || ""
      }&TCRCode=${arkaValue?.TCRCode || ""}`;

      const response = await mainAxios.get(`/arka/pfissarka?${queryParams}`);

      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];

        if (response.data[0]?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        } else {
          const message = `${veprimiValue?.Pershkrim || ""} u krye me sukses !`;
          showToast(message, { severity: "success" });
        }
      }
    } catch (err) {
      console.error("Error posting data:", err);
      toast.current.show({
        severity: "error",
        summary: "Deklarimi i Arkes deshtoi.",
      });
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center", // Center align the text
        }}
      >
        <h2>Deklarimi i Arkes</h2>

        <Button
          autoFocus
          onClick={onClose}
          style={{ alignSelf: "self-end", color: "red" }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <Toast ref={toast} />
      <DialogContent>
        {/* First Dropdown */}
        <Autocomplete
          options={oper}
          getOptionLabel={(option) => `${option.Pershkrim} - ${option.Kodi}`}
          onChange={(event, value) => setVeprimiValue(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Veprimi"
              variant="outlined"
              style={{ width: 300, padding: 5 }}
            />
          )}
        />

        {/* Second Dropdown */}
        <Autocomplete
          options={arka}
          getOptionLabel={(option) => `${option.Pershkrim} - ${option.TCRCode}`}
          getOptionSelected={(option, value) =>
            option.TCRCode === value.TCRCode
          }
          onChange={(event, value) => setArkaValue(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Arka"
              variant="outlined"
              style={{ width: 300, padding: 5 }}
            />
          )}
        />

        {/* Value Field*/}
        <TextField
          label="Vlera"
          variant="outlined"
          onChange={(event) => setVleraValue(event.target.value)}
          style={{ width: 300, padding: 5 }}
        />
      </DialogContent>
      <DialogActions style={{ textAlign: "center", justifyContent: "center" }}>
        <Button onClick={handleSubmit} autoFocus variant="contained">
          Deklaro
        </Button>
      </DialogActions>
    </Dialog>
  );
}
