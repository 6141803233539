import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import BackspaceIcon from "@mui/icons-material/Backspace";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const CustomKeyboard = ({ onClick }) => {
  const handleClick = (value) => {
    if (value === "Enter") {
      onClick("Enter");
    } else {
      onClick(value);
    }
  };

  const buttons = [
    // Number keys
    [...Array(9).keys()].map((num) => (
      <Grid item xs={3} key={num}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handleClick(num + 1)} // Start from 1
          sx={{
            height: "60px",
            borderRadius: "10px",
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "#000",
          }}
        >
          {num + 1}
        </Button>
      </Grid>
    )),
    <Grid item xs={3} key={"zero"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick("0")} // Display 0 as a string
        sx={{
          height: "60px",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "1.2rem",
          color: "#000",
        }}
      >
        0
      </Button>
    </Grid>,
    // Other keys
    <Grid item xs={3} key={"decimal"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick(".")}
        sx={{
          height: "60px",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "1.2rem",
          color: "#000",
        }}
      >
        .
      </Button>
    </Grid>,
    <Grid item xs={3} key={"add"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick("+")}
        sx={{
          height: "60px",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "1.2rem",
          color: "#000",
        }}
      >
        <AddIcon />
      </Button>
    </Grid>,
    <Grid item xs={3} key={"subtract"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick("-")}
        sx={{
          height: "60px",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "1.2rem",
          color: "#000",
        }}
      >
        <RemoveIcon />
      </Button>
    </Grid>,
    <Grid item xs={3} key={"delete"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick("DEL")}
        sx={{
          height: "60px",
          borderRadius: "10px",
          color: "red",
        }}
      >
        <BackspaceIcon />
      </Button>
    </Grid>,
    <Grid item xs={6} key={"Enter"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleClick("Enter")}
        sx={{
          height: "60px",
          borderRadius: "10px",
          color: "#0E46A3",
        }}
      >
        <SubdirectoryArrowLeftIcon />
      </Button>
    </Grid>,
  ];

  return (
    <Grid container spacing={1}>
      {buttons.flat()}
    </Grid>
  );
};

export default CustomKeyboard;
