import React, { useState } from "react";
// import "./TabelaSuperMarket.scss";
import PrimeGridSuperRestaurant from "./PrimeGridSuperRestaurant";

const TabelaRestaurant = ({ rows, setRows, shtoArtikull, calculateData }) => {
  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Skonto", title: "Skonto (%)" },
    {
      field: "Skonto_Vlera",
      title: "Skonto_Vlera",
      allowSum: true,
    },
    { field: "Cmimi_Pa_Tvsh", title: "Cmimi_Pa_Tvsh" },
    {
      field: "Vlera_Pa_Tvsh",
      title: "Vlera_Pa_Tvsh",
      allowSum: true,
    },
    { field: "Tvsh", title: "Tvsh" },
    {
      field: "Tvsh_Vlera",
      title: "Tvsh_Vlera",
      allowSum: true,
    },
    { field: "Cmimi", title: "Cmimi" },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Tipi", title: "Tipi" },
    { field: "Total", title: "Total", allowSum: true },
  ]);
  const defaultColumnsStorage = columns.map((column) => column.title);

  return (
    <>
      <PrimeGridSuperRestaurant
        gridKey="Restaurant"
        contextKey={"pos/dtl"}
        isEditable={true}
        columns={columns}
        setColumns={setColumns}
        disabled={false}
        defaultColumnsStorage={defaultColumnsStorage}
        rows={rows}
        setRows={setRows}
        calculateData={calculateData}
        shtoArtikull={shtoArtikull}
      />
    </>
  );
};

export default TabelaRestaurant;
