import * as React from "react";
import Modal from "@mui/material/Modal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const DeleteModalCelje = ({ open, handleClose, handleConfirm, state }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: 0,
          textAlign: "center",
        }}
      >
        <DialogTitle>Konfirmoni</DialogTitle>
        <DialogContent>
          <Typography variant="h5">
            Deshironi ta fshini{" "}
            {state.Pershkrim ? state.Pershkrim : "->S'ka Pershkrim"} ?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button variant="contained" onClick={handleClose}>
            JO
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={handleConfirm}
          >
            PO
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default DeleteModalCelje;
