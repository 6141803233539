import React, { useState, useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { People } from "@mui/icons-material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import { Toast } from "primereact/toast";
import "../../../../components/Navbar/NavStyles/posBtn.scss";
import "../../../../components/Navbar/NavStyles/NavBar.scss";
import "../POSE.scss";
import mainAxios from "../../../../services/axios";
import Xhiro from "../../../MbylljeTurni/Xhiro";
import { useUser } from "../../../../zustand/common";
import LoginSupermarket from "../../SuperMarket/NewSuperMarket/NavBarSuperMarket/LoginSupermarket";
import { Button } from "@mui/material";
import SplitButton from "../Main/LeftBar/SplitButtons/Button";
import { useNavigate } from "react-router-dom";

const NavBar = ({
  active,
  fetchPos,
  setUserAutomatikisht,
  menyra,
  clickedItems,
  state,
  arka,
  setState,
  newId,
  defaultState,
}) => {
  const navigate = useNavigate();
  const { user, logout } = useUser();
  const operator = user.username;
  const [logoutbtn, setLogout] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isXhiroDialogOpen, setIsXhiroDialogOpen] = useState(false);

  useEffect(() => {
    if (!logoutbtn) {
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

      const handleUnload = () => {
        navigate("/login");
        logout();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    } else {
      logout();
    }
  }, [logout, logoutbtn]);

  const [perdorues, setPerdorues] = useState([]);

  const toast = useRef(null);
  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      position: "top-center",
      detail: options.detail || "",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchDataPerdorues = async () => {
    try {
      const response = await mainAxios("/get/perdoruesi", {});
      if (response.status === 200) {
        const filteredData = response.data.filter(
          (item) => item.HAPAUTOMATIKISHT === 0 || item.HAPAUTOMATIKISHT === 9
        );
        setPerdorues(filteredData);
      } else {
        const message = `Fiskalizimi u krye me sukses !`;
        showToast(message, { severity: "success" });
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div
      style={{
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
      }}
      className="navbar_table"
    >
      <Toast ref={toast} position="top-center" />
      <div
        className="item-1 navbar_pos_maindiv"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: "5px",
        }}
      >
        <Button
          startIcon={<People />}
          onClick={() => {
            setPopupOpen(true);
            fetchDataPerdorues();
          }}
          size="large"
          style={{
            backgroundColor: "#11999E",
            color: "#fff",
          }}
        >
          {operator}
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {isMobile ? (
          ""
        ) : (
          <IconButton
            onClick={() => {
              toggleFullscreen();
            }}
            sx={{ ml: 2 }}
          >
            {!isFullscreen ? (
              <ZoomOutMapIcon
                sx={{
                  width: 25,
                  height: 25,
                  color: "white",
                  backgroundColor: "#11999E",
                  borderRadius: "3px",
                }}
              />
            ) : (
              <ZoomInMapIcon
                sx={{
                  width: 25,
                  height: 25,
                  color: "white",
                  backgroundColor: "#11999E",
                  borderRadius: "3px",
                }}
              />
            )}
          </IconButton>
        )}
        {active !== "Login" && (
          <>
            <IconButton
              onClick={handleClick}
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MenuIcon
                sx={{
                  width: 25,
                  height: 25,
                  color: "white",
                  backgroundColor: "#11999E",
                  borderRadius: "3px",
                }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <Divider /> */}

              <MenuItem
                onClick={() => {
                  setLogout(true);
                }}
              >
                <Logout fontSize="medium" className="mr-5" />
                Logout
              </MenuItem>

              <SplitButton
                menyra={menyra}
                newId={newId}
                rows={clickedItems}
                state={state}
                showToast={showToast}
                fetchPosSupermarket={fetchPos}
                arka={arka}
                setState={setState}
                defaultState={defaultState}
              />
            </Menu>
            {isXhiroDialogOpen && (
              <Xhiro
                posRestaurant={true}
                fetchPos={fetchPos}
                isFullscreen={isFullscreen}
                setIsFullscreen={setIsFullscreen}
                open={isXhiroDialogOpen}
                onClose={() => setIsXhiroDialogOpen(false)}
              />
            )}
          </>
        )}
        <LoginSupermarket
          isOpen={isPopupOpen}
          onClose={() => setPopupOpen(false)}
          setUserAutomatikisht={setUserAutomatikisht}
          perdorues={perdorues}
          showToast={showToast}
        />
      </div>
    </div>
  );
};

export default NavBar;
