// Function to set a cookie with a given name, value, and optional expiration days
export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Function to get the value of a cookie by name
export const getCookie = (name) => {
  // Get all cookies and find the one with the specified name
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

// Function to expire a cookie with a given name
export const expireCookie = (name) => {
  // Set the expiration date of the cookie to the past
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
