import { getCookie } from "./helpers";

// Custom route component to handle authorization
const AuthorizedRoute = ({ children }) => {
  // Get the access token from the cookie
  const token = getCookie("access_token");

  // If there is no access token, redirect to the home page
  if (!token) {
    //return (window.location.href = "/login");
    window.location.href = "/login";
  }

  // Render the children (the protected content) if the user is authenticated
  return children;
};

export default AuthorizedRoute;
