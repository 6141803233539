import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import PeopleIcon from "@mui/icons-material/People";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import mainAxios from "../../../../../services/axios";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";

export default function ShtoKlient({ klienti }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { state, updateState } = useMyContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChange = async (value) => {
    if (value) {
      const postDataWithoutId = {
        KLFU_Kodi: value.Kodi,
        KLFU_Pershkrim: value.Pershkrim,
      };
      try {
        await mainAxios.put(`/pos/${state.Id}`, postDataWithoutId);
        updateState({
          KLFU_Kodi: value.Kodi,
          KLFU_Pershkrim: value.Pershkrim,
        });
      } catch (error) {
        console.error("There was an error updating the data!", error);
      }
    }
    handleClose();
  };

  const filteredKlienti = klienti.filter((klient) =>
    klient.Pershkrim.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <IconButton onClick={handleClick} style={{ fontSize: "2rem" }}>
        <Badge badgeContent={state.KLFU_Pershkrim} color="primary">
          <PeopleIcon style={{ color: "#fff", fontSize: "2rem" }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 500,
            width: "20ch",
            top: "50px",
          },
        }}
        MenuListProps={{ style: { paddingTop: 0 } }}
      >
        <div style={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            label="Search"
            variant="outlined"
            size="small"
            fullWidth
          />
        </div>
        <div style={{ maxHeight: 400, overflowY: "auto" }}>
          {filteredKlienti.map((klient) => (
            <MenuItem
              key={klient.Kodi}
              onClick={() => handleSelectChange(klient)}
              style={{ fontSize: "0.9rem", padding: "4px 16px" }}
            >
              {klient.Pershkrim}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
}
