import React from "react";
import { Checkbox } from "primereact/checkbox";

const TabWebRights = ({
  toggleState,
  setState,
  disabled,
  state,
  Aktiv1,
  Aktiv2,
  Aktiv3,
  Aktiv4,
  Aktiv5,
  Aktiv6,
  Aktiv7,
}) => {
  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? true : false) : value,
      };
    });
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className={"grid gap-2 grid-cols-3"}>
        {/* aktive check for Web Rights */}
        {Aktiv1 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mFTSH === true}
              onChange={(e) =>
                handleChangeAktiv("mFTSH", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Fature Shitje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv2 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mFTBL === true}
              onChange={(e) =>
                handleChangeAktiv("mFTBL", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Fature Blerje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv3 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mFLH === true}
              onChange={(e) =>
                handleChangeAktiv("mFLH", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Flete Hyrje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv4 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mFLD === true}
              onChange={(e) =>
                handleChangeAktiv("mFLD", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Flete Dalje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv5 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mArt === true}
              onChange={(e) =>
                handleChangeAktiv("mArt", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Artikuj
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv6 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mBarRestorant === true}
              onChange={(e) =>
                handleChangeAktiv(
                  "mBarRestorant",
                  e.target.checked ? true : false
                )
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Bar / Restorant
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv7 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mReports === true}
              onChange={(e) =>
                handleChangeAktiv("mReports", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Raporte
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TabWebRights;
