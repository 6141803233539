import React from "react";
import { FormControlLabel, Checkbox, TextField, Button } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import OperatorList from "../buttonsXhiro/Operator";
import KlientList from "../buttonsXhiro/Klient";
import "./HeaderLeft.scss";

const HeaderLeft = ({
  functionPost,
  setSelectedOperator,
  setSelectedKlient,
  selectedOperator,
  operators,
  faturatEPambyllura,
  setFaturatEPambyllura,
  faturatEPafiskalizuara,
  searchWithKlient,
  setFaturatEPafiskalizuara,
  setSearchWithKlient,
  deriData,
  setDeriData,
  ngaData,
  setNgaData,
  handleOperatorSelection,
  handleKlientSelection,
  klients,
  selectedKlient,
}) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSearchWithKlient(isChecked);
    if (!isChecked) {
      setSelectedKlient("");
    }
  };

  return (
    <div className="main_header_left">
      <div className="flex">
        <FormControlLabel
          control={
            <Checkbox
              checked={faturatEPambyllura}
              onChange={(e) => setFaturatEPambyllura(e.target.checked)}
            />
          }
          label="Faturat e pambyllura"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={faturatEPafiskalizuara}
              onChange={(e) => setFaturatEPafiskalizuara(e.target.checked)}
            />
          }
          label="Faturat e pafiskalizuara"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={searchWithKlient}
              onChange={handleCheckboxChange}
            />
          }
          label="Kerko me klient"
        />
      </div>
      <div className="flex">
        <TextField
          disabled={faturatEPambyllura}
          type="date"
          label="Data Nga"
          InputLabelProps={{
            shrink: true,
          }}
          value={ngaData}
          onChange={(e) => setNgaData(e.target.value)}
          style={{ width: "30%", margin: 5 }}
        />
        <TextField
          disabled={faturatEPambyllura}
          type="date"
          label="Data Deri"
          InputLabelProps={{
            shrink: true,
          }}
          value={deriData}
          onChange={(e) => setDeriData(e.target.value)}
          style={{ width: "30%", margin: 5 }}
        />

        <OperatorList
          onSelectOperator={handleOperatorSelection}
          operators={operators}
          selectedOperator={selectedOperator}
          setSelectedOperator={setSelectedOperator}
        />
        {searchWithKlient && (
          <KlientList
            klients={klients}
            onSelectKlient={handleKlientSelection}
            selectedKlient={selectedKlient}
            setSelectedKlient={setSelectedKlient}
            setSearchWithKlient={setSearchWithKlient}
          />
        )}

        <Button
          disabled={
            !faturatEPafiskalizuara &&
            !faturatEPambyllura &&
            !ngaData &&
            !setNgaData
          }
          variant="outlined"
          startIcon={<ManageSearchIcon />}
          onClick={functionPost}
          style={{ width: "30%", margin: 5 }}
        >
          Kerko
        </Button>
      </div>
    </div>
  );
};

export default HeaderLeft;
