import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, ButtonGroup } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import useStorage from "../../hooks/useStorage";
import PaneliArtikuj from "./PaneliArtikuj";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import "./Artikuj.scss";
import mainAxios from "../../services/axios";
import { useSelector } from "react-redux";
import Footer from "../../components/Navbar/Footer";
import { Toast } from "primereact";
import { getArtikujPag } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import PrintoBarcode from "../../components/PrintoBarcode";
import PopUpConfirm from "../fature-blerje/tabet/PopUpConfirm";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { LoadingOverlayContext } from "../../components/LoadingOverlay";

// function
import incrementKodi from "./functions/IncrementKodi";
import DeleteConfirmationArtikuj from "../fature-blerje/minimodal/DeleteArtikuj";
import CheckboxHandler from "./functions/CheckboxHandler";
import ArticleHandler from "./functions/ArtikujHandler";
import { useUser } from "../../zustand/common";

const Artikuj = ({
  showHideColumns = true,
  gridKey,
  currentAction,
  setCurrentAction,
  hidePupUp,
  setHidePupUp,
  rows,
  isEditable = true,
  isMobile,
  disabled,
  setDisabled,
  ...props
}) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const [data, setdata] = useState([]);
  let numDataLength = data.length;
  const toast = useRef(null);
  // const [currentAction, setCurrentAction] = useState("add");
  const [currentId, setCurrentId] = useState("");
  const dispatch = useDispatch();
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState();
  const [checkModify, setCheckModify] = useState(false);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  }, []);

  useEffect(() => {
    if (currentId) {
      setDisabledBtnDelete(false);
    } else {
      setDisabledBtnDelete(true);
    }
  }, [currentId]);

  const onAddClick = () => {
    setCurrentId("");
    setCurrentAction("add");
  };

  // redux artikuj (get requesti i artikujve)
  const artikujRedux = useSelector((state) => state.globalSlice.artikujPag);
  const artikuj = artikujRedux;

  // redux total artikuj (get requesti i numrit total artikujve)
  const totalArtRedux = useSelector((state) => state.globalSlice.totalArt);
  const artikujTotal = totalArtRedux;

  useEffect(() => {
    setdata(artikuj);
  }, [artikuj]);

  const fetchData = async () => {
    try {
      showLoading();
      await dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    // Ensure that `numPerPage` and `currentPage` are defined before dispatching
    if (numPerPage && currentPage) {
      fetchData();
    }
  }, [numPerPage, currentPage, dispatch]);

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim", editor: textEditor },
    { field: "Njesi_Kodi", title: "Njesi_Kodi", editor: textEditor },
    { field: "Cmimi", title: "Cmimi", editor: textEditor },
    { field: "Barkod", title: "Barkod" },
    { field: "Aktiv", title: "Aktiv" },
    { field: "Perbere", title: "Perbere", editor: textEditor },
    { field: "Peshore", title: "Peshore" },
  ]);

  const defaultState = {
    AAM_Jetegjatesia_Njesi_Kodi: "",
    AAM_Kls01_Kodi: "",
    AAM_Kls02_Kodi: "",
    AAM_Kls03_Kodi: "",
    AAM_Metoda_Amortizimi_Kodi: "",
    Aktiv: 1,
    Shumice: 1,
    Pos_NeList: 1,
    Pakice: 1,
    Distribucion: "",
    AAM: "",
    Art_Kls01_Kodi: "",
    Cmimi_Bleres: "",
    Art_Kls02_Kodi: "",
    Art_Kls03_Kodi: "",
    Art_Kls04_Kodi: "",
    Art_Kls05_Kodi: "",
    Art_Kls06_Kodi: "",
    Fjale_Kyce: "",
    Kodi: "",
    Operator: "",
    Njesi_Kodi: "",
    NrSerik: "",
    Perbere: "",
    Pershkrim: "",
    Peshore: "",
    Cmimi: "",
    Kodi1: "",
    Kodi2: "",
    Kodi3: "",
    Kodi4: "",
    Kodi5: "",
    Cmimi_1: "",
    Cmimi_2: "",
    Cmimi_3: "",
    Cmimi_4: "",
    Cmimi_5: "",
    Cmimi_6: "",
    Barkod: "",
    Tarifa_Kodi: "",
  };

  const [state, setState] = useState(defaultState);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonClickedModifiko, setButtonClickedModifiko] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (selectedRowData) => {
    setState(selectedRowData);
    setCurrentId(selectedRowData.Id);
    setSelectedRowData(selectedRowData);
  };

  useEffect(() => {
    // Function to select the first row

    const selectFirstRow = () => {
      if (data && data.length > 0 && !state.Kodi) {
        handleRowClick(data[0]);
      }
    };

    selectFirstRow();
  }, [data, state]);

  const updateRowData = async (rowData) => {
    if (state.Kodi === "" || state.Kodi === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni Kodin ose anulloje.",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni pershkrimin ose anulloje.",
      });

      return;
    }

    try {
      const response = await mainAxios.put(`/art/${currentId}`, rowData);
      if (response.status === 200) {
        dispatch(
          getArtikujPag(
            `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
          )
        );
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.current.show({
        severity: "error",
        summary: "Error updating data.",
      });
    }
  };

  const handleChange = (key, value, rowData) => {
    if (isEditingAllowed) {
      const updatedRowData = { ...rowData, [key]: value };
      updateRowData(updatedRowData);
    }
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const handleDeleteClick = async () => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=ART&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });
      } else {
        setShowConfirmationPopup(true);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const handleRegister = () => {
    if (state.Kodi === "" || state.Kodi === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni Kodin ose anulloje.",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni pershkrimin ose anulloje.",
      });

      return;
    }

    if (currentAction === "add") {
      handleAdd();
    }
  };

  const { handleAdd, handleModify } = ArticleHandler({
    state,
    currentId,
    setCurrentId,
    handleRowClick,
    setDisabled,
    setButtonClicked,
    setButtonClickedModifiko,
    currentPage,
    numPerPage,
    username,
    showLoading,
    hideLoading,
    toast,
  });

  const defaultColumnsStorage = [
    "Kodi",
    "Pershkrim",
    "Barkod",
    "Cmimi",
    "Njesi_Kodi",
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);
  const cm = useRef(null);
  const [filters1, setFilters1] = useState(null);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatArtikuj"
  );

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns
      .filter((col) => selectedColumns.some((sCol) => sCol.title === col.title))
      .map((col) => col.field);
    setselectedcolumns(orderedSelectedColumns);
  };

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const cancelDelete = () => {
    setShowConfirmationPopup(false);
  };

  const deleteFature = async (Id) => {
    try {
      const response = await mainAxios.delete(`/art/${state.Id}`);
      if (response.status === 200) {
        try {
          setShowConfirmationPopup(false);

          dispatch(
            getArtikujPag(
              `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
            )
          );

          toast.current.show({
            severity: "info",
            summary: "Rreshti u fshi me sukses.",
          });
        } catch (error) {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Rreshti nuk mund te fshihet.",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const confirmDelete = async () => {
    await deleteFature(currentId);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setdata(artikuj);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      toast.current.show({
        severity: "info",
        summary: "Vendosni pershkrimin e artikullit",
      });
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      setdata(response.data); // Update data state with search results
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Artikulli nuk u gjend",
      });
    }
  };

  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  const header = (
    <>
      <div className="header_artikuj flex justify-between">
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              style={{ height: "2em" }}
              placeholder="Kerko"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </span>
          <Button
            style={{ marginLeft: "1em", height: "2em" }}
            variant="contained"
            onClick={handleSearchClick} // Use the search handler
          >
            Kerko
          </Button>
        </div>

        <div
          style={{
            textAlign: "left",
          }}
        >
          {showHideColumns && (
            <>
              <MultiSelect
                className="multiselect_artikuj"
                value={filterdColumns}
                options={columns}
                optionLabel="title"
                onChange={onColumnToggle}
                style={{
                  width: "3em",
                  height: "2em",
                  border: "1px solid #a8a9aa54",
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );

  const onCellEditComplete = (e) => {
    const { rowData, newValue, field } = e;
    handleChange(field, newValue, rowData);
  };

  const dynamicColumns = filterdColumns?.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    return (
      <Column
        key={uniqueKey}
        style={{ height: "2.6rem" }}
        // {...(isEditable && {
        //   onCellEditComplete: (e) => onCellEditComplete(e),
        // })}
        field={col?.field}
        header={col?.title}
        editor={isEditingAllowed ? col.editor : null}
        onCellEditComplete={onCellEditComplete}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData) => {
          const value = rowData[col?.field];
          let formattedValue;
          if (typeof value === "number") {
            if (Number.isInteger(value)) {
              formattedValue = value.toLocaleString();
            } else {
              formattedValue = value.toLocaleString();
              formattedValue = value.toFixed(2);
            }
          } else {
            formattedValue = value;
          }
          return (
            <div
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </div>
          );
        }}
      />
    );
  });

  // Add the barcode button column
  dynamicColumns.push(
    <Column
      style={{ height: "2.6rem" }}
      key="barcodeButton"
      body={(rowData) => <PrintoBarcode rowData={rowData} />}
    />
  );

  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(10);

  const onCustomPage2 = (event) => {
    setFirst2(event.first);
    setRows2(event.rows);
  };

  const [firstnum, setFirstNum] = useState("");
  const [lastnum, setLastNum] = useState("");

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",

    RowsPerPageDropdown: (options) => {
      options.props.page = currentPage;
      options.props.pageCount = Math.ceil(
        artikujTotal.total_rows / options.value
      );
      options.pageCount = Math.ceil(artikujTotal.total_rows / options.value);
      options.totalPages = Math.ceil(artikujTotal.total_rows / options.value);
      options.totalRecords = artikujTotal.total_rows;
      options.props.totalRecords = artikujTotal.total_rows;

      var totalPages = options.totalPages;
      var totalArt = options.totalRecords;

      if (currentPage === 1) {
        var firstnumb = currentPage;
      } else {
        var firstnumb = currentPage * options.value - (options.value - 1);
      }

      if (currentPage === totalPages) {
        var lastnumb = totalArt;
      } else {
        var lastnumb = currentPage * options.value;
      }
      setFirstNum(firstnumb);
      setLastNum(lastnumb);

      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      useEffect(() => {
        setCurrentPage(1);
      }, [options.value]);

      setNumPerPage(options.value);

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Rrjeshta
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },

    CurrentPageReport: (options) => {
      // Ensure options.totalRecords is correctly set
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {firstnum} - {lastnum} of {artikujTotal.total_rows}
        </span>
      );
    },

    PrevPageLink: (options) => {
      const handlePrevClick = () => {
        setCurrentPage((prevPage) => prevPage - 1);
      };

      return (
        <Button
          variant="outlined"
          style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      );
    },

    NextPageLink: (options) => {
      const handleNextClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };

      return (
        <Button
          variant="outlined"
          style={{ cursor: "pointer" }}
          onClick={handleNextClick}
          disabled={numPerPage > numDataLength}
        >
          <ChevronRightIcon />
        </Button>
      );
    },
  };

  const handleConfirm = () => {
    try {
      setHidePupUp(false);
      setCurrentAction(null);
      setButtonClicked(false);
      setButtonClickedModifiko(false);
      setDisabled(true);
      setState("");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
        )
      );
    }
  };

  const handleCancel = () => {
    setHidePupUp(false);
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
  };

  return (
    <div className="mainDiv_ftsh">
      <NavBar currentAction={currentAction} />

      <div className={isMobile ? null : "template"}>
        {hidePupUp && (
          <PopUpConfirm onConfirm={handleConfirm} onCancel={handleCancel} />
        )}
        {showConfirmationPopup && (
          <DeleteConfirmationArtikuj
            contexDelete={`artikullin ${state.Pershkrim}`}
            currentId={currentId}
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            setDisabled={setDisabled}
            defaultState={defaultState}
            setState={setState}
            setButtonClicked={setButtonClicked}
          />
        )}
        <div className="p-2 main-container">
          <div
            className="flex justify-between items-center"
            style={{ height: "5%" }}
          >
            <Toast ref={toast} />
            {/* Paneli i tabeve do te vendoset ketu */}
            <ButtonGroup className="mb-2 mt-1">
              <Button
                onClick={async (e) => {
                  const updatedData = await incrementKodi();

                  const updatedState = {
                    ...defaultState,
                    Kodi: updatedData.Kodi,
                    Art_Kls01_Kodi: updatedData.Art_Kls01_Kodi,
                    Tarifa_Kodi: updatedData.Tarifa_Kodi,
                    Skema_Kodi: updatedData.Skema_Kodi,
                    Njesi_Kodi: updatedData.Njesi_Kodi,
                  };

                  e.preventDefault();
                  setDisabled(false);
                  setCheckModify(false);
                  setState(updatedState);
                  setButtonClickedModifiko(true);
                  // addRow();
                  onAddClick();
                  setButtonClicked(true);
                }}
                disabled={buttonClicked}
              >
                <PostAddIcon />
                {isMobile ? null : "Shtim"}
              </Button>

              <Button
                className="p-1"
                disabled={disabledBtnDelete}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteClick();
                  // setButtonClicked(false);
                }}
              >
                <DeleteIcon />
                Fshije
              </Button>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  anulloFature();
                }}
                disabled={disabled || checkModify}
              >
                <ClearIcon />
                {isMobile ? null : "Anullim"}
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setHidePupUp(false);
                  setCurrentAction(null);
                  setButtonClicked(false);
                  setDisabled(true);
                  // setState("");
                  handleRegister();
                }}
                disabled={disabled || checkModify}
                type="submit"
              >
                <AppRegistrationIcon />
                {isMobile ? null : "Regjistro"}
              </Button>
            </ButtonGroup>
            <CheckboxHandler
              setIsEditingAllowed={setIsEditingAllowed}
              setCheckModify={setCheckModify}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              setCurrentAction={setCurrentAction}
              handleModify={handleModify}
              buttonClickedModifiko={buttonClickedModifiko}
            />
          </div>

          <div className="card_artikuj1" style={{ height: "67%" }}>
            <DataTable
              disabled={disabled}
              editMode="cell"
              className="datatable_primegrid artikuj"
              columnClassName="datatable-column-border"
              paginator
              paginatorTemplate={template2}
              first={first2}
              rows={rows2}
              size="small"
              rowClassName={(rowData) =>
                selectedRowData && selectedRowData.Id === rowData.Id
                  ? "selected-row"
                  : ""
              }
              onPage={onCustomPage2}
              paginatorClassName="footer_datatable_artikuj justify-content-end"
              reorderableColumns
              cellSelection
              scrollable
              resizableColumns
              onRowEditComplete={onCellEditComplete}
              onSelectionChange={(e) => {
                const selectedRowData =
                  e.value.length > 0 ? e.value[0].rowData : null;
                handleRowClick(selectedRowData);
              }}
              contextMenuSelection={selectedProduct}
              contextselection={selectedProduct}
              onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
              onContextMenu={(e) => cm.current.show(e.originalEvent)}
              value={data}
              showGridlines
              stripedRows
              dataKey="Id"
              filters={filters1}
              header={header}
              globalFilterFields={[
                "BarKod",
                "Pershkrim",
                "Njesi_Kodi",
                "Sasia_Print",
                "Sasia",
              ]}
              // responsiveLayout="scroll"
              filterDisplay="menu"
              emptyMessage="Nuk ka te dhena"
              {...props}
            >
              {dynamicColumns}
            </DataTable>
          </div>
          <div className="card_below_artikuj" style={{ height: "25%" }}>
            <PaneliArtikuj
              isMobile={isMobile}
              disabled={disabled}
              state={state}
              setState={setState}
              handleChange={handleChange}
            />
          </div>
        </div>
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer />
    </div>
  );
};

export default Artikuj;
