// Import necessary components
import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "@mui/material/Button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import useStorage from "../../../../../hooks/useStorage";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "primereact/toast";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import "./ArtikujSuperMarket.scss";
import CustomKeyboard from "./Keyboard";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";

const ModalListSupermarket = ({
  shtoArtikull,
  showHideColumns = true,
  disabled,
  setSearchQuery,
  searchQuery,
  autoClick = true,
  art,
}) => {
  const [visible, setVisible] = useState(false);
  const [searchTermArtikuj, setSearchTermArtikuj] = useState("");
  const [activeIcon, setActiveIcon] = useState("barcode");
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const buttonRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    if (autoClick && buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.click(); // Programmatically click the button
      }, 100);
    }
  }, [autoClick]);

  const handleIconClick = () => {
    // Toggle between barcode and keyboard icons
    setActiveIcon(activeIcon === "barcode" ? "keyboard" : "barcode");
  };

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const handleKeyboardClick = (value) => {
    if (value === "DEL") {
      setSearchQuery(searchQuery.slice(0, -1));
    } else if (value === "Enter") {
      const enterEvent = {
        target: { value: searchQuery },
        key: "Enter",
      };
      handleSearchChange(enterEvent);
    } else {
      setSearchQuery(searchQuery + value);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchTermArtikuj(query);

    // Check the active icon and search accordingly
    const fieldToSearch = activeIcon === "barcode" ? "Barkod" : "Pershkrim";
    const filteredItems = filterData(art, query, [fieldToSearch]);

    if (event.key === "Enter") {
      // When Enter key is pressed
      // event.preventDefault();
      if (filteredItems.length === 1) {
        const matchedItem = filteredItems[0];
        if (activeIcon === "barcode" && matchedItem.Barkod === query) {
          shtoArtikull(matchedItem);
        }
      } else if (filteredItems.length >= 1) {
        showToast("Artikulli gjendet me shume se nje here");
        setVisible(true);
      } else {
        showToast("Artikulli nuk gjendet");
      }
    }
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const rowsArtikuj = art.map((item) => ({
    ...item,
  }));

  const handleSearchClick = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Show loading overlay
    showLoading();

    // Fetch data
    try {
      // Check if there is a single matched item
      const filteredItems = filterData(art, searchQuery, [
        activeIcon === "barcode" ? "Barkod" : "Pershkrim",
      ]);
      if (filteredItems.length === 1) {
        const matchedItem = filteredItems[0];
        if (activeIcon === "barcode" && matchedItem.Barkod === searchQuery) {
          shtoArtikull(matchedItem);
          setSearchQuery("");
        }
      } else if (filteredItems.length >= 1) {
        showToast("Artikulli gjendet me shume se nje here");
        setVisible(true);
      } else {
        showToast("Artikulli nuk gjendet");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Hide loading overlay
      hideLoading();
    }
  };

  const onSelectionChange = (selected) => {
    const selectedValue = selected.value;

    shtoArtikull(selectedValue);
    setVisible(false);

    // Clear the search inputs
    setSearchTermArtikuj("");
    setSearchQuery("");
  };

  const columns = [
    { field: "Kodi", title: "Kodi" },
    { field: "Barkod", title: "Barkod" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
  ];

  const [selectedColumns, setselectedcolumns] = useStorage(
    columns,
    "kolonatEartikujve"
  );

  const filterData = (data, searchTerm, fieldNames) => {
    if (!searchTerm) return data;
    const filteredItems = data.filter((item) => {
      return fieldNames.some((fieldName) => {
        const fieldValue = item[fieldName];
        return (
          fieldValue != null &&
          fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    });
    return filteredItems;
  };

  const dynamicColumns = selectedColumns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.title} />;
  });

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setselectedcolumns(orderedSelectedColumns);
  };

  const header = (
    <>
      {showHideColumns && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MultiSelect
            className="selectArtikuj"
            value={selectedColumns}
            options={columns}
            optionLabel="title"
            onChange={onColumnToggle}
            style={{ width: "3em", height: "2em" }}
          />
          <span style={{ width: "20rem" }} className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search in Artikuj..."
              value={searchTermArtikuj}
              onChange={(e) => setSearchTermArtikuj(e.target.value)}
            />
          </span>
        </div>
      )}
    </>
  );
  const fontOverlay = {
    fontSize: "11px",
  };

  const handleOpenModals = () => {
    setVisible(true);
  };

  return (
    <form onSubmit={handleSearchClick}>
      <Paper
        component="div"
        sx={{
          p: "10px 15px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={handleIconClick}
          >
            {activeIcon === "barcode" ? (
              <FontAwesomeIcon icon={faBarcode} />
            ) : (
              <KeyboardIcon />
            )}
          </div>
          <InputBase
            ref={buttonRef}
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Kerko Artikujt me ${
              activeIcon === "barcode" ? "Barkode" : "Pershkrim"
            }`}
            inputProps={{
              onChange: handleSearchChange,
              value: searchQuery,
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearchClick(e);
                }
              },
            }}
            onClick={activeIcon === "keyboard" ? handleOpenModals : undefined}
          />
        </div>
        <Button
          disabled={disabled}
          type="button"
          style={{ height: "2rem" }}
          variant="contained"
          onClick={handleOpenModal}
        >
          <SearchIcon />
        </Button>
      </Paper>
      <CustomKeyboard onClick={handleKeyboardClick} />

      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        modal={false}
        appendTo={document.body}
        style={{
          width: "42vw",
          height: "62vh",
          position: "absolute",
          right: 0,
        }}
        className="overlaypanel-demo"
      >
        <div className="card">
          <TabView>
            <TabPanel header="Artikuj">
              <DataTable
                size="small"
                header={header}
                selectionMode="single"
                onSelectionChange={onSelectionChange}
                scrollable
                scrollHeight={"45vh"}
                value={filterData(rowsArtikuj, searchTermArtikuj, [
                  "Pershkrim",
                  "Kodi",
                  "Barkod",
                ])}
                responsiveLayout="scroll"
                style={fontOverlay}
              >
                {dynamicColumns}
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
    </form>
  );
};

export default ModalListSupermarket;
