// Importing necessary dependencies
import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./globalSlice"; // Importing your Redux slice
import globalPosSlice from "./globalPos";
import { userReducer } from "./reducers/userReducers";

const userInfoFromStorage = localStorage.getItem("account")
  ? JSON.parse(localStorage.getItem("account"))
  : null;

const initialState = {
  user: { userInfo: userInfoFromStorage },
};

// Creating and exporting the Redux store
export const store = configureStore({
  reducer: {
    globalSlice,
    globalPosSlice,
    user: userReducer,
  },
  preloadedState: initialState,
});
