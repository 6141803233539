import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import PrimeGrid from "../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import { TextField } from "@mui/material";
import mainAxios from "../../services/axios";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import Form from "react-bootstrap/Form";
import TabKlienti from "../fature-blerje/tabet/Klienti/TabKlienti";
import TabTrans from "../fature-blerje/tabet/Transportuesi/TabTrans";
import MainTab from "../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../fature-blerje/tabet/PaneliTabeve";
import Magazina from "../fature-blerje/minimodal/Magazina";
import { Autocomplete } from "@mui/material";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import PrintoPdfFleteDalje from "../../components/ReactPDF/FleteDaljePdf/PrintoPdf";
import "../fature-blerje/FatureBlerje.scss";
import { Toast } from "primereact/toast";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PeopleIcon from "@mui/icons-material/People";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import Footer from "../../components/Navbar/Footer";
import RightBar from "../fatureShitje/RightBarFIS";
import { createFilterOptions } from "@mui/material";
import Template from "../dynamicComponent/Template";
import { shtoArtikull, calculateData } from "../dynamicComponent/FleteArtCalc";
import ConfirmationDialog from "../dynamicComponent/ConfirmationDialog";
import { useLocation } from "react-router-dom";
import { getIndexFirstFd } from "../../store/globalSlice";
import { useDispatch } from "react-redux";

const FleteDaljePrv = ({
  currentAction,
  setCurrentAction,
  // storeOfflineRequest,
  // isOnline,
  setDataCurrentOffline,
  isMobile,
  disabled,
  setDisabled,
}) => {
  const dispatch = useDispatch();
  const [hidePupUp, setHidePupUp] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [fisVerificationUrl, setFisVerificationUrl] = useState("");
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const location = useLocation();
  const { nmrFSh, fromFatureShitje } = location.state || {};

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  // Define fetchData outside the useEffect
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  };

  // const fetchLastNumberFature = async () => {
  //   const firstFature = await mainAxios.get(`/first/fd`);
  //   setLastNumber(firstFature.data.index);
  // };

  useEffect(() => {
    fetchData();
    // fetchLastNumberFature();
  }, []);

  const handleCloseRightDrawer = () => {
    setRightDrawerOpen(false);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  // redux klienti (get requesti i klientit)
  const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const klienti = klientiRedux;

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);
  let numMag = magazina.length;

  // redux skema-veprimi (get requesti i veprimit)
  const veprimiRedux = useSelector((state) => state.globalSlice.veprimi);

  // Filter veprimiRedux based on Tabela value
  const fleteDaljeObjects = veprimiRedux.filter(
    (item) => item.Tabela === "Flete_Dalje"
  );

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // redux mer indexin e fatures se pare
  const firstFleteDIndex = useSelector((state) => state.globalSlice.getFirstFD);

  const veprimi = fleteDaljeObjects.map((item) => item.Kodi);

  const [rows, setRows] = useState([]);
  const [index, setIndex] = useState(0);

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = () => {
    setCurrentAction("add");
    setCurrentId(lastId);
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    if (state.Fature_Shitje_Id !== null) {
      setShowConfirmation(true); // Show the confirmation dialog
    } else {
      // setFiskalDisable(true)
      setCurrentAction("modify");
      setDisabled(false);
      setButtonClicked(true);
    }
  };

  // Function to handle confirmation
  const handleConfirmation = (confirm) => {
    setShowConfirmation(false); // Hide the confirmation dialog
    if (confirm) {
      setDisabled(false);
      setButtonClicked(true);
      // setFiskalDisable(true)
      setCurrentAction("modify");
    }
  };

  //Ploteson fushat e tjera nqs Zgjedh nje X-klient
  //Dmth nqs zgjedh F003 => NIPT (L61918004H)
  //F003 => klient (Ahmetaj )
  const handleKlientiType = async (e, value) => {
    setSearchString(value ? value.Kodi : "");
    if (value && value.Kodi) {
      try {
        const selectedKlienti = klientiRedux.find(
          (item) => item.Kodi === value.Kodi
        );

        if (selectedKlienti) {
          setState((state) => ({
            ...state,
            KLFU_Kodi: selectedKlienti.Kodi,
            KLFU_Pershkrim: selectedKlienti.Pershkrim,
          }));
        } else {
          // If selectedKlienti is not found, clear the text fields
          setState((state) => ({
            ...state,
            KLFU_Kodi: value,
            KLFU_Pershkrim: "",
          }));
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      // If value is empty or value.Kodi is falsy, clear all fields
      setState((state) => ({
        ...state,
        KLFU_Kodi: "",
        KLFU_Pershkrim: "",
      }));
    }
  };

  const toast = useRef(null);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 2000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const fiskalizo = async () => {
    try {
      const response = await mainAxios.post(`/flete_dalje/fis/${currentId}`);
      const firstItem = response.data;

      if (response.status === 200) {
        if (response.data?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        }
        if (response.data?.ErrorCode === "0") {
          toast.current.show({
            severity: "success",
            summary: "Flete Dalje u fiskalizua !",
          });
          await fetchFDalje(1);
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Nuk u fiskalizua.",
        });
      }
    } catch (error) {
      console.error("Error fetching new_kodi_fsh data:", error);
    }
  };

  const columnsSF = [
    { field: "Id", title: "Id" },
    { field: "Kodi", title: "Kodi" },
    { field: "Data", title: "Data" },
    { field: "KLFU_Kodi", title: "Shenim 1" },
    { field: "KLFU_Pershkrim", title: "Shenim 2" },
    { field: "Vlera", title: "Vlera" },
    { field: "Operator", title: "Operator" },
  ];

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "NrSerik", title: "NrSerik" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Shenim_1", title: "Shenim 1" },
    { field: "Shenim_2", title: "Shenim 2" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
    { field: "Cmimi_fiskal", title: "Cmimi Fiskal" },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Departamenti", title: "Departamenti" },
    { field: "Magazina_Kodi", title: "Magazina_Kodi" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      setDataCurrentOffline(`${formattedDate}T00:00:00`);
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };

  let defaultState = {
    Aktiv: 1,
    Kodi_ORG: "",
    Magazina_Kodi: defaultMag[0],
    FLH_Magazina_Kodi: defaultMag[1],
    Data: "",
    Kodi: "",
    Veprimi: "",
    Shenim_1: "",
    Shenim_2: "",
    Targa_e_Mjetit: "",
    Transportuesi_Nipt: "",
    Transportuesi_Adresa: "",
    Transportuesi_Pershkrim: "",
    Transport_Shenim: "",
    Transport_Data: "",
    KLFU_Pershkrim: "",
    KLFU_Kodi: "",
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);
  const [number, setNumber] = useState(1);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);

  useEffect(() => {
    if (fromFatureShitje) {
      setNumber(nmrFSh);
      fetchFDalje(nmrFSh);
    } else {
      fetchFDalje(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFDalje = async (number) => {
    try {
      showLoading();
      const response = await mainAxios(`/last/faturafd/${number}`);
      const responseTotalRows = await dispatch(getIndexFirstFd("/first/fd"));
      const total_data_num = responseTotalRows.payload.total_rows;
      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      handleChangeValue(response.data.Id);

      if (response.data.fisFwtnic === null || response.data.fisFwtnic === "") {
        setFatureDisabled(false);
      } else {
        setFatureDisabled(true);
      }

      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      const responseData = response.data;
      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(
          `/flete_dalje/dtl/${responseData.Id}`
        );
        const dtlData = dtlResponse.data;

        setState(responseData);
        setResponseData(responseData);
        setLastObject(responseData);
        setFisVerificationUrl(responseData.fisVerificationUrl);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);

    if (type === "first") {
      setNumber(firstFleteDIndex.total_rows);
      fetchFDalje(firstFleteDIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFDalje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFDalje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }
    if (type === "last") {
      setNumber(1);
      fetchFDalje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/flete/dalje/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFDalje(numberIndex);

        showToast("Flete Dalja u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e flete daljes.", { severity: "error" });

      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (!state.Magazina_Kodi) {
      showToast("Cakto Magazinen!", { hideProgressBar: true });
      return;
    }

    // if (!state.FLH_Magazina_Kodi) {
    //   showToast("Cakto FLH Magazinen!", { hideProgressBar: true });
    //   return;
    // }

    if (state.FLH_Magazina_Kodi === state.Magazina_Kodi) {
      showToast("Magazinat duhet te jene te ndryshme !", {
        hideProgressBar: true,
      });
      return;
    }

    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/flete_dalje/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleAdd();
      setCurrentAction(null);
    } else if (currentAction === "modify") {
      handleModify();
      setCurrentAction(null);
    }
  };

  // Kur Klikohet butoni i "SHTIM"
  const handleAdd = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Aktiv: row.Aktiv,
          Cmimi: row.Cmimi,
          CmimiPeshaKG: row.CmimiPeshaKG,
          DataSk: row.DataSk,
          Dep_Kodi: row.Dep_Kodi,
          Inserted: row.Inserted,
          Kodi: row.Kodi,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          Kodi3: row.Kodi3,
          Kodi4: row.Kodi4,
          Kodi5: row.Kodi5,
          Koeficient: row.Koeficient,
          Master_Id: newEntryID,
          Njesi_Kodi: row.Njesi_Kodi,
          NrSerik: row.NrSerik,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Origjina_ID: row.Origjina_ID,
          Pershkrim: row.Pershkrim,
          PeshaKg: row.PeshaKg,
          Sasia: row.Sasia,
          SasiaPak: row.SasiaPak,
          Selektuar: row.Selektuar,
          TipPaketimi_Kodi: row.TipPaketimi_Kodi,
          Updated: row.Updated,
          Volumi: row.Volumi,
          BarKod: row.BarKod,
          Vlera: row.Vlera,
        };
        return item;
      });
    };
    try {
      showLoading();

      // if (!isOnline) {
      //   storeOfflineRequest({
      //     url: "/flete_dalje",
      //     body: {
      //       ...state,
      //       Data: formatDate(state.Data, true),
      //     },
      //     rows: rows.map((row) => ({
      //       Aktiv: row.Aktiv,
      //       Cmimi: row.Cmimi,
      //       CmimiPeshaKG: row.CmimiPeshaKG,
      //       DataSk: row.DataSk,
      //       Dep_Kodi: row.Dep_Kodi,
      //       Inserted: row.Inserted,
      //       Kodi: row.Kodi,
      //       Kodi1: row.Kodi1,
      //       Kodi2: row.Kodi2,
      //       Kodi3: row.Kodi3,
      //       Kodi4: row.Kodi4,
      //       Kodi5: row.Kodi5,
      //       Koeficient: row.Koeficient,
      //       Njesi_Kodi: row.Njesi_Kodi,
      //       NrSerik: row.NrSerik,
      //       Operator: row.Operator,
      //       OperatorInsert: row.OperatorInsert,
      //       Origjina_ID: row.Origjina_ID,
      //       Pershkrim: row.Pershkrim,
      //       PeshaKg: row.PeshaKg,
      //       Sasia: row.Sasia,
      //       SasiaPak: row.SasiaPak,
      //       Selektuar: row.Selektuar,
      //       TipPaketimi_Kodi: row.TipPaketimi_Kodi,
      //       Updated: row.Updated,
      //       Volumi: row.Volumi,
      //       BarKod: row.BarKod,
      //       Vlera: row.Vlera,
      //     })),
      //   });
      //   // Inform the user that the request will be sent when online
      //   showToast("Request will be sent when online.");
      //   setState(defaultState);
      //   setDisabled(true);
      //   setButtonClicked(false);
      //   return;
      // }

      //  POST request i 1 per Flete Dalje
      const response = await mainAxios.post(`/flete_dalje`, {
        Aktiv: state.Aktiv,
        Kodi_ORG: state.Kodi_ORG,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        Kodi: Number(state.Kodi),
        Veprimi: state?.Veprimi,
        Shenim_1: state.Shenim_1,
        FLH_Magazina_Kodi: state.FLH_Magazina_Kodi,
        Shenim_2: state.Shenim_2,
        Operator: String(username),
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Transportuesi_Nipt: state.Transportuesi_Nipt,
        Targa_e_Mjetit: state.Targa_e_Mjetit,
        Transportuesi_Adresa: state.Transportuesi_Adresa,
        Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
        Transport_Shenim: state.Transport_Shenim,
        Transport_Data: formatDate(state.Transport_Data, true),
        KLFU_Kodi: state.KLFU_Kodi,
        fisIsGoodsFlammable: Number(state.fisIsGoodsFlammable),
        fisIsEscortRequired: Number(state.fisIsEscortRequired),
        fisWtnType: Number(state.fisWtnType),
        fisTransaction: Number(state.fisTransaction),
        fisStartPoint: Number(state.fisStartPoint),
        fisStartAddress: state.fisStartAddress,
        fisStartCity: state.fisStartCity,
        fisDestinationPoint: Number(state.fisDestinationPoint),
        fisDestinationAddress: state.fisDestinationAddress,
        fisDestinationCity: state.fisDestinationCity,
        fisStartDateTime: formatDate(state.fisStartDateTime, true),
        fisDestinationDateTime: formatDate(state.fisDestinationDateTime, true),
        fisPackType: state.fisPackType,
      });
      if (response.status === 201) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        toast.current.show({
          severity: "success",
          summary: "Flete Dalja u rregjistrua.",
        });
        // POST request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        const responseDtl = await mainAxios.post(
          `/flete_dalje/dtl/bulk`,
          dtlDataArray
        );
        if (responseDtl.status === 200) {
          fetchFDalje(1);

          setCurrentAction(null);
          toast.current.show({
            severity: "success",
            summary: "Flete Dalja u rregjistrrua!",
          });
        }
      }
    } catch (error) {
      console.error("Error in POST request:", error);

      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë regjistrimit të flete daljes.",
      });
    } finally {
      hideLoading();
    }
  };

  // Kur Klikohet butoni i "Modifikim"
  const handleModify = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Aktiv: row.Aktiv,
          Cmimi: row.Cmimi,
          CmimiPeshaKG: row.CmimiPeshaKG,
          DataSk: row.DataSk,
          Dep_Kodi: row.Dep_Kodi,
          Inserted: row.Inserted,
          Kodi: row.Kodi,
          Id: row.Id,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          Kodi3: row.Kodi3,
          Kodi4: row.Kodi4,
          Kodi5: row.Kodi5,
          Koeficient: row.Koeficient,
          Master_Id: newEntryID,
          Njesi_Kodi: row.Njesi_Kodi,
          NrSerik: row.NrSerik,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Origjina_ID: row.Origjina_ID,
          Pershkrim: row.Pershkrim,
          PeshaKg: row.PeshaKg,
          Sasia: row.Sasia,
          SasiaPak: row.SasiaPak,
          Selektuar: row.Selektuar,
          TipPaketimi_Kodi: row.TipPaketimi_Kodi,
          Updated: row.Updated,
          Volumi: row.Volumi,
          BarKod: row.BarKod,
          Vlera: row.Vlera,
        };
        return item;
      });
    };
    try {
      showLoading();
      //  Put request i 1 per FATURE SHITJE
      const response = await mainAxios.put(`/flete_dalje/${currentId}`, {
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Transportuesi_Nipt: state.Transportuesi_Nipt,
        Targa_e_Mjetit: state.Targa_e_Mjetit,
        Transportuesi_Adresa: state.Transportuesi_Adresa,
        Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
        Transport_Shenim: state.Transport_Shenim,
        Transport_Data: formatDate(state.Transport_Data, true),
        KLFU_Kodi: state.KLFU_Kodi,
        Aktiv: state.Aktiv,
        Kodi_ORG: state.Kodi_ORG,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        FLH_Magazina_Kodi: state.FLH_Magazina_Kodi,
        Kodi: Number(state.Kodi),
        Veprimi: state?.Veprimi,
        Shenim_1: state.Shenim_1,
        Shenim_2: state.Shenim_2,
        Operator: String(username),
        fisIsGoodsFlammable: Number(state.fisIsGoodsFlammable),
        fisIsEscortRequired: Number(state.fisIsEscortRequired),
        fisWtnType: Number(state.fisWtnType),
        fisTransaction: Number(state.fisTransaction),
        fisStartPoint: Number(state.fisStartPoint),
        fisStartAddress: state.fisStartAddress,
        fisStartCity: state.fisStartCity,
        fisDestinationPoint: Number(state.fisDestinationPoint),
        fisDestinationAddress: state.fisDestinationAddress,
        fisDestinationCity: state.fisDestinationCity,
        fisStartDateTime: formatDate(state.fisStartDateTime, true),
        fisDestinationDateTime: formatDate(state.fisDestinationDateTime, true),
        fisPackType: state.fisPackType,
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        toast.current.show({
          severity: "success",
          summary: "Flete dalje u rregjistrua.",
        });
        // Put request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        const responseDtl = await mainAxios.put(
          `/flete_dalje/dtl/bulk`,
          dtlDataArray
        );
        if (responseDtl.status === 200) {
          setRows([]);
          toast.current.show({
            severity: "success",
            summary: "Artikujt u regjistrruan.",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gabim gjatë regjistrimit të artikujve.",
        });
      }
    } catch (error) {
      console.error("Error in POST request:", error);

      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë regjistrimit të flete dalje.",
      });
    } finally {
      fetchFDalje(number);
      // fetchDtl();
      hideLoading();
    }
  };

  const handleMagazinaChange = (event, newValue) => {
    if (newValue) {
      updateMagazinaKodi(newValue);
    }
  };

  const updateMagazinaKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Magazina_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;

    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      fatureDisabled={fatureDisabled}
      fleteName={"Flete Dalje"}
      fatureName={"Fature Shitje"}
      popupContextKeyType={true}
      popupContextKey="FleteDalje"
      contexDelete={"flete daljen"}
      onAddClick={onAddClick}
      onModifyClick={onModifyClick}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFDalje={fetchFDalje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      number={number}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );
  const button6Text = isMobile ? "" : "Tjera";
  const button7Text = isMobile ? "" : "Klient";
  const button8Text = isMobile ? "" : "Transp";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      includeButton7={true}
      includeButton8={true}
      disabled={disabled}
      setDisabled={setDisabled}
      // fetchLastNumberFature={fetchLastNumberFature}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      button7Text={button7Text}
      button7Icon={<PeopleIcon />}
      button8Text={button8Text}
      button8Icon={<DirectionsBusIcon />}
    />
  );

  const TabiKryesor = (
    <MainTab
      formatDate={formatDate}
      includeDateField={true}
      includeNrOrigjinesField={true}
      includeKursiField={false}
      includeMonedhaSelect={false}
      includeKodiField={true}
      includeSerialNumerField={true}
      includeModalList={true}
      includeMagazina={true}
      disabled={disabled}
      state={state}
      handleMagazinaChange={handleMagazinaChange}
      magazina={magazina}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
      numMag={numMag}
    />
  );

  const BtnPrint = (
    <PrintoPdfFleteDalje
      className="print"
      state={state}
      rows={rows}
      fisVerificationUrl={fisVerificationUrl}
    />
  );
  const BtnFiskal = (
    <Button
      size="small"
      variant="contained"
      style={{
        ...(isMobile
          ? {
              height: "1.7rem",
              width: "4rem",
            }
          : {}),
        display: "flex",
      }}
      disabled={buttonClicked || fatureDisabled}
      onClick={fiskalizo}
    >
      {isMobile || "Fiskalizo"}
      <PointOfSaleIcon fontSize="small" />
    </Button>
  );
  const smallTabsKM = (
    <div>
      <div
        className={toggleState === 1 ? "content  active-content" : "content"}
      >
        <div className="grid grid-cols-2 gap-2">
          <Form.Select
            style={{ backgroundColor: "#fff" }}
            disabled={disabled}
            label="Veprimi"
            value={state?.Veprimi}
            onChange={(e) => {
              handleChange("Veprimi", e.target.value);
            }}
          >
            <option label="Veprim" />
            {veprimi.map((kodiValue) => {
              return (
                <option key={kodiValue} value={kodiValue}>
                  {kodiValue}
                </option>
              );
            })}
          </Form.Select>
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 1"
            value={state?.Shenim_1 || ""}
            onChange={(e) => handleChange("Shenim_1", e.target.value)}
            size="small"
          />

          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 2"
            value={state?.Shenim_2 || ""}
            onChange={(e) => handleChange("Shenim_2", e.target.value)}
            size="small"
          />

          <div className=" flex items-center justify-center rounded-tr-lg rounded-br-lg">
            {/* //////////// */}
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-demo13"
              options={magazina}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : null)}
              onChange={(e, newValue) =>
                handleChange(
                  "FLH_Magazina_Kodi",
                  newValue ? newValue.Kodi : null
                )
              }
              value={
                magazina.find(
                  (qytet) => qytet.Kodi === state?.FLH_Magazina_Kodi
                ) || null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Magazina Destinacion" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
            {/* ///////// MiniModal */}
            <div className="transition-all hover:scale-110">
              <Magazina numMag={numMag} magazina={magazina} />
            </div>
          </div>
        </div>
      </div>

      <TabKlienti
        fleteDalje={true}
        qytetKlienti={false}
        shenim={false}
        toggleState={toggleState === 2}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
        setSearchString={setSearchString}
        searchString={searchString}
        handleKlientiType={handleKlientiType}
        klienti={klienti}
        qytetet={qytetet}
      />
      {/* tab2 */}

      <TabTrans
        kontextKey="FleteDalje"
        formatDate={formatDate}
        toggleState={toggleState === 3}
        disabled={disabled}
        state={state}
        setState={setState}
        includeShenim={true}
        handleChange={handleChange}
        fetchTShitje={fetchFDalje}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh">
      <Toast ref={toast} />
      <NavBar currentAction={currentAction} />
      <div className={isMobile ? null : "template"}>
        <div className="main-container p-2">
          <div className="top_panel_fsh">
            <Panel
              headerTemplate={
                <Template
                  options={{
                    collapsed: isPanelToggleable,
                    onTogglerClick: () =>
                      setIsPanelToggleable(!isPanelToggleable),
                  }}
                  isMobile={isMobile}
                  fatureName="Flete Dalje"
                  currentId={currentId}
                  nxtBtnDisable={nxtBtnDisable}
                  prvBtnDisable={prvBtnDisable}
                  shikoFaturen={shikoFaturen}
                  handleTestiChange={handleTestiChange}
                  columnsSF={columnsSF}
                  contextKey={"flete/dalje"}
                  visible={visible}
                  setVisible={setVisible}
                  gridKey="FleteDalje"
                />
              }
              toggleable
              collapsed={isPanelToggleable}
              onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
              style={{ height: "100%" }}
            >
              <div className="bg-white">
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve">
                      <div className="paneli-tabeve1">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2">{PaneliDytesor}</div>
                      <div className="paneli-fis-print">
                        {BtnPrint}

                        {BtnFiskal}
                      </div>
                    </div>
                    <div className={"below-tabs"}>
                      <div className="below-tabs1">{TabiKryesor}</div>
                      <div className="below-tabs2">{smallTabsKM}</div>
                      <div className="below-tabs3"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                      <div style={{ display: "flex" }}>{BtnFiskal}</div>
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                      {BtnPrint}
                    </div>
                    <div
                      style={{
                        margin: 10,
                      }}
                    >
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>
          <div className={!isMobile ? "middle_fsh" : null}>
            <RightBar
              contextKeyFleteDalje={true}
              openRight={rightDrawerOpen}
              onCloseRight={handleCloseRightDrawer}
              state={state}
              formatDate={formatDate}
              setState={setState}
              disabled={disabled}
              setDisabled={setDisabled}
              currentId={currentId}
              handleChange={handleChange}
              fisVerificationUrl={fisVerificationUrl}
              fetchFDalje={fetchFDalje}
              buttonClicked={buttonClicked}
              fatureDisabled={fatureDisabled}
              prvBtnDisable={prvBtnDisable}
            />
          </div>

          <div className="dataTable_fsh">
            <PrimeGrid
              deletedRowIds={deletedRowIds}
              setDeletedRowIds={setDeletedRowIds}
              gridKey="FleteDalje"
              contextKey={"/flete_dalje/dtl"}
              isEditable={true}
              columns={columns}
              setColumns={setColumns}
              disabled={disabled}
              rows={rows}
              defaultColumnsStorage={defaultColumnsStorage}
              setRows={setRows}
              calculateData={handleCalculateData}
              shtoArtikull={handleShtoArtikull}
              currentAction={currentAction}
              currentId={currentId}
            />
          </div>
        </div>
        <ConfirmationDialog
          fleteName={"Flete Dalje"}
          fatureName={"Fature Shitje"}
          showConfirmation={showConfirmation}
          handleConfirmation={handleConfirmation}
          onClose={() => setShowConfirmation(false)}
        />
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(FleteDaljePrv);
