import React from "react";
import Tavolina from "./Main/TavolinatPrv";

const Restaurant = ({
  userAutomatikisht,
  setUserAutomatikisht,
  isOnline,
  // storeOfflineRequest,
  isMobile,
}) => {
  return (
    <Tavolina
      isMobile={isMobile}
      userAutomatikisht={userAutomatikisht}
      setUserAutomatikisht={setUserAutomatikisht}
      isOnline={isOnline}
      // storeOfflineRequest={storeOfflineRequest}
    />
  );
};

export default Restaurant;
