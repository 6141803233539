import "./print.scss";

function SimpleTableB({ state, perdorues }) {
  return (
    <table className="HeaderTable border">
      <thead>
        <tr className="funtior_table_head">
          <td>
            <tr className="solid-border">Nr.Serial: {state.NrSerik}</tr>
          </td>

          <td className="title1" colSpan={8}>
            <b>Fature Blerje</b>
            <br />
            <b>NR: {state.Kodi}</b>
          </td>

          <td>
            <tr className="solid-border">
              Data : {state.Data.substring(0, 10)}
            </tr>
            <tr className="solid-border">Nr. i Fatures: {state.Kodi}</tr>
          </td>
        </tr>

        <tr className="funtior_table_head">
          {/* Furnitori */}
          <td className="td_shitesi">
            <tr className="solid-border"> Shitesi: {state.KLFU_Pershkrim}</tr>
            <tr className="solid-border"> NIPT: {state.NIPT}</tr>
            <tr className="solid-border"> Adresa: {state.Qyteti_Kodi}</tr>
          </td>

          {/* Bleresi */}
          {/* <td className="td_bleresi">
            <tr className="solid-border">
              Bleresi: {perdorues.Ndermarrje_Sel}
            </tr>
            <tr className="solid-border">NIPT: {perdorues.fisBusinessNIPT}</tr>
            <tr className="solid-border">Adresa:{perdorues.Adresa}</tr>
          </td> */}
        </tr>
      </thead>
    </table>
  );
}

export default SimpleTableB;
