import React, { useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "./Faturat.scss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CachedIcon from "@mui/icons-material/Cached";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 30,
  width: "100%",
  color: theme.palette.text.secondary,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Title = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#1976d2",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 30,
  width: "100%",
  color: "#fff",
  position: "sticky",
  top: 0,
  marginBottom: 5,
  zIndex: 1000,
}));

export default function Faturat({
  currentId,
  handleItemClick,
  itemsData,
  fetchPosSupermarket,
}) {
  return (
    <div className="flex main_div_faturat" style={{ flexDirection: "column" }}>
      <Title
        className="title_faturat"
        style={{
          backgroundColor: "#9EB8D9",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          fetchPosSupermarket();
        }}
      >
        <CachedIcon style={{ color: "#fff" }} />
        <h3>Faturat e Fundit</h3>
      </Title>
      <Box
        className="center-content"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {itemsData.length > 0 ? (
            itemsData.map((item, index) => (
              <Grid key={item} item xs={12} sm={12} md={12} lg={12}>
                <Item onClick={() => handleItemClick(item)}>
                  <div>{index + 1}</div>
                  <div>
                    Id: {item} &nbsp;
                    <RemoveRedEyeIcon
                      style={{ color: item === currentId ? "red" : "" }}
                    />
                  </div>
                </Item>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <p>S'ka Fatura</p>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}
