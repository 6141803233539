import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import "../print.scss";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useReactToPrint } from "react-to-print";

import DynamicTableFD from "./dynamicTable";
import SimpleTableFD from "./simpletable";

import DynamicTableFdShoqeruese from "./dynamicTableShoqeruese";
import SimpleTableFdShoqeruese from "./simpletableShoqeruese";

const PrintoPdfFleteDalje = ({ rows, state, fisVerificationUrl }) => {
  const componentsRef = useRef(null);
  const componentsRef2 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentsRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button {...bindTrigger(popupState)}>
              <PrintSharp />
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                style={{
                  padding: "1rem",
                  display: "grid",
                  flexDirection: "row",
                }}
              >
                <Button
                  style={{ color: "#1971c2", cursor: "pointer", margin: "5px" }}
                  onClick={handlePrint}
                >
                  {" "}
                  <div style={{ fontSize: "12px" }}>Printo Flete Dalje</div>
                  <PrintSharp style={{ blockSize: "17px" }} />{" "}
                </Button>
                <Button
                  style={{ color: "#1971c2", cursor: "pointer", margin: "5px" }}
                  onClick={handlePrint2}
                >
                  {" "}
                  <div style={{ fontSize: "12px" }}>
                    Printo Fature Shoqeruese
                  </div>
                  <PrintSharp style={{ blockSize: "17px" }} />{" "}
                </Button>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      <div ref={componentsRef} className="print ">
        <SimpleTableFD state={state} fisVerificationUrl={fisVerificationUrl} />
        <DynamicTableFD rows={rows} state={state} />
      </div>

      <div ref={componentsRef2} className="print ">
        <SimpleTableFdShoqeruese
          state={state}
          fisVerificationUrl={fisVerificationUrl}
        />
        <DynamicTableFdShoqeruese rows={rows} state={state} />
      </div>
    </>
  );
};

export default PrintoPdfFleteDalje;
