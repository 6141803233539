import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div className="not-found-container">
      <h2>Oops! You seem to be lost.</h2>
      <h1 style={{ color: "#1976d2" }}>404</h1>
      <div className="flex">
        <h1>Go </h1>
        <Button variant="contained" component={Link} to="/login">
          Login
        </Button>
      </div>
    </div>
  );
}
