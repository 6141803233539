import * as React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -8,
    top: 1,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function IconShopping({ clickedItems }) {
  const artLength = clickedItems.length;
  return (
    <IconButton aria-label="cart">
      <StyledBadge badgeContent={artLength} color="error">
        <ShoppingCartIcon
          style={{ width: "20px", height: "20px", color: "#FFf" }}
        />
      </StyledBadge>
    </IconButton>
  );
}
