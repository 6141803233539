import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print80Skontrino.scss";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "N/A";
};

const DynamicXhiro = ({ rows, selectedOperator, dataNga }) => {
  const DisplayData1 = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="columnsPershkrim">{info.PERSHKRIM}</td>
        <td className="columnsPershkrim">{info.SASIA} x</td>
        <td className="columnsVlera">{formatNumber(info.VLERA)}</td>
      </tr>
    );
  });

  const sums = {
    VLERA: formatNumber(rows.reduce((total, info) => total + info.VLERA, 0)),
  };
  return (
    <table className="hide-border">
      <thead>
        <tr>
          <td className="tds-footer" colSpan={4}>
            Data: {dataNga}
          </td>
        </tr>
        <tr
          className="table-head"
          style={{
            textAlign: "center",
          }}
        >
          <th className="th solid-line">Pershkrim</th>
          <th className="th solid-line">Sasia</th>
          <th className="th solid-line">Vlera</th>
        </tr>
      </thead>

      <tbody>{DisplayData1}</tbody>
      <tr>
        <td colSpan={3}>
          <div className="div_line"></div>
        </td>
      </tr>

      <tr>
        <td colSpan={3}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tr>
        <td className="columnsTotal" colSpan={1}>
          <h2>Total</h2>
        </td>
        <td
          className="columnsTotal columnsvleracenterXhiroTurniPOS"
          colSpan={2}
        >
          <h2>{sums.VLERA}</h2>
        </td>
      </tr>
    </table>
  );
};

export default DynamicXhiro;
