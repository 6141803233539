import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import "../FatureBlerje.scss";

const PaneliTab2 = ({
  includeButton6,
  includeButton7,
  includeButton8,
  includeButton9,
  includeButton10,
  includeButton11,
  includeButton12,
  disabled,
  toggleState,
  toggleTab,
  button6Icon,
  button6Text,
  button7Icon,
  button7Text,
  button8Icon,
  button8Text,
  button9Icon,
  button9Text,
  button10Icon,
  button10Text,
  button11Icon,
  button11Text,
  button12Text,
  button12Icon,
  isFromCelje,
}) => {
  return (
    <ButtonGroup className={"mb-2"} style={{ width: "100%" }}>
      {includeButton6 && (
        <Button
          style={{ width: isFromCelje ? "30%" : "20%" }}
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          {button6Icon} {button6Text}
        </Button>
      )}
      {includeButton7 && (
        <Button
          style={{ width: isFromCelje ? "30%" : "20%" }}
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          {button7Icon} {button7Text}
        </Button>
      )}
      {includeButton8 && (
        <Button
          style={{ width: "20%" }}
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          {button8Icon} {button8Text}
        </Button>
      )}
      {includeButton9 && (
        <Button
          style={{ width: "20%" }}
          className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(4)}
        >
          {button9Icon} {button9Text}
        </Button>
      )}
      {includeButton10 && (
        <Button
          style={{ width: "20%" }}
          className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(5)}
        >
          {button10Icon} {button10Text}
        </Button>
      )}
      {includeButton11 && (
        <Button
          style={{ width: "20%" }}
          className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(6)}
        >
          {button11Icon} {button11Text}
        </Button>
      )}

      {/* Buttoni i bankes Check */}
      {includeButton12 && (
        <Button
          style={{ width: "20%" }}
          className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(7)}
          disabled={disabled}
        >
          {button12Icon} {button12Text}
        </Button>
      )}
    </ButtonGroup>
  );
};

export default PaneliTab2;
