import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function OperatorList({
  onSelectOperator,
  operators,
  setSelectedOperator,
  selectedOperator,
}) {
  const handleChange = (event) => {
    const selectedOperatorId = event.target.value;
    setSelectedOperator(selectedOperatorId);

    // Call the callback function with the selected operator
    onSelectOperator(selectedOperatorId);
  };

  return (
    <div
      className="flex"
      style={{
        width: "30%",
        margin: "4.5px 5px 2px 3px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "95%" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Operator</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedOperator}
            label="Operator"
            onChange={handleChange}
          >
            <MenuItem value={null}>None</MenuItem>
            {Array.isArray(operators) &&
              operators.map((operator) => (
                <MenuItem key={operator.Id} value={operator.Operator}>
                  {operator.Operator}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
