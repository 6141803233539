import React, { useState } from "react";
import "./NavStyles/SideBar.scss";
import { PanelMenu } from "primereact/panelmenu";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
// import { Dialog } from "primereact/dialog";
import nodes from "./SideBarItems/SideBarArray";
import ReportDialog from "./ReportDialog";
import ArkaDialog from "./DeklarimiArkes";
import { useNavigate } from "react-router-dom";
import Xhiro from "../../pages/MbylljeTurni/Xhiro";

const SideBar = () => {
  const [search, setSearch] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogText, setText] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showXhiro, setShowXhiro] = useState(false);
  // const [dialogIndex, setDialogIndex] = useState("");
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [dialogRaportButtonsKeys, setDialogRaportButtonsKeys] = useState([]);
  const [dialogArkaButtonsKeys, setDialogArkaButtonsKeys] = useState([]);
  const navigate = useNavigate();

  const handleButtonClick = (element) => {
    if (element.RaportButtons) {
      // setDialogIndex(element.index);
      setDialogContent(element.buttonRaport);
      setText(element.text);
      setDialogRaportButtonsKeys(element.RaportButtons.map((rb) => rb.key));
      setSelectedDialog("ReportDialog");
      openDialog(element.buttonRaport, element.RaportButtons);
      setDialogVisible(true);
    }
    if (element.ArkaButtons) {
      // setDialogIndex(element.index);
      setDialogContent(element.buttonRaport);
      setDialogArkaButtonsKeys(element.ArkaButtons.map((ab) => ab.key));
      setSelectedDialog("ArkaDialog");
      openDialog(element.buttonRaport, element.ArkaButtons);
      setDialogVisible(true);
    }
  };

  const openDialog = (content, raportButtonsKeys) => {
    setDialogContent(content);
    setDialogRaportButtonsKeys(raportButtonsKeys.map((rb) => rb.key));
    setDialogVisible(true);
  };

  const searchTree = (element, matchingTitle) => {
    if (element.label.toLowerCase().includes(matchingTitle)) {
      return element;
    } else if (element?.items != null) {
      let matchedItems = [];
      element.items.forEach((item) => {
        const result = searchTree(item, matchingTitle);
        if (result) matchedItems.push(result);
      });
      if (matchedItems.length > 0) {
        return { ...element, items: matchedItems };
      }
    }
    return null;
  };

  const generateMenuModel = (items) => {
    return items.map((item) => {
      if (item.label === "Xhiro") {
        return {
          ...item,
          command: () => handleXhiroClick(),
        };
      }
      if (item.buttonRaport) {
        return {
          ...item,
          command: () => handleButtonClick(item),
        };
      } else if (item.path) {
        return {
          ...item,
          command: () => navigate(`${item.path}`),
        };
      } else if (item.items) {
        return {
          ...item,
          items: generateMenuModel(item.items),
        };
      }
      return item;
    });
  };

  const filteredNavLinks = search
    ? nodes
        .map((item) => searchTree(item, search.toLowerCase()))
        .filter(Boolean)
    : nodes;

  const menuModel = generateMenuModel(filteredNavLinks);

  const handleXhiroClick = () => {
    setShowXhiro(true);
  };

  return (
    <div className="sidebar">
      <ScrollPanel style={{ width: "100%", height: "85vh" }}>
        <div className="mb-2 border-b p-2">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div>

        <PanelMenu model={menuModel} />
      </ScrollPanel>
      {showXhiro && (
        <Xhiro
          homeNavbar={true}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          open={showXhiro}
          onClose={() => setShowXhiro(false)}
        />
      )}
      {selectedDialog === "ReportDialog" ? (
        <ReportDialog
          buttonRaport={dialogContent}
          text={dialogText}
          visible={dialogVisible}
          // index={dialogIndex}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogRaportButtonsKeys}
        />
      ) : (
        <ArkaDialog
          visible={dialogVisible}
          // index={dialogIndex}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogArkaButtonsKeys}
        />
      )}
    </div>
  );
};

export default SideBar;
