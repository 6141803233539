import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { Toast } from "primereact/toast";
import { ContextMenu } from "primereact/contextmenu";
import useStorage from "../../../../hooks/useStorage";
import { Tooltip } from "primereact/tooltip";
import mainAxios from "../../../../services/axios";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";

// Component responsible for rendering a PrimeReact DataTable
const TableListaFaturave = ({
  rows,
  setData2,
  setSelectedId,
  setDataCheck,
  setState,
  setFaturaNIVF,
}) => {
  const [columns] = useState([
    { field: "ID", title: "ID" },
    { field: "KODI", title: "KODI" },
    { field: "TAVOLINA", title: "TAVOLINA" },
    { field: "VLERA", title: "VLERA", allowSum: true },
    { field: "OPERATOR", title: "OPERATOR" },
    // { field: "KLFU_KODI", title: "KODI KL" },
    { field: "KLFU_PERSHKRIM", title: "KLIENTI" },
    { field: "TipPrintimi", title: "Tip i Printimit" },
    { field: "VleraPaguar", title: "Vlera Paguar" },
    // { field: "NSLF", title: "NSLF" },
    // { field: "NIVF", title: "NIVF" },
    { field: "TIPI", title: "TIPI" },
    { field: "DATA", title: "DATA" },
    { field: "Hapur", title: "Hapur" },
    { field: "Mbyllur", title: "Mbyllur" },
    // { field: "UPDATED", title: "UPDATED" },
  ]);

  const [firstRowSelected, setFirstRowSelected] = useState(false);

  // Effect to select the first row once
  useEffect(() => {
    if (rows && rows.length > 0 && !firstRowSelected) {
      const firstRow = rows[0];
      handleRowClick(firstRow);
      setFirstRowSelected(true);
    }
  }, [rows, firstRowSelected]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const gridKey = "listaFaturave";

  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );

  // Function to export Excel
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rows);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "rows");
    });
  };

  // Function to save Excel file
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div></div>
      <div>
        <Button
          disabled={!rows || rows.length === 0}
          onClick={exportExcel}
          icon="pi pi-file-excel"
          label="Export Excel"
          style={{ backgroundColor: "green", borderRadius: 20, border: "none" }}
        />
      </div>
    </div>
  );

  // Dynamically generate columns based on filteredColumns
  const dynamicColumns = columns?.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    return (
      <Column
        key={uniqueKey}
        style={{ height: "2.6rem" }}
        field={col?.field}
        header={col?.title}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData) => {
          const value = rowData[col?.field];
          let formattedValue;
          if (col.field === "Hapur" || col.field === "Mbyllur") {
            if (value) {
              const timePart = value.split(" ")[1]; // Split and get the time part
              formattedValue = timePart;
            } else {
              formattedValue = "";
            }
          } else if (col.field === "DATA") {
            if (value) {
              const datePart = new Date(value).toLocaleDateString(); // Get the date part
              formattedValue = datePart;
            } else {
              formattedValue = "";
            }
          } else {
            if (typeof value === "number") {
              if (Number.isInteger(value)) {
                formattedValue = value.toLocaleString();
              } else {
                formattedValue = value.toLocaleString();
                formattedValue = value.toFixed(2);
              }
            } else {
              formattedValue = value;
            }
          }
          return (
            <span
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </span>
          );
        }}
      />
    );
  });
  // Function to calculate column sum
  let columnSum = (field) => {
    let Total = 0;

    if (rows && Array.isArray(rows)) {
      for (let row of rows) {
        if (row[field]) {
          Total += Number(row[field]);
        }
      }
    }

    return Total > 0 ? Total.toFixed(2) : "0.00";
  };

  // Function to generate footer group
  const footerGroup = () => {
    let checkCols = columns?.some((column) => {
      return column?.allowSum;
    });
    if (!checkCols) return <></>;

    return (
      <ColumnGroup>
        <Row>
          {columns?.map((column, i) => {
            if (column?.allowSum) {
              const uniqueKey = `${column?.field}_${i}`;
              return (
                <Column
                  key={uniqueKey}
                  colSpan={1}
                  footer={() => columnSum(column?.field)}
                  footerStyle={{ textAlign: "right" }}
                />
              );
            }
            return <Column key={`empty_${i}`} colSpan={1} />;
          })}
        </Row>
      </ColumnGroup>
    );
  };

  let footerGr = footerGroup();

  const cm = useRef(null);
  const toast = useRef(null);

  // Event handler for column reorder
  const onColReorder = (event) => {
    const { dragIndex, dropIndex } = event;

    let columnsCopy = [...selectedColumns];
    const tempColumn = { ...selectedColumns[dragIndex] };
    columnsCopy[dragIndex] = selectedColumns[dropIndex];
    columnsCopy[dropIndex] = tempColumn;

    setselectedcolumns(columnsCopy);

    toast.current.show({
      severity: "success",
      summary: "Column Reordered",
      life: 3000,
    });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const dt = useRef(null);

  const [selectionRow, setSelectionRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const getPosDtl = async (selectedRowData) => {
    let currentSelectId = selectedRowData.ID;
    try {
      const responseDtl = await mainAxios.get(
        selectedRowData.TIPI === "_BAK"
          ? `/posdtlbak/master/${currentSelectId}`
          : `/posdtl/master/${currentSelectId}`
      );
      setState(selectedRowData);
      setDataCheck(selectedRowData);
      setData2(responseDtl.data);
      setSelectedId(currentSelectId);
      setFaturaNIVF(selectedRowData.NIVF);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (selectedRowData) => {
    setSelectionRow(selectedRowData);
    setSelectedRowData(selectedRowData);
    getPosDtl(selectedRowData);
  };

  // Return the rendered JSX
  return (
    <div className="dataTablePrimeGrid">
      <Toast ref={toast}></Toast>

      <ContextMenu ef={cm} />
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card">
        <div className="prime-react">
          <DataTable
            reorderablecolumnsdatatable="true"
            cellSelection
            selection={selectionRow}
            onSelectionChange={(e) => {
              const selectedRowData =
                e.value.length > 0 ? e.value[0].rowData : null;
              handleRowClick(selectedRowData);
            }}
            selectionMode="multiple"
            style={{ position: "relative" }}
            ref={dt}
            contextMenuSelection={selectedProduct}
            scrollable
            contextselection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onColReorder={onColReorder}
            resizableColumns
            columnResizeMode="fit"
            responsiveLayout="scroll"
            size="small"
            value={rows}
            stripedRows
            dataKey="Id"
            autoLayout={true}
            header={header}
            rowClassName={(rowData) =>
              selectedRowData && selectedRowData.ID === rowData.ID
                ? "selected-row"
                : ""
            }
            footerColumnGroup={footerGr}
            globalFilterFields={[
              "BarKod",
              "Pershkrim",
              "Njesi_Kodi",
              "Sasia_Print",
              "Sasia",
            ]}
            filterDisplay="menu"
            emptyMessage="Nuk ka te dhena"
          >
            {dynamicColumns.map((col) => {
              return React.cloneElement(col, {
                sortable: col.props.sortable,
                editor: col.props.editor,
              });
            })}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default TableListaFaturave;
