import React from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Departamenti from "../../minimodal/Departamenti";
import { useSelector } from "react-redux";

const TabTjera = ({
  toggleState,
  disabled,
  state,
  handleChange,
  includeButton5,
  formatDate,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  // redux departamenti (get requesti i Departamentit)
  const departamentiRedux = useSelector(
    (state) => state.globalSlice.departamenti
  );

  const departamenti = departamentiRedux;
  const numDep = departamenti.length;

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid w-72 gap-2 grid-cols-1">
        <div className="rounded-tr-lg rounded-br-lg flex justify-center items-center">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-qyteti15"
            options={departamenti}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Kodi + " - " + option.Pershkrim : ""
            }
            onChange={(e, newValue) =>
              handleChange("Dep_Kodi", newValue ? newValue.Kodi : "")
            }
            value={
              departamenti.find((qytet) => qytet.Kodi === state?.Dep_Kodi) ||
              null
            }
            size="small"
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Departamenti" />
            )}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />

          <div>
            <Departamenti numDep={numDep} />
          </div>
        </div>
        <div className="grid gap-2 grid-cols-1">
          <TextField
            fullWidth
            disabled={disabled}
            type="date"
            label="Data Deklarimit"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={formatDate(state?.Data_Deklarimit)}
            onChange={(e) => handleChange("Data_Deklarimit", e.target.value)}
            size="small"
          />
        </div>
        {includeButton5 && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                type="checkbox"
                size="small"
                checked={state?.Eksport === 1}
                onChange={(e) =>
                  handleChange("Eksport", e.target.checked ? 1 : 0)
                }
              />
            }
            label="Eksport"
          />
        )}
      </div>
    </div>
  );
};

export default TabTjera;
