import "../print.scss";

function SimpleTableFD({ state }) {
  return (
    <table className="HeaderTable border">
      <thead>
        <tr>
          <td className="title1" colSpan={8}>
            <b>Flete Dalje</b>
            <br />
            <b>NR: {state.Kodi}</b>
          </td>
        </tr>
      </thead>
      <th colSpan="11">
        <tr className="table table-striped bordered border">
          {/* Tabela 1 */}
          <tr className="th-square1">
            {/* Data Ora */}
            <td>
              <tr className="solid-border">Data : {state.Data}</tr>
            </td>
          </tr>

          {/* Bleresi */}
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <td>
              <br />
              <tr>
                {/* <tr className="solid-border">NIPT: {state.fisFwtnic}</tr> */}
                <tr className="solid-border">
                  {" "}
                  Magazina Qendrore: {state.Magazina_Kodi}
                </tr>

                <tr className="solid-border"> Shenim 1: {state.Shenim_1}</tr>
                <tr className="solid-border"> Shenim 2: {state.Shenim_2}</tr>

                {/* <tr className="solid-border">
                Targa Mjetit: {state.Targa_e_Mjetit}
              </tr> */}

                {/* Transportuesi */}
                {/* {state.Transportuesi_Pershkrim === "" ||
              state.Transportuesi_Pershkrim === null ? null : (
                <tr className="th-square2">
                  <td>
                    <tr className="solid-border">
                      {" "}
                      Transportuesi: {state.Transportuesi_Pershkrim}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      Adresa: {state.Transportuesi_Adresa}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      NIPT: {state.Transportuesi_Nipt}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      Targa: {state.Targa_e_Mjetit}
                    </tr>
                  </td>
                </tr>
              )} */}
              </tr>
            </td>
            <td>
              <br />
              <tr>
                {/* <tr className="solid-border">NIPT: {state.fisFwtnic}</tr> */}
                <tr className="solid-border">
                  Magazina Destinacion: {state.FLH_Magazina_Kodi}
                </tr>

                <tr className="solid-border">
                  Transportuesi: {state.Transportuesi_Pershkrim}
                </tr>
                <tr className="solid-border"> Targa: {state.Targa_e_Mjetit}</tr>

                {/* <tr className="solid-border">
                Targa Mjetit: {state.Targa_e_Mjetit}
              </tr> */}

                {/* Transportuesi */}
                {/* {state.Transportuesi_Pershkrim === "" ||
              state.Transportuesi_Pershkrim === null ? null : (
                <tr className="th-square2">
                  <td>
                    <tr className="solid-border">
                      {" "}
                      Transportuesi: {state.Transportuesi_Pershkrim}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      Adresa: {state.Transportuesi_Adresa}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      NIPT: {state.Transportuesi_Nipt}
                    </tr>
                    <tr className="solid-border">
                      {" "}
                      Targa: {state.Targa_e_Mjetit}
                    </tr>
                  </td>
                </tr>
              )} */}
              </tr>
            </td>
          </div>
        </tr>
      </th>
    </table>
  );
}

export default SimpleTableFD;
