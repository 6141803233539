import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";
import { Toast } from "primereact/toast";

const DialogConfirm = ({
  selectedId,
  faturaNIVF,
  onClose,
  open,
  setDialogOpen,
  setData2,
  functionPost,
  setSelectedId,
}) => {
  const toast = useRef(null);

  const confirmDelete = async () => {
    try {
      if (!faturaNIVF) {
        const response = await mainAxios.delete(`/pos/${selectedId}`);
        if (response.status === 200) {
          // Close the dialog
          setDialogOpen(false);

          // Show the toast
          toast.current.show({
            severity: "info",
            summary: `Fatura me ID: ${selectedId} u fshi !`,
          });

          functionPost();
          setData2([]);
          setSelectedId("");
        } else {
          functionPost();
        }
      } else if (faturaNIVF) {
        toast.current.show({
          severity: "info",
          summary: `Fatura me ID: ${selectedId} eshte e fiskalizuar `,
        });
      }
    } catch (error) {
      console.error("Error for delete request Lista e faturave:", error);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Confirmation</DialogTitle>
      <Toast ref={toast} />
      <DialogContent>
        Deshironi te fshini Faturen me ID: {selectedId}
      </DialogContent>

      <div className="buttons_confirmation">
        <Button onClick={confirmDelete} color="primary" variant="contained">
          PO
        </Button>

        <Button
          onClick={() => {
            onClose();
            setDialogOpen(false);
          }}
          color="primary"
          variant="contained"
        >
          JO
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogConfirm;
