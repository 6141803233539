// CheckboxHandler.js
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const CheckboxHandler = ({
  setIsEditingAllowed,
  setCheckModify,
  setButtonClicked,
  setDisabled,
  setCurrentAction,
  handleModify,
  buttonClickedModifiko,
}) => {
  const handleCheckboxChange = (e) => {
    setIsEditingAllowed(e.target.checked);
    setCheckModify(true);

    if (e.target.checked) {
      setCurrentAction("fromArt");
      setButtonClicked(true);
      setDisabled(false);

      handleModify();
    } else {
      setCurrentAction(null);
      setButtonClicked(false);
      setDisabled(true);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={buttonClickedModifiko}
          onChange={handleCheckboxChange}
        />
      }
      label={
        <>
          <ModeEditIcon />
          Modifiko ne tabele
        </>
      }
    />
  );
};

export default CheckboxHandler;
