import React from "react";
import { Dialog, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "../../dynamicComponent/ConfirmationDialog.scss";

function DeleteConfirmationPopup({
  onConfirm,
  onCancel,
  setDisabled,
  setState,
  setButtonClicked,
  defaultState,
  currentId,
  contexDelete,
  state,
  fleteName,
  fatureName,
}) {
  return (
    <>
      <Dialog
        open={true}
        onClose={onCancel}
        PaperProps={{ style: { padding: "20px" } }}
      >
        <div style={{ display: "inline-block", alignSelf: "center" }}>
          <ErrorOutlineIcon
            className="animate-bounce mt-1"
            style={{ color: "#BF3131" }}
          />
        </div>

        <h2>
          {state.Fature_Shitje_Id != null || state.Fature_Blerje_Id != null
            ? `Kjo ${fleteName} me ID: ${currentId} eshte gjeneruar nga ${fatureName}. Deshironi ta fshini?`
            : `A jeni te sigurt per te fshire ${contexDelete} me ID: ${currentId}?`}
        </h2>
        <div className="button_modifikim_confirm">
          <Button
            onClick={() => {
              onConfirm();
              setDisabled(true);
              setState(defaultState);
              setButtonClicked(false);
            }}
            onTouchStart={() => {
              onConfirm();
              setDisabled(true);
              setState(defaultState);
              setButtonClicked(false);
            }}
            variant="contained"
          >
            Po
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
            onTouchStart={() => {
              onCancel();
            }}
            variant="contained"
          >
            Jo
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default DeleteConfirmationPopup;
