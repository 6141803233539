import React, { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import mainAxios from "../../../services/axios";
import DeleteConfirmationPopup from "../minimodal/DeleteConfirmation";
import PopUpConfirm from "./PopUpConfirm";

const PaneliTabeve = ({
  popupContextKey,
  disabled,
  checkDisabled,
  setDisabled,
  state,
  fatureDisabled,
  defaultState,
  setState,
  index,
  setIndex,
  responseData,
  setResponseData,
  lastObject,
  setLastObject,
  setHidePupUp,
  hidePupUp,
  fetchFShitje,
  fetchFBlerje,
  fetchFHyrje,
  fetchFDalje,
  setRows,
  anulloFature,
  button1Icon,
  button1Text,
  button2Icon,
  button2Text,
  button3Icon,
  button3Text,
  button4Icon,
  button4Text,
  contexDelete,
  button5Icon,
  button5Text,
  currentId,
  setButtonClicked,
  buttonClicked,
  onAddClick,
  number,
  onModifyClick,
  isMobile,
  handleRegister,
  setCurrentAction,
  popupContextKeyType,
  fleteName,
  fatureName,
}) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleConfirm = () => {
    setHidePupUp(false);
    setCurrentAction(null);
    setButtonClicked(false);
    setDisabled(true);
    if (popupContextKey === "FatureShitje") {
      fetchFShitje(number);
    }
    if (popupContextKey === "FatureBlerje") {
      fetchFBlerje(number);
    }
    if (popupContextKey === "FleteHyrje") {
      fetchFHyrje(number);
    }
    if (popupContextKey === "FleteDalje") {
      fetchFDalje(number);
    }
  };

  const handleCancel = () => {
    setHidePupUp(false);
  };

  const deleteFature = async (Id) => {
    try {
      // Conditionally perform the delete request based on the context key
      if (popupContextKey === "FatureShitje") {
        await mainAxios.delete(`/fature/shitje/${Id}`);
      }
      if (popupContextKey === "FatureBlerje") {
        await mainAxios.delete(`/fature/blerje/${Id}`);
      }
      if (popupContextKey === "FleteHyrje") {
        await mainAxios.delete(`/flete_hyrje/${Id}`);
      }
      if (popupContextKey === "FleteDalje") {
        await mainAxios.delete(`/flete_dalje/${Id}`);
      }

      const deletedIndex = responseData.findIndex((item) => item.Id === Id);
      if (deletedIndex !== -1) {
        const updatedResponseData = [...responseData];
        updatedResponseData.splice(deletedIndex, 1);
        setResponseData(updatedResponseData);

        if (lastObject && lastObject.Id === Id) {
          setLastObject(null);
        }

        if (index >= deletedIndex) {
          setIndex(index - 1);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteClick = () => {
    if (currentId) {
      setShowConfirmationPopup(true);
    } else {
      alert("Nuk egziston si fature!");
    }
  };

  const confirmDelete = async () => {
    await deleteFature(currentId);
    setShowConfirmationPopup(false);

    if (popupContextKey === "FatureShitje") {
      fetchFShitje(1);
    }
    if (popupContextKey === "FatureBlerje") {
      fetchFBlerje(1);
    }
    if (popupContextKey === "FleteDalje") {
      fetchFDalje(1);
    }
    if (popupContextKey === "FleteHyrje") {
      fetchFHyrje(1);
    }
  };

  const cancelDelete = () => {
    setShowConfirmationPopup(false);
  };

  return (
    <ButtonGroup className={"mb-2"} style={{ width: "100%" }}>
      {hidePupUp && (
        <PopUpConfirm
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          contextKey={popupContextKey}
        />
      )}
      {showConfirmationPopup && (
        <DeleteConfirmationPopup
          contexDelete={contexDelete}
          currentId={currentId}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          setDisabled={setDisabled}
          defaultState={defaultState}
          setState={setState}
          setButtonClicked={setButtonClicked}
          fatureName={fatureName}
          fleteName={fleteName}
          state={state}
        />
      )}
      <Button
        onClick={(e) => {
          e.preventDefault();
          setButtonClicked(true);
          setDisabled(false);
          setRows([]);
          setState(defaultState);
          onAddClick();
        }}
        disabled={buttonClicked}
      >
        {button1Icon} {button1Text}
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (!popupContextKeyType) {
            setDisabled(false);
            setButtonClicked(true);
          }
          onModifyClick();
        }}
        disabled={buttonClicked || fatureDisabled}
      >
        {button2Icon} {button2Text}
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          handleDeleteClick();
        }}
        disabled={
          buttonClicked || fatureDisabled || state.fisIsCorrectiveInvoice === 1
        }
      >
        {button3Icon} {button3Text}
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          anulloFature();
        }}
        disabled={disabled}
      >
        {button4Icon} {button4Text}
      </Button>

      <Button
        onClick={(e) => {
          e.preventDefault();
          handleRegister();
        }}
        disabled={disabled}
        type="submit"
      >
        {button5Icon} {button5Text}
      </Button>
    </ButtonGroup>
  );
};

export default PaneliTabeve;
