import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavStyles/NavBar.scss";
import Logoja from "../../assets/Logoja.png";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import { useUser } from "../../zustand/common";
import Xhiro from "../../pages/MbylljeTurni/Xhiro";
import { useNavigate } from "react-router-dom";
import items from "./items";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import "./NavStyles/posBtn.scss";
import Stack from "@mui/material/Stack";
import SideBarPhone from "./SideBarPhone";

const CustomLink = ({ to, children, currentAction, isActive }) => {
  const navigate = useNavigate();
  const checkBeforeNavigate = (url) => {
    if (currentAction === "fromArt") {
      alert("Uncheck: Modifiko ne Tabele.");
      return;
    }
    if (currentAction === "modify" || currentAction === "add") {
      alert("Klikoni: Regjistro ose Anullim per te vazhdur.");
      return;
    } else {
      navigate(url);
    }
  };

  return (
    <div
      className={`navitem ${isActive ? "active" : ""}`}
      onClick={() => checkBeforeNavigate(to)}
      style={{ color: isActive ? "#1564ad" : "white", cursor: "pointer" }}
    >
      {children}
    </div>
  );
};

const NavBar = ({ setCurrentRoute, currentAction }) => {
  const { user, logout } = useUser();
  const username = user.username;
  const nipt = user.nipt;
  const [logoutbtn, setLogout] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isXhiroDialogOpen, setIsXhiroDialogOpen] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [setCurrentRoute]);

  useEffect(() => {
    if (!logoutbtn) {
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

      const handleUnload = () => {
        navigate("/login");
        logout();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    } else {
      logout();
    }
  }, [logout, logoutbtn]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleOpenTurni = () => {
    setIsXhiroDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSublinkClick = (sublink) => {
    if (sublink.id === 10) {
      handleOpenTurni();
    } else {
      // Handle other sublinks
      navigate(sublink.url);
      handleDialogClose();
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const handleMenuButtonClick = () => {
    setSidebarOpen(true);
  };
  return (
    <div
      style={{ background: "#1564ad" }}
      className="navBarMain tekst flex items-center rounded-xl shadow-md "
    >
      {isMobile && (
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#bdbdbd" }}
              >
                <Avatar sx={{ width: 25, height: 25 }} />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {!isMobile && (
        <div className="item-1">
          <Link to="/fature-shitje">
            <img src={Logoja} className="pss-logo ml-8 h-9 w-9" alt="" />
          </Link>
        </div>
      )}
      {!isMobile && (
        <div className="item-2">
          <ul className="item-2-ul flex gap-4">
            {items.map((link) => {
              const { id, text, url, photo } = link;
              const isActive = location.pathname === url;
              return (
                <li key={id}>
                  {id === 4 ? (
                    <div
                      className={`navitem ${isActive ? "active" : ""}`}
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={handleDialogOpen}
                    >
                      {photo}
                      {text}
                    </div>
                  ) : (
                    <CustomLink
                      to={url}
                      currentAction={currentAction}
                      isActive={isActive}
                    >
                      {photo}
                      {text}
                    </CustomLink>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {!isMobile && (
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#bdbdbd" }}
              >
                <Avatar sx={{ width: 25, height: 25 }} />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: 300,
            maxHeight: 500,

            "& .username-nipt": {
              padding: "10px", // Add padding to username and Nipt
              alignSelf: "center", // Center username and Nipt vertically
              textAlign: "center", // Center the text
            },
            "& .view-profile-button": {
              width: "100%", // Make the button full-width
              textAlign: "center", // Center the text
            },
            "& .MuiMenuItem-root": {
              textAlign: "left", // Align menu items to the left
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <div className="flex" style={{ flexDirection: "column" }}>
            <Avatar
              sx={{
                width: 38,
                height: 38,

                alignSelf: "center",
              }}
            />
            <div className="username-nipt">
              <span>{username}</span>
              <br />
              <span> {nipt}</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Button
              style={{
                width: "90%",
                border: "1px solid #EEEDEB",
                borderRadius: "5px",
                color: "#000",
                textTransform: "none",
                fontSize: "12px",
              }}
              component={Link}
              to="/celje/perdorues"
            >
              <span style={{ color: "#000" }}>View Profile</span>
            </Button>
          </div>
        </div>
        {/* <MenuItem>Settings</MenuItem> */}
        <MenuItem
          onClick={() => {
            setLogout(true);
            // logout();
          }}
        >
          Sign Out
        </MenuItem>
        <Divider />
        <h6
          className="switch-accounts"
          style={{ textAlign: "left", marginLeft: "15px", marginTop: "15px" }}
        >
          Switch Accounts
        </h6>
        <MenuItem component={Link} to="/celje/perdorues">
          <Avatar
            style={{
              width: 42,
              height: 42,
              marginRight: "10px",
              backgroundColor: "#EEEDEB",
            }}
          >
            <AddIcon fontSize="small" style={{ color: "#000" }} />
          </Avatar>
          <span style={{ color: "black" }}>Add account</span>
        </MenuItem>
      </Menu>
      {isMobile && (
        <div className="navbar-button item-4">
          <SideBarPhone
            sidebarOpen={sidebarOpen}
            setOpen={setSidebarOpen}
            onClose={() => setSidebarOpen(false)}
          />
        </div>
      )}
      {/* Dialog POS */}
      {isMobile && (
        <IconButton onClick={handleMenuButtonClick} className="menu_btn">
          <MenuIcon style={{ color: "white" }} />
        </IconButton>
      )}
      {isXhiroDialogOpen && (
        <Xhiro
          homeNavbar={true}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          open={isXhiroDialogOpen}
          onClose={() => setIsXhiroDialogOpen(false)}
        />
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        className="largeDialog"
      >
        <DialogActions className="dialogActionsContainer">
          <div className="dialogTitleContainer">
            <DialogTitle className="titleDialogPos">POS</DialogTitle>
          </div>
          <div className="closeIconContainer">
            <IconButton
              onClick={handleDialogClose}
              color="inherit"
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogActions>

        <DialogContent className="ContentPos">
          <ul className="ulPos">
            {items
              .find((link) => link.id === 4)
              .sublinks.map((sublink) => (
                <li key={sublink.id}>
                  {sublink.photo}
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      style={{
                        padding: "10px",
                        width: "200px",
                      }}
                      onClick={() => handleSublinkClick(sublink)}
                      disabled={isMobile && sublink.text === "Market"}
                    >
                      {sublink.text}
                    </Button>
                  </Stack>
                </li>
              ))}
          </ul>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NavBar;
