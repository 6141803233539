import React from "react";
import "./CalcXhiro.scss";

const CalcXhiro = ({ totalVlera, totalVleraJoFiskale, selectedId }) => {
  return (
    <div className="calculator_xhiro">
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          padding: 5,
          marginLeft: "20px",
          flexDirection: "column",
          width: "70%",
        }}
      >
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "bold" }}>Fiskale</h2>
          <h2 style={{ fontWeight: "bold" }}>{totalVlera}</h2>
        </div>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "bold" }}>Pezull</h2>
          <h2 style={{ fontWeight: "bold" }}>{totalVleraJoFiskale}</h2>
        </div>
        <hr style={{ color: "#000", border: "3px solid #000" }} />
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "bold" }}>Total</h2>
          <h2 style={{ fontWeight: "bold" }}>
            {totalVlera + totalVleraJoFiskale}
          </h2>
        </div>
      </div>
      <div
        className="flex"
        style={{
          padding: 5,
          marginLeft: "20px",
          flexDirection: "column",
          width: "25%",
          alignSelf: "flex-end",
        }}
      >
        <div className="flex" style={{ alignSelf: "flex-end" }}>
          <h3 style={{ fontWeight: "bold" }}>Fatura e selektuar</h3>
        </div>
        <div className="flex" style={{ alignSelf: "flex-end" }}>
          <h2 style={{ fontWeight: "bold", color: "red" }}>
            ID: {selectedId ? selectedId : "N/A"}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CalcXhiro;
