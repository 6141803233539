import React from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";

const TabTjeraPrvC = ({
  toggleState,
  disabled,
  handleChange,
  state,
  formatDate,
  TextMagazina,
  TextData,
  TextNrLlogarie,
  TextIBAN,
  TextSWIFT,
  TextDega,
  TextAdresa,
  TextAdresaEmerKontaktit,
}) => {
  // magazina redux
  const magazinaRedux = useSelector((state) => state.globalSlice.magazina);
  const magazina = magazinaRedux;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className={"grid gap-2 grid-cols-2"}>
        {TextData ? (
          <TextField
            fullWidth
            disabled={disabled}
            type="date"
            label="Date"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={formatDate(state?.EVlefshmeDeri)}
            onChange={(e) => handleChange("EVlefshmeDeri", e.target.value)}
            size="small"
          />
        ) : null}
        {TextNrLlogarie ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Nr_Llogarise", e.target.value)}
            label="Nr.Llogarie"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Nr_Llogarise || ""}
          />
        ) : null}
        {TextIBAN ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Iban", e.target.value)}
            label="IBAN"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Iban || ""}
          />
        ) : null}

        {TextSWIFT ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Swift", e.target.value)}
            label="SWIFT"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Swift || ""}
          />
        ) : null}
        {TextDega ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Dega", e.target.value)}
            label="Dega"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Dega || ""}
          />
        ) : null}
        {TextAdresa ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Adresa", e.target.value)}
            label="Adresa"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Adresa || ""}
          />
        ) : null}
        {TextAdresaEmerKontaktit ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("EmerKontaktit", e.target.value)}
            label="Emer Kontaktit"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.EmerKontaktit || ""}
          />
        ) : null}
        {TextMagazina ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-magazina2"
              options={magazina}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) =>
                handleChange("Magazina_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                magazina.find((item) => item.Kodi === state?.Magazina_Kodi) ||
                null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Magazina" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TabTjeraPrvC;
