// Importing necessary dependencies
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mainAxios from "../services/axios";

const initialState = {
  artikujPos: [],
  posByOperator: [],
  error: null,
};

export const getArtikujPos = createAsyncThunk(
  "GET_ArtikujPos",
  async (url, { rejectWithValue }) => {
    try {
      const response = await mainAxios(url);
      // Only return the serializable parts (status and data)
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
);

export const getPosByOperator = createAsyncThunk(
  "get_PosByOperator",
  async (url, { rejectWithValue }) => {
    try {
      const response = await mainAxios(url);
      // Return only the serializable parts (status and data)
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
);

export const globalPosSlice = createSlice({
  name: "globalPosState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fulfilled getArtikujPos
    builder.addCase(getArtikujPos.fulfilled, (state, action) => {
      state.artikujPos = action.payload.data; // Access data
    });

    // Handle fulfilled getPosByOperator
    builder.addCase(getPosByOperator.fulfilled, (state, action) => {
      state.posByOperator = action.payload.data; // Access data
    });

    // Handle rejected getArtikujPos
    builder.addCase(getArtikujPos.rejected, (state, action) => {
      state.error = action.payload; // Store error response if request fails
    });

    // Handle rejected getPosByOperator
    builder.addCase(getPosByOperator.rejected, (state, action) => {
      state.error = action.payload; // Store error response if request fails
    });
  },
});

// Exporting the slice reducer
export default globalPosSlice.reducer;
