import React from "react";
import "./print80Skontrino.scss";

function Skontrino80({ state, menyrapageses, nipt, konfigurimeRedux }) {
  // Find the Menyra Pageses entry by ID and return its description
  const pagesaPershkrim = menyrapageses?.find(
    (menyraP) => menyraP.Id === state.Menyra_Pageses_Id
  )?.Pershkrim;

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    return dateTimeString.replace("T", " ");
  };
  // Safely access the first element of konfigurimeRedux
  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  // Handle the case where ndermarrje might be null or undefined
  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }
  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <b>Fature Porosi</b>
          </td>
          <td className="tds-footer" colSpan={4}>
            {ndermarrje.Pershkrim}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino">Fature: {state.Kodi}</td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Data dhe Ora :{" "}
            {formatDateTime(
              state.UPDATED ||
                state.Updated ||
                state.Hapur ||
                state.DATA ||
                state.Data
            )}
          </td>
        </tr>

        <tr>
          <td className="columnsSkontrino">Operator: {state.Operator}</td>
        </tr>

        {state.Tavolina && (
          <tr>
            <td className="columnsSkontrino">Tavolina: {state.Tavolina}</td>
          </tr>
        )}

        <tr>
          <td className="columnsSkontrino">
            Menyra e Pageses: {pagesaPershkrim}
          </td>
        </tr>
        {state.KLFU_Pershkrim && (
          <tr>
            <td className="columnsSkontrino" colSpan={4}>
              Klienti: {state.KLFU_Pershkrim}
            </td>
          </tr>
        )}
      </thead>

      <tbody>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Skontrino80;
