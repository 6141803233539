import React, { useState, useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Assuming you're using Material-UI

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <div style={{ display: "flex", marginRight: "5px", fontWeight: "bold" }}>
      <AccessTimeIcon /> <h2>{currentTime}</h2>
    </div>
  );
};

export default Clock;
