import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import "./print.scss";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import DynamicTableB from "./dynamicTableB";
import SimpleTableB from "./blerjeSimpleTable";

const PrintoPdf = ({ rows, state }) => {
  const componentsRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentsRef.current,
  });

  const perdoruesRedux = useSelector((state) => state.globalSlice.perdorues);

  let perdorues = perdoruesRedux[0];

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // Example usage
  const currentDateTime = getCurrentDateTime();

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button {...bindTrigger(popupState)}>
              <PrintSharp />
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                style={{
                  padding: "1rem",
                  display: "grid",
                  flexDirection: "row",
                }}
              >
                <Button
                  style={{ color: "#1971c2", cursor: "pointer", margin: "5px" }}
                  onClick={handlePrint}
                >
                  {" "}
                  <div style={{ fontSize: "12px" }}>Printo Fature Blerje</div>
                  <PrintSharp style={{ blockSize: "17px" }} />{" "}
                </Button>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      <div ref={componentsRef} className="print ">
        <SimpleTableB state={state} perdorues={perdorues} />
        <DynamicTableB
          rows={rows}
          state={state}
          currentDateTime={currentDateTime}
        />
      </div>
    </>
  );
};

export default PrintoPdf;
