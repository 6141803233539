import React from "react";
import { Button } from "@mui/material";

const CertificateDownloadButton = ({ certBase64, filename }) => {
  const handleDownload = () => {
    // Decode the base64 string to binary data
    const byteCharacters = atob(certBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/x-pkcs12" }); // Adjust the MIME type as needed

    // Create a URL for the blob and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <Button
      sx={{ marginRight: 6 }}
      variant="contained"
      onClick={handleDownload}
    >
      Download Certificate
    </Button>
  );
};

export default CertificateDownloadButton;
