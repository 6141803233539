import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import WifiOffIcon from "@mui/icons-material/WifiOff";

export default function FloatingActionButtons({ isOnline }) {
  return (
    !isOnline && (
      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          left: "50%",
        }}
      >
        <Fab variant="extended">
          <WifiOffIcon sx={{ mr: 1, color: "#ff5252" }} />
          Offline
        </Fab>
      </Box>
    )
  );
}
