import React, { useContext } from "react";
import { Button } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import mainAxios from "../../../services/axios";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";

const AnullimFature = ({ selectedId, toast, currentFatura, functionPost }) => {
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const handleFis = async (Id) => {
    showLoading();
    try {
      const response = await mainAxios.post(`/pos/${Id}`);

      const firstItem = response.data;
      if (firstItem?.ErrorCode !== "0") {
        const faultstring = firstItem.faultstring;

        toast.current.show({
          severity: "error",
          summary: faultstring,
        });
      }
    } catch (error) {
      console.error("Error fiskalizimi:", error);
    } finally {
      hideLoading();
      functionPost();
    }
  };

  const handleAnullim = async (selectedId) => {
    try {
      const response = await mainAxios(`/pos/anullim/${selectedId}`);
      if (response.status === 201) {
        await handleFis(response.data.Id); // Ensure handleFis is awaited
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.current.show({
        severity: "info",
        summary: "Gabim Gjate Anullimit",
      });
    }
  };

  const functionToAnullim = () => {
    if (selectedId === "") {
      toast.current.show({
        severity: "info",
        summary: "Selekto nje Fature",
      });
      return;
    }
    if (!currentFatura.NIVF) {
      toast.current.show({
        severity: "info",
        summary: "Fatura eshte e pa fiskalizuar",
      });
      return;
    }
    if (currentFatura.TIPI === "_BAK") {
      toast.current.show({
        severity: "info",
        summary: "Fature eshte e mbyllur nuk mund te anullohet",
      });
      return;
    } else {
      handleAnullim(selectedId);
    }
  };

  return (
    <Button
      disabled={!selectedId}
      variant="contained"
      startIcon={<RemoveCircleIcon style={{ color: "#fff" }} />}
      onClick={functionToAnullim}
      style={{ margin: "5px" }}
    >
      Anullo Faturen
    </Button>
  );
};

export default AnullimFature;
